/**********************************************************************************
 *
 *  CAUTION!! DO NOT EDIT THIS FILE MANUALLY.
 *
 *  edit _*.json.mjs in same directory instead and use
 *
 *  node script/mergeI18n.mjs
 *
 *  The script will be merge _th.json.mjs & _en.json.mjs together which mean it will copy "new" key from one file to another, beautify and sort automatically.
 *
 **********************************************************************************/

/* eslint-disable */
export default {
  homePageBanner: `ดูแลให้น้องชายแข็งทนวันนี้ รับส่วนลดสูงสุด 35% ทันที!`,
  aboutDescDialog1: `
		HIBRO เป็นเสมือนคลินิกสุขภาพชายออนไลน์ ที่ให้คำปรึกษาและวางแผนการรักษา โดยคุณไม่จำเป็นต้องเปิดเผยตัวตน และเป็นอีกหนึ่งตัวเลือกที่เข้าถึงง่ายสำหรับคุณผู้ชาย  ก่อตั้งขึ้นโดยทีมพี่น้องที่ประสบปัญหาเช่นเดียวกับคุณและพยายามแก้ปัญหาเหล่านี้ ด้วยวิธีของเราเอง เริ่มตั้งแต่ความรู้สึกไม่มั่นใจก่อนมีเพศสัมพันธ์ ไปจนถึงวิตกกังวลกับเส้นผมของคุณที่หน้ากระจกทุกวัน ไม่สามารถเป็นตัวของตัวเองที่ดีที่สุด คุณไม่ได้เป็นคนเดียวที่อยู่ในความเจ็บปวดเหล่านี้อย่างแน่นอน<br /><br />
		เรื่องสุขภาพเพศชาย ไม่ใช่เรื่องง่ายที่จะพูดคุยอย่างเปิดเผย ข้อมูลที่มีบนออนไลน์ก็มักจะคลาดเคลื่อนและไม่อัพเดต รู้หรือไม่? สำหรับผู้ที่มีปัญหาสุขภาพทางเพศในประเทศไทย <a class="link" href="https://pubmed.ncbi.nlm.nih.gov/18821288/" rel="noopener noreferrer" target="_blank">มีเพียง 3% เท่านั้นที่ได้รับความช่วยเหลือจากผู้ให้บริการสุขภาพการแพทย์</a> เป็นตัวเลขที่น่าตกใจที่มีผู้ชายจำนวนมากที่ตัดสินใจไม่รับความช่วยเหลือ และเกิดช่องว่างในระบบการรักษาพยาบาลในปัจจุบัน`,
  aboutDescDialog2: `เราได้ยินเรื่องนี้บ่อยมากขึ้นเมื่อเราโตขึ้นซึ่งเป็นสิ่งที่ผลักดันลักษณะของบุคลิกภาพบางอย่างสำหรับผู้ชาย แต่เรื่องดังกล่าวควรมีผลกระทบต่อการตัดสินใจด้านสุขภาพส่วนบุคคลด้วยประเด็นเรื่อง ความเป็นชายที่ส่งผลกระทบต่อสังคมและต่อตัวผู้ชายเอง หรือไม่ ตราบาปด้านสุขภาพของผู้ชายสามารถส่งผลกระทบต่อเรื่องเพศ จิตใจ และตัวตนของผู้ชายได้ เรื่องนี้จึงเป็นหน้าที่ของเราในการช่วยให้ผู้ชายมีสุขภาพที่ดีขึ้นและมีความตระหนักในเรื่องสุขภาพที่ดีขึ้น`,
  aboutDescDialog3: `
		การเริ่มต้นเป็นสิ่งที่ยาก  แต่อย่าปล่อยให้ปัจจัยภายนอกมาเป็นอุปสรรคในการรับความช่วยเหลือ ดังนั้นเราจึงตั้งเป้าหมายสร้างแพลตฟอร์มที่ปลอดภัยและใช้ง่ายที่สุดสำหรับผู้ชาย เรารวบรวมทีมแพทย์เฉพาะทางจากสถาบันชั้นนำ ทำงานร่วมกับเครือข่ายร้านขายยาที่ถูกต้องตามกฎหมาย ซึ่งล้วนมีค่านิยมทางจริยธรรมเช่นเดียวกับของเรา และใช้เทคโนโลยีมาช่วยเพื่อให้มั่นใจ ให้รู้สึกถึงความเป็นส่วนตัวและความปลอดภัยสูงสุดสำหรับผู้ใช้ของเรา ทำให้ดีขึ้น เพื่อความสบายใจ และเพื่อความสัมพันธ์ที่ดีกับคนที่คุณรัก<br /><br />
		บางครั้งการเป็นผู้ชายก็อาจเป็นเรื่องยาก แต่ขอให้น้องชาย(แข็ง)ในที่ที่เราต้องการเท่านั้น`,
  aboutHibro: `<span class="font-[CentraNo2]">ABOUT HIBRO</span>`,
  aboutPageBeAMan: `“Be a man, do the right thing.” – says Russel Peter, your bros, your SO, heck, even to yourself at some point.`,
  aboutPageBroLetsDo: `Bro, let’s do something about it`,
  aboutPageSubtitle: `<span class="font-[CentraNo2]">If something is bothering you, we want to help. Complete an online visit to get started.</span>`,
  aboutPageSubtitle2: `Healthcare at your doorstep, for men by men`,
  aboutPageweAreMenThatJust: `WE ARE MEN THAT JUST WANTS TO MAKE THINGS ACCESSIBLE FOR OUR FELLOW MEN.`,
  accept: `ยอมรับ`,
  agree: `ยินยอม`,
  all: `ทั้งหมด`,
  backToHomePage: `กลับหน้าหลัก`,
  backToTop: `กลับไปด้านบน`,
  blogPageSubtitle: `Hi Bro, Welcome to Your Health Guide`,
  blogPageTitle: `สาระความรู้ และเทคนิคในการดูแลสุขภาพ`,
  certifyBy: `ได้รับอนุญาตตามมาตรฐานของกรมพัฒนาธุรกิจการค้า`,
  completeOnlineVisit: `If something is bothering you, we want to help. Complete an online visit to get started.`,
  conRead: `อ่านต่อ`,
  confirm: `ยืนยัน`,
  contactPageCompany: `บริษัท เอนโดส จำกัด`,
  contactPageCompanyAddr: `เลขที่ 7 อาคารซัมเมอร์ พ้อยท์ ชั้นที่ 2 ห้องเลขที่ 27 ถนนสุขุมวิท 69 แขวงพระโขนงเหนือ เขตวัฒนา กรุงเทพมหานคร 10110`,
  contactPageEmail: `care@sayHIBRO.com`,
  contactPageFAQ: `คำถามที่พบบ่อย`,
  contactPageHello: `สวัสดีครับ เราจะช่วยคุณได้อย่างไร?`,
  contactPageIfhaveQuestion: `หากคุณมีคำถามหรือข้อสงสัยเกี่ยวกับการให้บริการของเรา สามารถติดต่อเราได้ทาง`,
  contactPageLine: `@HIBRO (มี@ข้างหน้า)`,
  contactPageNeedMoreHelp: `Need more Help?`,
  contactPageNeedMoreHelpDesc: `Get instant answers to common question.`,
  contactPagePhone: `(02) 200 1032`,
  contactPageTitle: `ติดต่อเรา`,
  cookieConsentMessage: `เราใช้คุกกี้และเทคโนโลยีที่เกี่ยวข้องอื่นๆ เพื่อรับรองประสบการณ์การใช้เว็บไซต์ที่ดีที่สุดสำหรับคุณ คุณสามารถแก้ไขการตั้งค่าด้ทุกเมื่อ `,
  cookieConsentMessageLink: `คลิกที่นี่เพื่อดูรายละเอียดเพิ่มเติม`,
  dapoxetine: `เม็ดขาว ฟินนาน`,
  erectileDysfunction: `หย่อนสมรรถภาพทางเพศ`,
  finasteride: `ยารักษาชนิดรับประทาน`,
  footerAbout: `เกี่ยวกับเรา`,
  footerBlog: `บทความ`,
  footerContactUs: `ติดต่อเรา`,
  footerHelpCenter: `ศูนย์ช่วยเหลือ`,
  footerHowItWork: `ทำงานอย่างไร`,
  footerMDTeam: `ทีมแพทย์`,
  footerPayConfirm: `ยืนยันการชำระเงิน`,
  footerPrivacy: `Privacy Policy`,
  footerTermOfUse: `Terms of Use`,
  goToSurvey: `ทำแบบสอบถาม`,
  hair: `เส้นผม`,
  hairlossTag: `ผมร่วง ผมบาง`,
  hairStartConsult: `สั่งซื้อ`,
  hairlossPageIndex1: `อาการผมร่วง ผมบาง ศีรษะล้าน เป็นอย่างไร`,
  hairlossPageIndex2: `ลักษณะของผมร่วง`,
  hairlossPageIndex3: `การรักษาอาการผมร่วง ผมบาง ศีรษะล้าน`,
  hairlossPageIndex4: `How we can help, get ahead!`,
  hairlossPageSubtitle: `Hair Loss 101`,
  hairlossPageTitle: `รู้จักอาการผมร่วง ผมบาง ศีรษะล้าน<br/> พร้อมวิธีรักษาอย่างเห็นผล`,
  health: `สุขภาพ`,
  helpPageCateAccounts: `การตั้งค่าบัญชี`,
  helpPageCateAccountsFAQ1: `ประวัติการสั่งซื้อ`,
  helpPageCateAccountsFAQ1Desc: `คุณสามารถดูข้อมูลการรักษาและตารางเวลาคำสั่งซื้อได้ในโปรไฟล์บัญชีของคุณ รวมถึงประวัติการสั่งซื้อ ใบแจ้งหนี้และประวัติการติดตามทั้งหมด`,
  helpPageCateAccountsFAQ2: `จะแก้ไข เปลี่ยนแปลงข้อมูลของฉันอย่างไร`,
  helpPageCateAccountsFAQ2Desc: `คุณสามารถแก้ไข เปลี่ยนแปลง อัปเดตข้อมูลบัญชี รวมถึงวิธีการชำระเงิน ที่อยู่ในการจัดส่ง ในโปรไฟล์บัญชีของคุณ`,
  helpPageCateAccountsFAQ3: `จะลบบัญชีของฉันได้อย่างไร`,
  helpPageCateAccountsFAQ3Desc: `
  เรารู้สึกเสียใจที่ทราบว่าคุณต้องการยกเลิกการเป็นสมาชิก HIBRO เราเข้าใจเป็นอย่างยิ่งว่าอาจไม่เหมาะสำหรับทุกคน เราเสนอสองวิธีในการยกเลิก<br />
  คุณสามารถเข้าสู่ระบบบัญชีของคุณและส่งคำขอทางออนไลน์หรือโทรหาเราได้โดยตรง
`,
  helpPageCateAccountsFAQ4: `ฉันจะตั้ง(เปลี่ยน)รหัสผ่านได้อย่างไร`,
  helpPageCateAccountsFAQ4Desc: `คุณสามารถตั้ง(เปลี่ยน)รหัสผ่านตามลิงค์นี้ <a class="underline" href="/reset-password">here</a>`,
  helpPageCateContact: `ติดต่อเรา`,
  helpPageCateContactFAQ1: `Contact us ติดต่อเรา`,
  helpPageCateContactFAQ1Desc: `
    ทีมดูแลลูกค้าของเราพร้อมให้ความช่วยเหลือคุณทางโทรศัพท์ อีเมล หรือแชททางไลน์ หากคุณมีความจำเป็นเร่งด่วนทางคลินิก กรุณาโทร 02-200-1032 จะเป็นวิธีที่เร็วที่สุดในการติดต่อเรา <br />
    ความช่วยเหลือทางโทรศัพท์ในเวลาทำการ<br />
    เวลาทำการปกติของเราคือวันจันทร์ถึงวันศุกร์ 8.00 - 18.00 น. และวันเสาร์และวันอาทิตย์ 8.00 - 18.00 น.<br /><br />
    ความช่วยเหลือทางโทรศัพท์นอกเวลาทำการ<br />
    นอกเวลาทำการปกติ ระบบข้อฝากข้อความเสียงจะทำการอัตโนมัติ กรุณาฝากข้อความ เจ้าหน้าที่หรือเภสัชกรจะติดต่อกลับโดยเร็วที่สุด<br />
    อีเมล์และแชทสนทนา<br />
    คุณสามารถส่งข้อความหาเราได้ที่อีเมล์ สนทนาผ่านแชทบนแพลตฟอร์ม หรือ สนทนาผ่านแชทไลน์ ที่ @HIBRO
  `,
  helpPageCateGeneral: `ทั่วไป`,
  helpPageCateGeneralFAQ1: `HIBRO เหมาะกับใคร`,
  helpPageCateGeneralFAQ1Desc: `
    HIBROให้บริการ telemedicine สำหรับผู้ใหญ่ที่ประสบปัญหาหย่อนสมรรถภาพทางเพศ การหลั่งเร็วและผมร่วง <br /><br />
    เจ้าหน้าที่ผู้ให้บริการด้านการสุขภาพเป็นผู้ที่มีใบอนุญาตอย่างถูกต้องในประเทศไทย เพื่อออกแบบแผนการรักษาที่เหมาะสมสำหรับคุณ โดยพิจารณาจากสภาวะสุขภาพและความต้องการของคุณ และร้านขายยาพันธมิตรที่ได้รับการรับรองของเรา จะทำการจัดส่งผลิตภัณฑ์(ยา)ให้กับคุณด้วยบรรจุภัณฑ์ที่ปกปิดมิดชิด<br /><br />
    คุณต้องเป็นผู้ที่มีอายุไม่ต่ำกว่า 20 ปี และเป็นผู้มีถิ่นที่อยู่ในราชอาณาจักรไทยจึงจะมีสิทธิ์ได้รับการรักษาผ่านแพลตฟอร์มของเรา
  `,
  helpPageCateGeneralFAQ2: `ใครคือผู้ให้บริการบน HIBRO?`,
  helpPageCateGeneralFAQ2Desc: `เจ้าหน้าที่ผู้ให้บริการด้านสุขภาพ(ทางการแพทย์)ทั้งหมดของ HIBRO เป็นแพทย์ผู้เชี่ยวชาญที่ได้รับใบอนุญาตจากแพทยสภา ซึ่งจะผ่านการตรวจสอบประวัติ ใบอนุญาต และเงื่อนไขอื่นๆอย่างละเอียดเพื่อรักษาอาการของคุณ เราจะสามารถเปิดเผยข้อมูลใบอนุญาตทางการแพทย์และข้อมูลประวัติของเจ้าหน้าที่ฯของเราได้ตามคำขอของคุณ`,
  helpPageCateGeneralFAQ3: `ยาที่ใช้รักษามาจากไหน`,
  helpPageCateGeneralFAQ3Desc: `เนื่องจากเรา(HIBRO)เป็นเพียงผู้ให้บริการด้านเทคโนโลยีเท่านั้น เราจึงทำงานร่วมกับร้านขายยาพันธมิตรที่ได้รับการรับรอง เพื่อนำส่งผลิตภัณฑ์และการรักษาที่วินิจฉัยโดยแพทย์และเป็นที่แน่ใจว่าแหล่งที่มาของการรักษาที่มีคุณภาพ ร้านขายยาพันธมิตรของเราได้รับการรับรอง “Good Pharmacy Practice :GPP” โดยสภาเภสัชกรรมแห่งประเทศไทย`,
  helpPageCateGeneralFAQ4: `ฉันจำเป็นต้องไปพบแพทย์เพื่อใช้ HIBRO หรือไม่?`,
  helpPageCateGeneralFAQ4Desc: `
    เพื่อให้ได้ใช้บริการและรับการรักษาจากHIBRO คุณต้องเคยใช้บริการทางการแพทย์ ตรวจสุขภาพ เช็คร่างกายหรือพบแพทย์ในช่วง 24 เดือนที่ผ่านมา เนื่องจากเราจำเป็นต้องใช้ข้อมูลสุขภาพที่เป็นปัจจุบันที่สุดของคุณ รวมถึงไปค่าความดันโลหิต หากคุณไม่เคยไปหาผู้ให้หรือใช้บริการด้านการดูแลสุขภาพ(ทางการแพทย์)ในช่วง 24 เดือนที่ผ่านมาแสดงว่าคุณไม่เหมาะกับบริการของ HIBRO<br /><br />
    นอกจากนี้หลังจากที่แพทย์ของเราได้ตรวจสอบบันทึกสุขภาพของคุณแล้ว แพทย์อาจแนะนำให้คุณไปพบผู้ให้บริการด้านการดูแลสุขภาพ(ทางการแพทย์)ด้วยตนเองแทน หากคุณอยู่ในเกณฑ์หรือเงื่อนไขที่ต้องได้รับการตรวจอย่างใกล้ชิด
  `,
  helpPageCateGeneralFAQ5: `ผู้เชี่ยวชาญด้านการดูแลสุขภาพ(ทางการแพทย์) จะตรวจสอบประวัติและผลของฉันได้นานแค่ไหน?`,
  helpPageCateGeneralFAQ5Desc: `
    หลังจากที่คุณพบแพทย์ทางออนไลน์แล้ว แพทย์จะทำการตรวจสอบประวัติทางการแพทย์ของคุณในเบื้องต้นภายใน 24 ชั่วโมง<br /><br />
    หากรอนาน คุณสามารถส่งอีเมลถึงเราที่ care@sayHIBRO.com หรือโทรหาเราโดยตรง
  `,
  helpPageCateGeneralFAQ6: `ต้องใช้วิดีโอในการสนทนา หรือโทรศัพท์หรือไม่`,
  helpPageCateGeneralFAQ6Desc: `ในบางกรณีแพทย์อาจจะโทรหรือใช้วิดีโอการสนทนา ในการติดตามผลและแบบประเมินออนไลน์ของคุณ เพื่อให้แน่ใจว่าแพทย์เข้าใจเกี่ยวกับอาการและสุขภาพของคุณมากที่สุด และมีแนวทางในการออกแบบการรักษาที่เหมาะสมที่สุดสำหรับคุณ`,
  helpPageCateGeneralFAQ7: `ฉันสามารถพูดคุยกับผู้เชี่ยวชาญทางการแพทย์เพื่อแก้ปัญหา หรือข้อกังวลได้หรือไม่?`,
  helpPageCateGeneralFAQ7Desc: `
    แน่นอน! คุณสามารถพูดคุยกับผู้เชี่ยวชาญทางการแพทย์ที่ได้รับมอบหมายเคสของคุณผ่านทางอีเมลหรือข้อความได้ตลอดเวลา ได้ทันทีหลังจากที่คุณรับการรักษาในครั้งแรก<br /><br />
    หากคุณต้องการพูดคุยกับแพทย์หรือผู้ประกอบวิชาชีพพยาบาลผ่านทางโทรศัพท์หรือวิดีโอแชทที่ปลอดภัย กรุณาส่งข้อความถึงทีมดูแลลูกค้าของเราเพื่อประสานงานให้คุณ
  `,
  helpPageCateGeneralFAQ8: `หากมีเหตุฉุกเฉิน ฉันควรทำอย่างไร`,
  helpPageCateGeneralFAQ8Desc: `หากเกิดเหตุฉุกเฉิน คุณสามารถติดต่อโรงพยาบาลใกล้บ้าน หน่วยงานฉุกเฉิน หรือ โทร 1554 ทันที`,
  helpPageCateOrdering: `คำสั่งซื้อ/การจัดส่ง`,
  helpPageCateOrderingFAQ1: `Delivery (การจัดส่ง)`,
  helpPageCateOrderingFAQ1Desc: `
  <b>ใช้เวลานานเท่าไหร่ในการจัดส่ง</b><br /><br />
  คุณจะได้รับผลิตภัณฑ์ในการรักษาที่จัดส่งโดยบริษัทขนส่งที่เป็นพันธมิตรกับเรา ภายใน 2-6 วัน หลังจากได้รับการปรึกษาและอนุมัตการรักษาจากแพทย์ ในระหว่างการจัดส่งคุณจะได้รับอีเมลหรือ SMS พร้อมลิงก์เพื่อติดตามการจัดส่ง<br /><br />
  คำสั่งซื้อสามารถจัดส่งได้เฉพาะในวันธรรมดาเท่านั้น ระยะเวลาในการขนส่งจะไม่รวมวันที่สั่งซื้อและจัดเตรียมผลิตภัณฑ์ <br /><br />
  <b>จะจัดส่งอย่างไร</b><br />
  การจัดส่งขึ้นอยู่ชนิดและประเภทของแผนรักษาที่คุณได้รับ เราปฏิบัติตามเงื่อนไขและระเบียบการจัดส่ง เพื่อให้มั่นใจในความปลอดภัยในการใช้ยาของผู้ใช้<br /><br />
  สำหรับแผนการรักษาภาวะหย่อนสมรรถภาพทางเพศ และการหลั่งเร็ว ทางเราจะจัดส่งผลิตภัณฑ์ให้คุณเป็นรายเดือน <br /><br />
  สำหรับแผนการรักษาศีรษะล้าน ผมร่วง ทางเราจะจัดส่งผลิตภัณฑ์ทั้งหมดที่ใช้ในระยะ 3 เดือนให้คุณในครั้งแรก<br /><br />
  <b>เสียค่าส่งเท่าไหร่</b><br />
  “จัดส่งฟรี” ในฐานะลูกค้าของ HIBRO คุณไม่จำเป็นต้องกังวลเกี่ยวกับค่าส่งและค่าบริการอื่นๆที่จะเกิดขึ้นกับแผนการรักษาของคุณ<br /><br />
  <b>จัดส่งที่ไหนบ้าง</b><br />
  เราจัดส่งผลิตภัณฑ์และแผนการรักษาไปยังที่อยู่จริงทุกแห่งในประเทศไทย<br /><br />
  <b>บรรจุภัณฑ์ที่มิดชิด</b><br />
  เราจัดส่งยาและแบบการรักษาของคุณในกล่องบรรจุภัณฑ์ที่แข็งแรงและมิดชิด ปิดผนึกซีลบนกล่องอีกครั้งเพื่อเพิ่มความปลอดภัยในผลิตภัณฑ์<br /><br />
  <b>ที่อยู่ในการจัดส่งของคุณ พัสดุทั้งหมดจะถูกจัดส่งไปยังที่อยู่ที่แจ้งไว้</b><br />
  เราเข้าใจเป็นอย่างยิ่งว่าทุกคนมีเวลาการทำงานและไลฟ์สไตล์ที่แตกต่างกัน เพื่อให้ตรงกับตารางเวลาของแต่ละบุคคลมากที่สุด เราขอแนะนำให้ระบุที่อยู่ที่ทำงานเพื่อจัดส่งหากไม่มีใครอยู่บ้านในช่วงเวลาทำการ<br />
  อาจมีการเก็บค่าใช้จ่ายเพิ่มเติม หากคุณไม่สามารถรับผลิตภัณฑ์ในการรักษาและต้องการให้เราจัดส่งใหม่อีกครั้ง
`,
  helpPageCateOrderingFAQ2: `Returns and Exchange (การส่งคืนและการแลกเปลี่ยนสินค้า)`,
  helpPageCateOrderingFAQ2Desc: `
  เนื่องด้วยยาทั่วไปและยาที่สั่งจ่ายโดยแพทย์​ไม่สามารถคืน หรือใช้ซ้ำได้ตามกฎหมายทางด้านสุขภาพ และการขายทั้งหมดถือเป็นที่สิ้นสุด และผลิตภัณฑ์ของเราเป็นยา ดังนั้นเมื่อบรรจุ จัดเตรียมให้คุณแล้วจะไม่สามารถนำไปใช้กับท่านอื่นๆได้ <br /><br />
  อย่างไรก็ตามหากสินค้าที่ได้รับมีความเสียหายหรือชำรุด หรือไม่ใช่สินค้าที่สั่ง กรุณาอย่าอย่าแกะป้ายสินค้าใด ๆ ออกจากตัวสินค้าหรือนำสินค้าออกจากกล่อง เราขอแนะนำให้คุณติดต่อทีมงานฝ่ายบริการลูกค้าทันทีโดยส่งอีเมล์ที่ care@sayHIBRO.com  เพื่อให้กระบวนการแลกเปลี่ยนผลิตภัณฑ์เป็นไปอย่างรวดเร็ว"
`,
  helpPageCateOrderingFAQ3: `Refund (การคืนเงิน)`,
  helpPageCateOrderingFAQ3Desc: `
  <p>เราจะคืนเงินให้คุณตามแผนการรักษาของคุณหาก:</p>
  <ul class="list-disc list-inside">
    <li>แพทย์วินิจฉัยว่าแผนการรักษาไม่เหมาะสมกับคุณ</li>
    <li>แผนการรักษาที่สั่งซื้อไม่สามารถใช้ได้อีกต่อไป หรือ</li>
    <li>มีการระบุแผนการรักษาที่ไม่ถูกต้องหรือข้อผิดพลาดในการกำหนดราคา</li>
  </ul>
  <br />
  หากการคืนเงินของคุณได้รับการอนุมัติ การดำเนินการเรื่องการคืนเงินจะใช้เวลาประมาณ 14 วันทำการ
`,
  helpPageCatePayment: `การชำระเงิน`,
  helpPageCatePaymentFAQ1: `มีค่าใช้บริการเท่าไหร่`,
  helpPageCatePaymentFAQ1Desc: `
  มีค่าใช้จ่ายสองส่วนที่อาจเกิดขึ้นเมื่อใช้บริการบนแพลตฟอร์ม: 1) การให้คำปรึกษาออนไลน์ 2) แผนการรักษา<br /><br />
  การปรึกษาออนไลน์มีค่าบริการไม่เกิน 300 บาท โดยยังไม่รวมค่าใช้จ่ายในการรักษาของคุณ (แพทย์วินิจฉัยเห็นว่าเหมาะสมกับคุณ) แต่หากแพทย์หรือเภสัชกรวินิจฉัยแล้วว่าคุณมีคุณสมบัติไม่เพียงพอต่อการรักษาtelemedicine และจะได้รับเงินคืนเต็มจำนวน<br /><br />
  คุณจะถูกเรียกเก็บเงินเฉพาะค่ายาหลังจากที่แพทย์ของคุณอนุมัติแผนการรักษาแล้ว คุณสามารถลงชื่อเข้าใช้ผ่านระบบบัญชีของคุณ เพื่อรับการอัปเดตและตรวจสอบรายละเอียดแผนการรักษาและค่าใช้จ่าย
`,
  helpPageCatePaymentFAQ2: `สามารถใช้ประกันร่วมกับ HIBROได้หรือไม่`,
  helpPageCatePaymentFAQ2Desc: `บริการบนแพบตฟอร์ม HIBRO ผู้ใช้บริการจะต้องชำระค่าบริการด้วยตนเองทั้งหมด ไม่สามารถใช้สิทธิ์ของประกันได้ เราอยากให้คุณทราบว่าค่าบริการออนไลน์ 200 บาทของเรานั้นน้อยกว่าค่าใช้จ่ายโดยรวมในการไปพบแพทย์ถึง 50% `,
  helpPageCatePaymentFAQ3: `ฉันจะถูกเรียกเก็บเงินเมือไหร่`,
  helpPageCatePaymentFAQ3Desc: `
  คุณจะถูกเรียกชำระเงินหลังจากรับแบบการรักษาตั้งแต่ครั้งแรกจาก HIBRO<br /><br />
  หากคุณเลือกแผนรักษาแบบชำระเงินล่วงหน้า ทางเราจะดำเนินการจัดส่งแผนการรักษาในครั้งถัดไป (ตามรายละเอียดแผนรักษาแต่ละประเภท) ร้านยาพันธมิตรของเราจะดำเนินการตามคำสั่งซื้อและตรวจสอบให้แน่ใจว่าคุณจะได้รับแผนการรักษาตามระยะเวลาที่กำหนด<br /><br />
  คุณสามารถดูวันที่ในการรับแผนรักษาครั้งถัดไปได้ในโปรไฟล์บัญชีของคุณ"
`,
  helpPageCatePaymentFAQ4: `ฉันจะหยุดหรือยกเลิกคำสั่งซื้อได้อย่างไร?`,
  helpPageCatePaymentFAQ4Desc: `
  เนื่องจากการจัดส่งของเรามีทั้งส่งแบบครั้งเดียว และแบบประจำต่อเนื่องตามแผน ซึ่งขึ้นอยู่กับการเลือกแผนการรักษาของคุณในระหว่างที่รับการปรึกษา จึงไม่จำเป็นต้องกังวลว่าจะต้องชำระเงินเพิ่ม<br /><br />
  หากคุณต้องการเปลี่ยนแปลงวันที่ในการจัดส่ง กรุณาโทรหรือส่งอีเมลถึงเราเพื่อทำการเปลี่ยนแปลง 5 วันก่อนส่งล่วงหน้า ฝ่ายบริการลูกค้าของเราจะช่วยประสานงานการจัดส่ง เพื่อให้คุณได้รับการรักษาตามเวลาที่คุณต้องการ
`,
  helpPageCatePaymentFAQ5: `ฉันจะได้รับเงินคืนเมื่อไหร่`,
  helpPageCatePaymentFAQ5Desc: `
  <p>หากคำสั่งซื้อของคุณตรงตามเงื่อนไขในการขอคืนเงิน (โปรดไป”คืนเงิน”) ระยะเวลาในการดำเนินการคืนเงินจะขึ้นอยู่กับช่องทางที่ชำระเงินของคุณ</p><br />
  <ul>
    <li>บัตรเครดิต: 30 วันนับจากวันที่ยืนยันการคืนเงิน</li>
    <li>โอนเงินผ่านธนาคาร: 14 วันนับจากวันที่ยืนยันการคืนเงิน</li>
  </ul>
`,
  hibroMedicalTeam: `<span class="font-[CentraNo2]">HIBRO Medical Team</span>`,
  helpPageCatePrivacy: `ความเป็นส่วนตัว`,
  helpPageCatePrivacyFAQ1: `Account Security and Privacy (ความปลอดภัยของบัญชีและความเป็นส่วนตัว)`,
  helpPageCatePrivacyFAQ1Desc: `
  ข้อมูลของลูกค้าทั้งหมดได้รับการเข้ารหัสตั้งแต่ต้นทางถึงปลายทาง ระบบถูกออกแบบให้ทีมงาน HIBRO ถูกฝึกอบรมให้จัดการข้อมูล ตามหลักการป้องกันข้อมูลของHIPAA เราได้ว่าจ้าง บริษัทรักษาความปลอดภัยจากภายนอกประจำ เพื่อตรวจสอบแนวทางปฏิบัติด้านความปลอดภัยของเรา นอกจากนี้กระบวนการของเรายังเป็นไปตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล (PDPA) และเป็นไปตามข้อบังคับกำหนด<br /><br />
  <a class="underline" href="/privacy-policy">สำหรับข้อมูลเพิ่มเติมโปรดอ่านนโยบายความเป็นส่วนตัวของเรา</a>
`,
  helpPageSubtitle: `มีข้อสงสัย? เรามีคำตอบให้กับทุกคำถาม`,
  helpPageTitle: `ศูนย์ช่วยเหลือ<br />Help Center `,
  HibroMedicalTeam: `ทีมแพทย์<br /><span class="font-[CentraNo2]">HIBRO Medical Team</span>`,
  homePageActionNow: `รักษาอาการหย่อนสมรรถภาพทางเพศ ได้ง่ายๆ<br />แค่ทำแบบทดสอบด้วยตัวคุณเอง`,
  homePageActionPE: `รักษาอาการหลั่งเร็วแก้ได้ง่าย ๆ<br />
  แค่ท าแบบทดสอบด้วยตัวคุณเอง`,
  homePageActionED: `รักษาอาการหย่อนสมรรถภาพทางเพศ ได้ง่ายๆ <br />
  แค่ทำแบบทดสอบด้วยตัวคุณเอง
  `,
  homePageActionHL: `รักษาผมบางผมร่วง ได้ง่ายๆ<br />แค่ทำแบบทดสอบด้วยตัวคุณเอง`,
  homePageGotQuestion: `มีข้อสงสัย? เรามีคำตอบให้กับทุกปัญหา`,
  homePageHair: `Hair`,
  homePageHairDesc: `ไม่ต้องเสียความมั่นใจ เสียบุคลิก เพราะผมร่วง ผมบาง จากฮอร์โมนและกรรมพันธุ์จนเป็นเหตุของหัวล้าน ด้วยผลิตภัณฑ์รักษารากผม ของ HIBRO ที่มีผู้เชี่ยวชาญด้านเส้นผมคอยดูแล`,
  homePageHairItem: `รักษาผมร่วงจากฮอร์โมนผู้ชายและพันธุกรรม`,
  homePageHelpCenter: `คำถามที่พบบ่อย`,
  homePageHibroMDTeam: `ทีมแพทย์<br />สำหรับ THE BROS`,
  homePageHibroMDTeamDesc: `"ทีมแพทย์ผู้เชี่ยวชาญเฉพาะทางที่มี ใบอนุญาตจากกระทรวงสาธารณสุขของ HIBRO จะมอบการวินิจฉัยและ แนะนำวิธีการรักษาที่เหมาะสมกับคุณ"`,
  homePageHibroMDTeamMeet: `พบกับทีมแพทย์ของเรา`,
  homePageHowItWork: `3 ขั้นตอนง่ายๆ<br />ช่วยแก้ปัญหากังวลใจ`,
  homePageHowItWork1: `1. กรอกประวัติสุขภาพเพียง 5 นาที`,
  homePageHowItWork1Desc: `เลือกตอบคำถามสุขภาพและประวัติทางการแพทย์ออนไลน์ หรือ ปรึกษาแพทย์ทางโทรศัพท์เป็นการส่วนตัวได้ตามสะดวก`,
  homePageHowItWork2: `2. รับการวินิจฉัย`,
  homePageHowItWork2Desc: `ทีมแพทย์ผู้เชี่ยวชาญเฉพาะทางที่มีใบอนุญาตจาก กระทรวงสาธารณสุข ของ HIBROจะมอบการวินิจฉัย และแนะนำวิธีการรักษาที่เหมาะสมกับคุณ`,
  homePageHowItWork3: `3.รับผลิตภัณฑ์ที่เหมาะสมถึงบ้าน`,
  homePageHowItWork3Desc: `สำหรับผู้ที่ต้องการซื้อยา สามารถสั่งและรับผลิตภัณฑ์ยาที่ได้รับรองจาก อย. ฟรี! ค่าจัดส่ง ภายใน 2 วัน`,
  homePageMD1: `นพ. อาคเนย์ วงษ์สวัสดิ์<br />(ว.27048)`,
  homePageMD1Desc: `ศัลยแพทย์ระบบทางเดินปัสสาวะ<br />โรงพยาบาลเมดพาร์ค กรุงเทพฯ`,
  homePageMD2: `นพ. ชยุตม์ ฝูงทองเจริญ<br />(ว.38429)`,
  homePageMD2Desc: `CEO และผู้อำนวยการฝ่ายการแพทย์<br />HE clinic ทองหล่อ`,
  homePageMD3: `นพ. พชรวรรธน์ จิระสุทัศน์<br />(ว.43700)`,
  homePageMD3Desc: `แพทย์ผู้เชี่ยวชาญด้านโรคผิวหนังประจำสถาบันโรคผิวหนัง กรมการแพทย์ กระทรวงสาธารณะสุข กรุงเทพมหานคร`,
  homePageOurTreatments: `ปลอดภัย มั่นใจได้`,
  homePageOurTreatmentsDesc: `ยาของ HIBRO เป็นยาที่มีมาตรฐานและได้รับการรับรองจากองค์การอาหารและยา (อย.) พร้อมมีเภสัชกรและร้านยาที่มีใบอนุญาตถูกต้อง และใช้ตามคำแนะนำจากแพทย์ผู้เชี่ยวชาญอย่างเคร่งครัด`,
  homePagePerPill: `/เม็ด`,
  homePagePerBottle: `/ขวด`,
  menariniName: `Menarini (เมนารินี)`,
  homePagePill1: `เม็ดฟ้า ให้ประสิทธิผลการรักษามาตรฐาน มีขนาด 50 มิลลิกรัม และ 100 มิลลิกรัม ที่ผลิตโดยองค์การเภสัชกรรม (ภอ)`,
  homePagePill2: `อีกตัวเลือก ที่ให้ประสิทธิผลการรักษาสูงกว่า ผลิตโดยบริษัท Eli Lilly ออกฤทธิ์ยาวนาน 24–36 ชม.  มีให้เลือกใช้ตามความจำเป็น (รายครั้ง) หรือรักษาแบบต่อเนื่อง (ใช้ทุกวัน)`,
  homePagePill3: `ยาไมน็อกซิดิลชนิดทา 5% ผลิตโดยบ. โปลิฟาร์ม`,
  homePagePill4: `เม็ดฟ้าต้นตำรับ ผลิตโดยบริษัท Pfizer ออกฤทธิ์นานปานกลาง (6-8 ชม.) ใช้ตามความจำเป็น (รายครั้ง)`,
  homePagePill5: `ยาตัวแรกและตัวเดียวที่ได้รับการอนุมัติจากองค์การอาหารและยา ประเทศไทยเพื่อใช้ชะลอการหลั่ง รับประทาน 1 เม็ด 1-3 ชั่วโมงก่อนมีเพศสัมพันธ์ ผลิตโดย Menarini`,
  homePageReview: `คำชมจากผู้ใช้`,
  homePageReviewDesc: `ผู้ใช้ส่วนใหญ่พึงพอใจผลการรักษา สนใจปรึกษา และรับคำวินิจฉัย พร้อมแนวทางการรักษา แบบเป็นส่วนตัว จากแพทย์ผู้เชี่ยวชาญ จาก HIBRO สามารถคลิกพบพูดคุยส่วนตัวได้ทันที`,
  homePageSexualHealth: `ปัญหาสุขภาพเพศชาย`,
  homePageSexualHealthDesc: `หย่อนสมรรถภาพทางเพศ ไม่แข็งตัว หรือหลั่งเร็ว<br />มีผลต่อความภูมิใจและชีวิตคู่ มาคืนความมั่นใจอีกครั้งกับแพทย์ผู้เชี่ยวชาญ ของ HIBRO ที่จะมาวินิจฉัย และ แนะแนวทางการรักษาที่เหมาะสมกับคุณอย่างเป็นส่วนตัวโดยเฉพาะ`,
  homePageSexualItem1: `หย่อนสมรรถภาพทางเพศ`,
  homePageSexualItem2: `หลั่งเร็ว`,
  homeSubtitle: `<p>เว็ปไซต์นี้ได้รับการตรวจสอบความถูกต้องของข้อมูลทางการแพทย์</p><p>โดยทีมแพทย์ HIBRO</p>`,
  homepageMencare: `ดูแลใส่ใจ ทุกปัญหา สุขภาพเพศชาย:`,
  homepageProblemList: `นกเขาไม่ขัน แก้ยังไง<br />ล่มปากอ่าว แก้ได้?<br />ผมบาง หัวล้าน รักษาได้?<br />ปิดจ๊อบก่อนไคลแมกซ์<br />เครียดผมบาง ศีรษะล้าน`,
  homepageTalkToRealMD: `แก้ปัญหากังวัลใจสุขภาพชาย ด้วยวิธีการที่ถูกต้อง เหมาะสม และเป็นกันเอง กับ แพทย์ผู้เชี่ยวชาญจาก HIBRO`,
  findMyTreatment: `ค้นหาการรักษาของฉัน`,
  howHiBroWorkWithMD: `HIBRO ทำงานร่วมกับแพทย์อย่างไร`,
  howHiBroWorkWithMDItem1: `ทีมแพทย์ผู้เชี่ยวชาญของ HIBRO ทำงานในลักษณะคลีนิกอิสระ ไม่ได้ถูกว่าจ้างโดย HIBRO คุณจึงสามารถมั่นใจ ในจรรยาบรรณของบุคลากร และแนวทางการปฎิบัติที่คำนึงถึง ประโยชน์สูงสุดของคุณ`,
  howHiBroWorkWithMDItem2: `ค่าธรรมเนียมแพทย์จะชำระเป็นรายครั้งที่เข้าปรึกษา ไม่อิงตามจำนวนของใบสั่งยา ดังนั้นการวินิจฉัยของทีมแพทย์จะคำนึงถึงสุขภาพ และความปลอดภัยเป็นหลัก และจ่ายยาตามความจำเป็นเท่านั้น`,
  howHiBroWorkWithMDItem3: `ผู้เชี่ยวชาญในการบริการด้านสุขภาพของเราจะตรวจสอบขั้นตอน การทำงานทางคลินิกอย่างต่อเนื่องเพื่อให้แน่ใจว่าผู้ป่วยของเรา ปลอดภัยตามมาตรฐานสากลสูงสุด`,
  howHiBroWorkWithMDItem4: `เรามีความตั้งใจที่จะออกแบบขั้นตอนทางการแพทย์ เพื่อที่จะช่วย ให้แพทย์ของเราได้รับข้อมูล รายละเอียด ประวัติทางการแพทย์ของคุณ ที่สอดคล้องและครบถ้วนมากที่สุด ซึ่งช่วยให้แพทย์นำมาวินิจฉัย ในภาพรวม และคิดแผนการรักษาที่เหมาะสมที่สุด`,
  ifInterested: `ถ้าสนใจรับคำปรึกษาจากแพทย์ผู้เชี่ยวชาญ <br />หรือผลิตภัณฑ์ที่มั่นใจได้ จาก HIBRO <br />สามารถติดต่อได้จากช่องทางที่ระบุไว้ด้านบน`,
  introduction: `Introduction`,
  lefestyle: `ไลฟ์สไตล์`,
  login: `Login`,
  logout: `Logout`,
  md1Slogan: `HIBRO ตัวเลือกใหม่สำหรับการดูแลรักษาสุขภาพน้องชายที่เป็นทั้งแพทย์ผู้เชี่ยวชาญ​และพี่ชายที่คุยกันได้สบายๆ​ อุ่นใจ ปลอดภัย ไร้กังวล`,
  md2Slogan: `ทางเลือกใหม่สำหรับคุณผู้ชายในการฟื้นฟูดูแลสุขภาพทางเพศ ที่สะดวก ประหยัด และปลอดภัย ภายใต้การดูแลของแพทย์ผู้เชี่ยวชาญ HIBROจะทำให้ทุกคนสามารถเข้าถึงการรักษาที่เหมาะสมและหันมาใส่ใจกับปัญหาสุขภาพเพศมากขึ้น`,
  md3Slogan: `การรักษาแบบโทรเวชกรรม หรือ Telemedicine มีบทบาทมากขึ้นสำหรับการรักษาผู้ป่วยในปัจจุบัน HIBRO เป็นอีกหนึ่งทางเลือก ที่ให้ผู้ป่วยได้เข้าถึงการรักษาและยาที่ได้มาตรฐาน`,
  mdDetailPageDocumentNo: `(ใบอนุญาตผู้ประกอบวิชาชีพเวชกรรมเลขที่ {{number}})`,
  mdDetailPageGettingToKhow: `ทำความรู้จักกับแพทย์ของเรา`,
  medicalTeamPageDialog1: `ด้วยทีมแพทย์ผู้เชี่ยวชาญด้านทางเดินปัสสาวะและสุขภาพทางเพศชายที่มากประสบการณ์ และความเชี่ยวชาญกว่า 10 ปี พร้อมให้คำปรึกษาและแนะนำเฉพาะเจาะจงสำหรับปัญหาของคุณ พร้อมทีมวิชาชีพผู้เชี่ยวชาญที่จะทำงานร่วมกันเพื่อเกิดประสิทธิภาพ คุณจะได้รับประสบการณ์ที่พิเศษในการเข้ารับคำปรึกษากับ HIBRO ด้วยระบบออนไลน์ที่ทันสมัย ช่วยให้คุณเข้าถึงบริการทางสุขภาพ โดยที่ไม่ต้องเสียเวลาเดินทาง หรือกังวลเรื่องความเป็นส่วนตัว`,
  medicalTeamPageSubtitle: `<p class='font-bold title'>ทีมแพทย์ของเรา ที่มากประสบการณ์ และมีความเชี่ยวชาญกว่า 10 ปี</p>`,
  medicine: `ยา/เวชภัณฑ์`,
  minoxidil: `ไมน็อกซิดิล`,
  optOut: `ไม่ยินยอม`,
  ourMedicalExport: `Our Medical Experts`,
  ourMedicalExportDesc: `ด้วยทีมแพทย์ด้านทางเดินปัสสาวะและปัญหาของการเสื่อมสมรรถภาพทางเพศที่มากประสบการณ์และมีความเชี่ยวชาญกว่า 10 ปี ที่พร้อมให้คำปรึกษาและคำแนะนำอย่างเฉพาะเจาะจง สำหรับปัญหาของคุณ`,
  pdpaPageAgreePerformance: `คุกกี้สถิติ เพื่อวิเคราะห์และวัดผลการทำงาน`,
  pdpaPageAgreePerformanceDesc: `เพื่อให้คุณใช้งานได้สะดวก ตรงกับความต้องการมากขึ้น บริษัทจะใช้คุกกี้ประเภทนี้เพื่อช่วยในการพัฒนาเว็บไซต์/แอปพลิเคชัน โดยการเก็บรวบรวมและรายงานข้อมูลเกี่ยวกับลักษณะวิธีที่คุณใช้งานเว็บไซต์/แอปพลิเคชัน`,
  pdpaPageCookiesSettings: `ตั้งค่าคุกกี้`,
  pdpaPageForMarketing: `คุกกี้เพื่อการโฆษณา`,
  pdpaPageForMarketingDesc: `เพื่อเข้าใจความต้องการของคุณ และนำเสนอโปรโมชั่น สิทธิพิเศษ รวมทั้งข้อเสนอเกี่ยวกับสินค้าหรือบริการใหม่ ๆ จากบริษัท ที่คัดสรรมาสำหรับคุณโดยเฉพาะ บริษัทจะใช้คุกกี้ประเภทนี้เพื่อช่วยเก็บรวบรวมและใช้ข้อมูลเพื่อนำมาวิเคราะห์ ทำความเข้าใจ เพื่อช่วยในการปรับปรุงการนำเสนอโฆษณาและวัดประสิทธิภาพการทำแคมเปญโฆษณา`,
  pdpaPageStrictlyNecessary: `คุกกี้ที่จำเป็นอย่างยิ่ง`,
  pdpaPageStrictlyNecessaryDesc: `เพื่อให้การเข้าถึงและใช้งานเว็บไซต์/แอปพลิเคชันของคุณเป็นไปได้อย่างถูกต้อง คุกกี้ประเภทนี้มีความจำเป็นอย่างยิ่ง ต่อการทำงานขั้นพื้นฐานของเว็บไซต์/แอปพลิเคชันของบริษัท เพื่อให้คุณสามารถเข้าถึงข้อมูลและใช้งานเว็บไซต์/แอปพลิเคชันได้อย่างปลอดภัย คุกกี้ประเภทนี้ไม่มีการจัดเก็บข้อมูลซึ่งสามารถระบุตัวตนของคุณได้`,
  phoneConsultIfnotSure: `*หากคุณไม่แน่ใจในการเลือกผลิตภัณฑ์ สามารถเลือกปรึกษาผ่านโทรศัพท์ได้โดยตรง เพื่อรับคำแนะนำจากแพทย์ผู้เชี่ยวชาญ`,
  placebo: `ยาหลอก`,
  prematureEjaculation: `หลั่งเร็ว`,
  privacyPolicy: `นโยบายความเป็นส่วนตัว`,
  proListEasy: `ง่าย! ซื้อสะดวก`,
  proListEasySub: `ได้ตลอด 24 ชั่วโมง`,
  proListFast: `รวดเร็ว,`,
  proListFastSub: `ส่งถึงที่ภายใน 2 วัน`,
  proListFree: `ฟรีค่าจัดส่ง!`,
  proListFreeSub: `ทั่วประเทศ`,
  proListSafe: `ปลอดภัย`,
  proListSafeSub: `แพ็คเกจปกปิดมิดชิด`,
  proListFDAApproved: `ผลิตภัณฑ์(ยา)`,
  proListFDAApprovedSub: `ได้อย.`,
  proListThaiLicense: `แพทย์ ผู้เชี่ยวชาญ`,
  proListThaiLicenseSub: `ดูแล`,
  proListPharmaLicense: `ร้านยามีใบอนุญาต`,
  proListPharmaLicenseSub: `ถูกต้อง`,
  profileAcademicWork: `ผลงานทางวิชาการ`,
  profileCertificate: `ใบรับรอง`,
  profileEducation: `การศึกษา`,
  profileSpecialist: `ความเชี่ยวชาญพิเศษ`,
  profileWorks: `การทำงาน`,
  questionnaire: `Questionnaire`,
  readMore: `อ่านข้อมูลเพิ่มเติม`,
  readyToStartOnlineVisit: `Ready To Start Your Online Visit`,
  references: `References`,
  register: `Register`,
  relatedArticle: `Related Article`,
  reviewTitle: `เสียงตอบรับจากผู้ใช้จริง`,
  reviewedBy: `Reviewed by {{name}}`,
  salePageAddLine: `Add Line`,
  salePageConsultNow: `Consult Now`,
  salePageFAQ: `FAQ`,
  salePageNeedMoreHelp: `Need more Help ?`,
  salePageNeedMoreHelpDesc: `Talk to our team in HIBRO Line Official account to understand more about what we treat and how we can help`,
  salePageStartOurWebsite: `Our website`,
  salePageStartQuiz: `ทำแบบทดสอบ`,
  salePageTakeAQuiz: `<span class="font-[CentraNo2]">Take a quiz to assess risk of ED</span>`,
  salePageTakeAQuizDesc: `The risk to erectile dysfunction is based on multiple lifestyle factors. Check it out to get to know more about your risk factors.`,
  setting: `การตั้งค่า`,
  sex: `เพศสัมพันธ์`,
  sildenafil: `เม็ดฟ้า ทันใจ`,
  sorryMsg404: `Sorry, this page you're looking for couldn't be found.`,
  startConsult: `เริ่มปรึกษา`,
  startYourOnlineVisit: `Start<br />Your Online Visit`,
  stillHaveQuestion: `มีข้อสงสัยเพิ่มเติมเกี่ยวกับการใช้บริการของ hibro? เรามีคำตอบให้กับทุกคำถาม กดอ่านได้`,
  stillHaveQuestionVisit: `ที่นี่`,
  treatmentEDPageAssured: `มั่นใจไร้กังวล`,
  treatmentEDPageBluePillDesc: `
    <p class="text-[14px] leading-[20px] mt-[16px]">ยาที่มีชื่อเสียง และเป็นเครื่องหมายการค้าของ Pfizer มีสารยับยั้งเอนไซม์ PDE-5 จะช่วยผ่อนคลายกล้ามเนื้อและหลอดเลือด เพิ่มการไหลเวียนของเลือดไปยังองคชาตเพื่อช่วยในการแข็งตัว</p>
    <p class="text-[14px]"><span class="font-bold">รูปแบบ:</span> ยาเม็ดรับประทาน</p>
    <p class="text-[14px]"><span class="font-bold">ปริมาณยา:</span> 25 mg, 50 mg, 100 mg</p>
    <p class="text-[14px]"><span class="font-bold">การใช้:</span> ใช้เป็นครั้งคราว</p>
    <p class="text-[14px]"><span class="font-bold">ใช้เมื่อไหร่:</span> 30-60 นาทีก่อนมีเพศสัมพันธ์</p>
    <p class="text-[14px]"><span class="font-bold">ออกฤทธิ์ได้นาน:</span> 6-8 ชม.</p>
  `,
  treatmentEDPageBluePillName: `เม็ดฟ้า ทันใจ`,

  treatmentEDPageSymptoms: `หย่อนสมรรถภาพทางเพศ อาการเป็นอย่างไร?`,
  treatmentEDPageSymptomsDetail: `ลักษณะอาการของโรคหย่อนสมรรถภาพทางเพศนั้น จะมีอาการเด่น ๆ คือ น้องชายไม่แข็งตัว หรือแข็งตัวได้ไม่เต็มที่ จนทำให้ไม่ประสบความสำเร็จในการมีเพศสัมพันธ์​ โดยจะแบ่งระดับความรุนแรงเป็น 3 ระดับ ดังนี้`,
  treatmentEDPageSymptomsDetailPoint1: `ระดับทั่วไป : อาการเกิดขึ้นบ้าง หรือนาน ๆ ครั้ง`,
  treatmentEDPageSymptomsDetailPoint2: `ระดับปานกลาง : อาการเกิดขึ้นได้ในบางครั้ง`,
  treatmentEDPageSymptomsDetailPoint3: `ระดับรุนแรง : อาการเกิดขึ้นเกือบจะทุกครั้ง`,

  treatmentEDPageCanEDCure: `หย่อนสมรรถภาพเพศชายรักษาหายหรือไม่?`,
  treatmentEDPageCanEDCureDetail: `
    <p>อาการหย่อนสมรรถภาพทางเพศ หรือนกเขาไม่ขัน สามารถรักษาได้ โดยการปรับเปลี่ยนพฤติกรรมการใช้ชีวิตให้เหมาะสม ร่วมกับใช้ยารักษาโรคเสื่อมสมรรถภาพของผู้ชายที่ได้รับอนุญาตให้จำหน่ายในไทย ซึ่งล้วนเป็นยาชนิดรับประทานชนิดควบคุมพิเศษ ต้องมีใบสั่งยาจากแพทย์ก่อนเท่านั้น </p>
  `,
  treatmentEDPageCanEDTreatmentOptionDesc: `HIBRO ให้บริการปรึกษาปัญหาหย่อนสมรรถภาพทางเพศออนไลน์ โดยแพทย์ผู้เชี่ยวชาญเฉพาะทาง พร้อมออกใบสั่งยารักษาโรคเสื่อมสมรรถภาพของผู้ชายที่ได้รับรองให้จำหน่ายในไทย โดยแบ่งเป็น 2 ตัวหลัก ๆ คือ เม็ดฟ้าทันใจจาก Pfizer, องค์การเภสัช, Unison และ เม็ดส้มทนนานจาก Eli Lilly พร้อมให้คำแนะนำอย่างใกล้ชิด เพื่อความปลอดภัยสูงสุด`,
  treatmentEDPageCanEDTreatmentOptionSubtitle: `ตัวเลือกในการรักษานกเขาไม่ขันของ HIBRO`,
  treatmentEDPageCanEDTreatmentOptionTitle: `ED Treatment Options`,
  treatmentEDPageCauseED: `หย่อนสมรรถภาพทางเพศ เกิดจากสาเหตุใด?`,

  treatmentEDPageCauseEDDetail: `สาเหตุของโรคหย่อนสมรรถภาพ ได้แก่`,
  treatmentEDPageCauseEDDetailPoint1: `มีโรคประจำตัว หรือโรคเรื้อรัง เช่น โรคหัวใจ โรคหลอดเลือด โรคเบาหวาน โรคอ้วน`,
  treatmentEDPageCauseEDDetailPoint2: `ผลกระทบจากภาวะทางจิตใจ เช่น ภาวะซึมเศร้า ความวิตกกังวล หรือความเครียด`,
  treatmentEDPageCauseEDDetailPoint3: `พฤติกรรมที่ไม่เหมาะสม เช่น การดื่มเครื่องดื่มแอลกอฮอล์ หรือสูบบุหรี่เป็นประจำ ไม่ออกกำลัง ไม่รับประทานอาหารที่มีประโยชน์ หรือพักผ่อนไม่เพียงพอ`,
  treatmentEDPageCauseEDDetailPoint4: `ผลข้างเคียงจากการใช้ยาบางชนิด`,
  treatmentEDPageCauseEDDetailPoint5: `อายุที่เพิ่มมากขึ้น`,
  treatmentEDPageCauseEDDetailPoint6: `ภาวะพร่องฮอร์โมนเพศชาย`,
  treatmentEDPageCauseEDDetailPoint7: `ผู้ที่เป็นโรคหย่อนสมรรถภาพทางเพศอาจเกิดจากหลายสาเหตุร่วมกัน หรือเกิดจากสาเหตุเดียวกันก็ได้ อาจทำให้แพทย์ไม่สามารถระบุสาเหตุเฉพาะเจาะจงกับคนบางคนได้`,

  treatmentEDPageDesc: `ปรึกษา รักษา ปัญหาหย่อนสมรรถภาพทางเพศ โดยทีมแพทย์ผู้เชี่ยวชาญของ HIBRO พร้อมออกใบสั่งยา ส่งตรงถึงบ้านคุณ`,
  treatmentEDPageErectileDysfunction: `หย่อนสมรรถภาพทางเพศ`,

  treatmentEDPageFrequentlyAskedQuestions: `คำถามที่พบบ่อยเกี่ยวกับการรักษา<br/>อาการหย่อนสมรรถภาพทางเพศ`,
  treatmentEDPageFAQ1: `จะรู้ได้อย่างไรว่ามีปัญหาหย่อนสมรรถภาพทางเพศ?`,
  treatmentEDPageFAQ1Desc: `หากอวัยวะเพศชายของคุณไม่สามารถแข็งตัวหรือคงความแข็งตัวไว้ได้ ในขณะปฏิบัติกิจทางเพศ คุณอาจเริ่มมีความเสี่ยงต่อภาวะหย่อน หรือเสื่อมสมรรถภาพทางเพศ ควรรีบปรึกษาแพทย์ หรือผู้เชี่ยวชาญเพื่อหาสาเหตุและวิธีรักษาที่เหมาะสมกับคุณมากที่สุด`,
  treatmentEDPageFAQ2: `ยารักษานกเขาไม่ขันมีผลออกฤทธิ์อย่างไร?`,
  treatmentEDPageFAQ2Desc: `ยารักษานกเขาไม่ขัน จะส่งผลต่อการตอบสนองของอวัยวะเพศชายต่อการกระตุ้นทางเพศ โดยยาจะไปยับยั้งเอนไซม์ที่ทำให้อวัยวะเพศอ่อนปวกเปียกกลับคืนสู่ปกติ ส่งผลให้เกิดการแข็งตัวของอวัยวะเพศชายมากขึ้น`,
  treatmentEDPageFAQ3: `ยารักษาอาการหย่อนสมรรถภาพทางเพศ ชนิดใดดีที่สุด?`,
  treatmentEDPageFAQ3Desc: `ยารักษาอาการหย่อนสมรรถภาพทางเพศที่ได้รับการรับรองจากสำนักงานคณะกรรมการอาหารและยา ( อย. ) ล้วนอยู่ในกลุ่มยาประเภทเดียวกัน ยาเหล่านี้จะช่วยผ่อนคลายกล้ามเนื้อบริเวณอวัยวะเพศ และปรับการไหลเวียนของเลือด เพื่อรักษาอาการ <a class='link' href="https://www.sayhibro.com/blog/sex-health-help"> น้องชายไม่แข็งตัว </a> <br/><br/>
  อย่างไรก็ตาม แม้ว่าจะมีคุณสมบัติใกล้เคียงกัน แต่อาการนกเขาไม่ขันของแต่ละคนเกิดจากสาเหตุที่ต่างกัน อาจทำให้แพทย์แนะนำไม่เหมือนกัน จึงควรปรึกษาแพทย์ก่อนใช้ยา เพราะสิ่งที่สำคัญที่สุดในการเลือกใช้ยา คือการคำนึงถึงลักษณะของสุขภาพและการใช้ชีวิตตามคำแนะนำของแพทย์`,
  treatmentEDPageFAQ4: `ยาชนิดใช้เมื่อต้องการและชนิดทานต่อเนื่อง (ทุกวัน) ต่างกันอย่างไร?`,
  treatmentEDPageFAQ4Desc: `ยารักษาโรคเสื่อมสมรรถภาพของผู้ชายในกลุ่ม phosphodiesterase-5 inhibitors (PDE5i) ส่วนใหญ่เหมาะสำหรับใช้เมื่อต้องการมีเพศสัมพันธ์เท่านั้น ตามคำแนะนำของแพทย์ผู้เชี่ยวชาญ โดยควรรับประทานยาก่อนมีกิจกรรมทางเพศอย่างน้อย 30–60 นาที ซึ่งจะออกฤทธิ์ได้นานถึง 8 ชม. <br/><br/>
  ในบางกรณีแพทย์อาจมีการแนะนำการรักษาด้วย เม็ดส้ม ฟินนาน ในขนาด 5 มก. ที่ออกฤทธิ์ได้นานถึง 36 ชม. ทานอย่างต่อเนื่องเป็นประจำทุกวัน <br/><br/>
  การรักษาทั้งสองแสดงให้เห็นประสิทธิภาพและความปลอดภัยที่คล้ายคลึงกันในการศึกษาเมื่อเทียบกับยาหลอก ความแตกต่างส่วนใหญ่ระหว่างยาทั้งสองขึ้นอยู่กับความชอบของผู้ใช้เกี่ยวกับระยะเวลาของยา วิธีรับประทาน และประวัติทางการแพทย์ของผู้ที่รับประทานยา
  `,
  treatmentEDPageFAQ5: `สามารถใช้ "เม็ดฟ้า" ได้บ่อยแค่ไหน?`,
  treatmentEDPageFAQ5Desc: `ควรใช้เมื่อต้องการมีเพศสัมพันธ์ และควรรับประทานไม่เกินวันละครั้ง ในปริมาณปกติ 50 มก. และสามารถเพิ่มขนาดยาได้ถึง 100 มก. หรือลดลงเป็น 25 มก. ขึ้นอยู่กับอาการข้างเคียง`,
  treatmentEDPageFAQ6: `ถ้าฉันปกติหรือแข็งแรงดี สามารถใช้ "เม็ดฟ้า" ได้หรือไม่?`,
  treatmentEDPageFAQ6Desc: `สามารถใช้ได้ แต่ควรปรึกษาแพทย์ก่อนทุกครั้ง เพื่อทราบถึงประโยชน์และข้อควรระวัง`,
  treatmentEDPageFAQ7: `ถ้าฉันปกติหรือแข็งแรงดี สามารถใช้ "เม็ดฟ้า" ได้หรือไม่?`,
  treatmentEDPageFAQ7Desc: `สามารถใช้ได้ แต่ควรปรึกษาแพทย์ก่อนทุกครั้ง เพื่อทราบถึงประโยชน์และข้อควรระวัง`,
  treatmentEDPagePercent35Save: `ประหยัดถึง <span class="text-[#D60000]">35%</span>`,
  treatmentEDPagePercent78Work: `กว่า 78% ของผู้ชายมีการแข็งตัวดีขึ้นกว่าผู้ไม่ใช้ยา`,
  treatmentEDPageStartWith180Consult: `ปรึกษาแพทย์พร้อมรับยา`,
  treatmentEDPageStartWith180: `เริ่มต้นเพียง 180 บาท`,
  treatmentEDPagePriceStartWith180: `ราคาเริ่มต้นที่ 180 บาท`,
  treatmentEDPageSubtitle: `Erectile Dysfunction (ED)`,
  treatmentEDPageSuccessSexAttmp: `ความสำเร็จในการมีเพศสัมพันธ์`,
  treatmentEDPageTitle: `หย่อนสมรรถภาพทางเพศ`,
  treatmentEDPageTopic1: `หย่อนวันนี้ เสื่อมวันหน้า<br/>ถ้าไม่รีบรักษา`,
  treatmentEDPageTopic2: `รู้จักอาการหย่อนสมรรถภาพทางเพศ`,
  treatmentEDPageTreatmentResult: `ผลลัพท์ในการรักษา`,
  treatmentEDPageTreatmentResultDesc: `ผลการวิจัยพบว่า หลังจากที่ใช้ยารักษาโรคเสื่อมสมรรถภาพของผู้ชายไปแล้ว ผู้เข้ารับการทดลองมีอัตราประสบความสำเร็จในการมีเพศสัมพันธ์มากขึ้น และคงความแข็งตัวของอวัยวะเพศชายได้ดีขึ้น โดยกลุ่มที่ใช้ยาจริงมีผลสำเร็จ 66.6% ในขณะที่กลุ่มที่ใช้ยาหลอกมีผลสำเร็จ 33.05% ซึ่งมากกว่า 2 เท่าเมื่อเปรียบเทียบกับกลุ่มยาหลอก`,
  treatmentEDPageTreatmentResultDesc2: ``,
  treatmentEDPageTreatmentResultSrcTag: `ที่มา :`,
  treatmentEDPageTreatmentResultSrc: ` Kongkanand A. The efficacy and safety of oral sildenafil in Thai men with erectile dysfunction (2003)`,
  treatmentEDPageWhatED: `หย่อนสมรรถภาพทางเพศ (ED) คืออะไร?`,
  treatmentEDPageWhatEDDetail: `<p><a class='link' href="https://www.sayhibro.com/erectile-dysfunction-101">โรคหย่อนสมรรถภาพทางเพศ</a> หรือที่เรียกว่า “กามตายด้าน” หรือ “นกเขาไม่ขัน” คือ อาการอวัยวะเพศชายไม่สามารถแข็งตัว หรือแข็งตัวได้ไม่นานพอจนจบการมีเพศสัมพันธ์ได้ เป็นอาการที่สามารถเกิดขึ้นได้กับผู้ชายทุกช่วงวัย แต่จะพบมากในผู้ที่มีอายุ  40 - 70 ปี</p>`,
  treatmentEDPageYellowPillDesc: `
    <p class="text-[14px] leading-[20px] mt-[16px]">จะมีระยะเวลาออกฤทธิ์ได้นาน ช่วยผ่อนคลายกล้ามเนื้อและหลอดเลือด เพิ่มการไหลเวียนของเลือดไปยังองคชาตเพื่อช่วยในการแข็งตัว เป็นอีกทางเลือกหนึ่งสำหรับผู้ที่ใช้ตัวยามาตรฐาน (เม็ดฟ้า) แต่ผลยังไม่เป็นที่น่าพอใจ หรือผู้มีโรคประจำตัว</p>
    <p class="text-[14px]"><span class="font-bold">รูปแบบ:</span> ยาเม็ดรับประทาน</p>
    <p class="text-[14px]"><span class="font-bold">ปริมาณยา:</span> 2.5 mg, 5 mg, 10 mg, 20 mg</p>
    <p class="text-[14px]"><span class="font-bold">การใช้:</span> ใช้เป็นครั้งคราว และใช้ทุกวัน</p>
    <p class="text-[14px]"><span class="font-bold">ใช้เมื่อไหร่:</span> 30 นาทีก่อนมีเพศสัมพันธ์</p>
    <p class="text-[14px]"><span class="font-bold">ออกฤทธิ์ได้นาน:</span> 24-36 ชม.</p>
  `,
  treatmentEDPageYellowPillName: `เม็ดส้ม ทนนาน`,
  treatmentEfficacy: `ประสิทธิภาพในการรักษา`,
  treatmentErectilePageIndex1: `ภาวะเสื่อมสมรรถภาพทางเพศ (Erectile Dysfunction : ED)`,
  treatmentErectilePageIndex2: `สาเหตุของการเสื่อมสมรรถภาพทางเพศ`,
  treatmentErectilePageIndex3: `เกรดการวัดความแข็งตัวของอวัยวะเพศชาย`,
  treatmentErectilePageIndex4: `การรักษาภาวะเสื่อมสมรรถภาพทางเพศ`,
  treatmentErectilePageIndex5: `How we can help, get ahead!`,
  treatmentErectilePageSubtitle: `Erectile<br />Dysfunction 101`,
  treatmentErectilePageTitle: `ภาวะเสื่อมสมรรถภาพทางเพศ`,
  treatmentHLPage10PercentSave: `ประหยัดถึง <span class="text-[#D60000]">10%</span>`,
  treatmentHLPageAboutHL: `ศีรษะล้าน ผมบาง ผมร่วงจากฮอร์โมนและพันธุกรรมสามารถดีขึ้นได้ เพียงรักษาให้ตรงจุด`,
  treatmentHLPageAssured: `เห็นผลเมื่อใช้เป็นประจำ อย่างน้อย 4-6 เดือนขึ้นไป`,
  treatmentHLPageBaldC: `รูปแบบ C`,
  treatmentHLPageBaldCDesc: `ผมร่วงเป็นแบบครึ่งวงกลมหรือตัวอักษร C`,
  treatmentHLPageBaldM: `รูปแบบ M`,
  treatmentHLPageBaldMDesc: `ผมรอบขมับค่อยๆ ร่วงทีละน้อย`,
  treatmentHLPageBaldO: `รูปแบบ O`,
  treatmentHLPageBaldODesc: `ผมร่วงที่ด้านบนของศีรษะ (จุดบนสุด)`,
  treatmentHLPageBaldPattern: `รูปแบบของศีรษะล้าน`,
  treatmentHLPageBaldU: `รูปแบบ U`,
  treatmentHLPageBaldUDesc: `เส้นผมค่อยๆ ร่วงจากด้านหน้าไปเหนือจุดตรงกลาง ซึ่งมีลักษณะคล้ายเกือกม้า`,
  treatmentHLPageCauseOfHL: `ศีรษะล้าน ผมร่วง ผมบาง เกิดจากสาเหตุใด?`,
  treatmentHLPageCauseOfHLDesc: `ผมร่วง ผมบาง ศีรษะล้านในผู้ชาย จะเกิดจากสาเหตุหลัก ๆ คือ ภาวะผมบางจากพันธุกรรมและฮอร์โมนเพศชายไดไฮโดรเทสโทสเตอโรน (Dihydrotestosterone : DHT) ซึ่งจะทำให้รากผมค่อย ๆ เปลี่ยนแปลงจากผมเส้นใหญ่ไปเป็นเส้นผมเส้นเล็ก โดยส่วนใหญ่จะบางลงมากบริเวณกลางศีรษะ`,
  treatmentHLPageClinicallyProve: `วิธีรักษาผมร่วงผมบางผู้ชายของ HIBRO`,
  treatmentHLPageClinicallyProveDesc: `เราเสนอยาไมน็อกซิดิล (Minoxidil) ชนิดพ่น และ ยาชนิดรับประทาน ใช้เป็นการรักษามาตรฐานในการหยุดผมร่วง ผมบางสำหรับผู้ชาย ซึ่งได้รับการอนุมัติจากสำนักงานคณะกรรมการอาหารและยา (อย.) และจัดส่งโดยตรงจากร้านขายยาที่ได้รับอนุญาต`,
  treatmentHLPageCombineRemart: `*เมื่อใช้การรักษาร่วมกัน ภายใต้คำแนะนำจากแพทย์และผู้เชี่ยวชาญ`,
  treatmentHLPageDesc: `หยุดปัญหาศีรษะล้าน ผมร่วง ผมบาง จากพันธุกรรมและฮอร์โมน ด้วยวิธีรักษาและผลิตภัณฑ์ที่ได้มาตรฐานจากผู้เชี่ยวชาญ มั่นใจเห็นผล ยืนยันโดยผู้ใช้จริง`,
  treatmentHLPageDropperDesc: `
    <p class="text-[14px] leading-[20px] mt-[16px]">ช่วยเพิ่มการไหลเวียนของเลือดไปยังบริเวณหนังศีรษะ เพื่อทำให้ผมกลับมาเส้นโตขึ้น แข็งแรงในตำแหน่งที่ใช้ยา เหมาะสําหรับรักษาเฉพาะที่ในจุดที่มีอาการศีรษะล้าน</p>
    <p class="text-[14px]"><span class="font-bold">ชื่อสามัญ:</span> ไมน็อกซิดิล</p>
    <p class="text-[14px]"><span class="font-bold">รูปแบบ:</span> ยาหยอด ใช้เฉพาะจุด (Topical)</p>
    <p class="text-[14px]"><span class="font-bold">วิธีใช้:</span> ใช้ต่อเนื่อง</p>
    <p class="text-[14px]"><span class="font-bold">ผลลัพธ์ที่ได้:</span> ดูแลรักษาง่าย</p> 
  `,
  treatmentHLPageDropperName: `ไมน็อกซิดิล (Minoxidil)`,
  treatmentHLPageFrequentlyAskedQuestions: `คำถามที่พบบ่อย<br/>เกี่ยวกับผมร่วง ผมบาง`,
  treatmentHLPageFAQ1: `HIBRO ให้บริการรักษาผมบางผมร่วงจากฮอร์โมนผู้ชายและพันธุกรรมอย่างไร?`,
  treatmentHLPageFAQ1Desc: `
  ผู้เชี่ยวชาญด้านเส้นผมจาก hibro จะประเมินอาการของคุณจากประวัติทางการแพทย์ และทำการวินิจฉัยเบื้องต้นจากภาพถ่ายของคุณที่อัปโหลดลงบนระบบ เพื่อคัดกรองภาวะที่อาจจะต้องไปพบแพทย์ที่โรงพยาบาล และให้คำปรึกษาและข้อแนะนำที่จำเป็นต่อคุณ <br /><br />
  หลังจากที่คุณตอบคำถามสุขภาพทั้งหมดแล้ว ผู้เชี่ยวชาญของเราจะตรวจสอบแบบการรักษาที่คุณเลือกว่าเหมาะสมกับคุณหรือไม่ เพื่อให้มั่นใจว่าการใช้ผลิตภัณฑ์ของเราจะเห็นผลและปลอดภัยสำหรับคุณมากที่สุด แล้วทำการจัดส่งผลิตภัณฑ์ให้กับคุณ <br /><br>
  นอกจากนี้ผู้เชี่ยวชาญด้านเส้นผมของเราจะติดตามความคืบหน้าเป็นระยะๆ เพื่อระบุปัญหาในระยะเริ่มต้น พร้อมทั้งให้คำแนะนำ วิธีการรักษา และแนวทางปฏิบัติที่ดีที่สุด
  `,
  treatmentHLPageFAQ2: `มีวิธีรักษาผมร่วงผมบางผู้ชายอย่างถาวรหรือไม่?`,
  treatmentHLPageFAQ2Desc: `
  ในปัจจุบันยังไม่มีการรักษาใดที่สามารถรักษาอาการศีรษะล้าน ผมบาง ผมร่วงจากฮอร์โมนผู้ชายและพันธุกรรมได้อย่างถาวร แม้แต่การปลูกผมเองก็ตาม ก็อาจกลับมามีอาการผมร่วงได้ เมื่อไม่ได้กินยา หรือรักษาอย่างต่อเนื่อง<br /><br />
  อย่างไรก็ตาม การรักษาตั้งแต่เนิ่น ๆ อาการผมร่วง ผมบาง ยังไม่มาก จะช่วยเสริมประสิทธิภาพการรักษา และช่วยชะลอไม่ให้ผมบาง หรือศีรษะล้านได้นานขึ้น ด้วยเหตุนี้ เมื่อคุณพบว่าตนเองเริ่มมีอาการผมร่วง ผมบาง ศีรษะล้าน หรือสังเกตเห็นอาการผิดปกติอื่นๆ ควรรีบปรึกษาแพทย์เพื่อหาวิธีการป้องกันและรักษาโดยเร็วที่สุด
  `,
  treatmentHLPageFAQ3: `การรักษาผมบางผมร่วงจากฮอร์โมนผู้ชายและพันธุกรรมใช้เวลานานแค่ไหน?`,
  treatmentHLPageFAQ3Desc: `ผู้ชายที่รักษาผมร่วงจากฮอร์โมนผู้ชายและพันธุกรรมด้วย Minoxidil ชนิดพ่นและชนิดยาชนิดทานร่วมกัน จะต้องใช้ติดต่อกันอย่างน้อย 4 เดือน จึงจะเริ่มเห็นผล โดยผลของการรักษาจะยังคงอยู่เมื่อใช้ยาอย่างต่อเนื่องเท่านั้น และมีโอกาสที่ผมที่พึ่งขึ้นใหม่จะกลับมาร่วงหลังจากหยุดยา`,
  treatmentHLPageFAQ4: `ผลข้างเคียงที่อาจเกิดขึ้นจากการรักษาผมร่วง ผมบางคืออะไร?`,
  treatmentHLPageFAQ4Desc: `
  แม้ว่าการใช้ยารักษาผมร่วง ผมบาง จะมีงานวิจัยรองรับในด้านประสิทธิภาพการรักษา และความปลอดภัยในการใช้ยา แต่การใช้ยาทุกชนิดล้วนมีโอกาสเกิดผลข้างเคียงในผู้ใช้ยาบางรายได้ <br/><br/>

  ผลข้างเคียงที่อาจเกิดขึ้นจากการใช้ยาไมน็อกซิดิล (minoxidil) ได้แก่ <br/>

  <ul class="ml-10 list-disc mt-[20px]">
    <li>
      อาการคันและระคายเคืองบริเวณหนังศีรษะ 
    </li>
    <li>
      เกิดขนส่วนเกินขึ้นบริเวณใบหน้า หรือร่วงเพิ่มขึ้นในช่วงแรกของการรักษา 
    </li>
    <li>
      อาจพบอาการผมร่วงมากขึ้นได้ชั่วคราว แต่ไม่ต้องกังวล เพราะเป็นอาการที่เกิดจากการผลัดเปลี่ยนระยะของผม ซึ่งจะค่อย ๆ หายไปหลังใช้ยาอย่างต่อเนื่อง
    </li>
    <li>
     อาจมีโอกาสหย่อนสมรรถภาพทางเพศ หรือปริมาณน้ำอสุจิลดลง (พบได้น้อยมาก คิดเป็น 2%)
    </li>
    <li>
      อาจมีอาการเจ็บอัณฑะ คัดตึงที่เต้านม 
    </li>
  </ul> <br/>
  ผลข้างเคียงของยาไมน็อกซิดิลสามารถกลับสู่สภาวะปกติได้หลังหยุดใช้ยา อย่างไรก็ตาม หากคุณมีความกังวลกับผลข้างเคียง สามารถปรึกษาแพทย์หรือผู้เชี่ยวชาญเพื่อหาวิธีรับมือที่เหมาะสมได้
  `,
  treatmentHLPageFAQ5: `แพทย์หรือผู้เชี่ยวชาญสามารถแนะนำอะไรสำหรับผมร่วงได้บ้าง?`,
  treatmentHLPageFAQ5Desc: `แพทย์หรือผู้เชี่ยวชาญสามารถแนะนำการรักษาที่เหมาะสมสำหรับคุณโดยคำนึงถึงประสิทธิภาพ ความปลอดภัย และค่าใช้จ่ายในการรักษา รวมถึงแนะนำแนวทางการปฏิบัติตัวเพื่อให้การรักษาผมร่วงเป็นไปอย่างต่อเนื่องและมีประสิทธิภาพสูงสุด`,
  treatmentHLPageFAQ6: `ฉันควรใช้ยาแก้ผมร่วง ผมบางเมื่อใด เทียบกับการผ่าตัดปลูกผมได้หรือไม่?`,
  treatmentHLPageFAQ6Desc: `
  การรักษาผมร่วง ผมบางด้วยยาไมน็อกซิดิล ชนิดพ่น (minoxidil) และยาชนิดรับประทาน จะให้ประสิทธิภาพสูงในผู้ป่วยที่มีอายุน้อยและประสบปัญหาผมร่วง ผมบาง เป็นเวลาไม่นาน<br /><br />
  หรือกล่าวอีกนัยหนึ่งคือ การรักษาจะให้ผลการรักษาที่ดีขึ้น หากเริ่มการรักษาเร็ว โดยที่ความรุนแรงโรคยังไม่มาก ซึ่งนอกจากตัวยาจะช่วยหยุดการร่วงของผมแล้ว ยังสามารถช่วยให้มีผมใหม่งอกขึ้นมาอีกด้วย<br /><br />
  แต่สำหรับการผ่าตัดปลูกผม ผมของผู้ป่วยจะเริ่มขึ้นประมาณ 3 เดือนหลังการรักษาและจะงอกเต็มที่ประมาณ 1 ปี -1 ปีครึ่ง<br /><br />
  แม้ผลการรักษานี้ประสบความสําเร็จมากกว่า 90% แต่ในผู้ป่วยบางรายอาจไม่เหมาะแก่การรักษาด้วยวิธีนี้ เนื่องจากมีข้อห้ามบางอย่าง เช่น โรคเลือดแข็งตัวไม่หยุด โรคเบาหวานที่ควบคุมไม่ได้ โรคความดันโลหิตสูงที่ควบคุมไม่ได้ มีการติดเชื้อของผิวหนังบริเวณนั้น<br /><br />
  ดังนั้น แพทย์มักแนะนำให้ผู้ที่รักษาด้วยการผ่าตัดปลูกผม ใช้การรักษาผมร่วงด้วยยาไมน็อกซิดิล ชนิดพ่น (minoxidil) และยาชนิดรับประทานอย่างต่อเนื่องควบคู่ไปด้วย<br /><br />
  `,
  treatmentHLPageHaveOneOrMore: `<span class="inline-block">หากรูปแบบศีรษะของคุณเป็นแบบข้างต้น นั่นอาจเป็นสัญญาณของอาการผมร่วงจากฮอร์โมนผู้ชาย หรือพันธุกรรม</span> <span class="inline-block">ให้เราช่วยแก้ปัญหาให้คุณ</span>`,
  treatmentHLPageMDNote: `แพทย์ผู้เชี่ยวชาญแนะนำ`,
  treatmentHLPageMDNoteDesc: `เพื่อเพิ่มผลลัพธ์ในการรักษา ควรใช้ยาชนิดรับประทาน และยา Minoxidil ชนิดพ่นร่วมกันในทุกวัน ซึ่งเป็นการรักษาที่แพทย์ผิวหนังส่วนใหญ่แนะนำ สามารถใช้ได้ทั้งผู้ที่เริ่มมีอาการและผู้ที่มีอาการมาสักระยะหนึ่งแล้ว`,
  treatmentHLPagePercent94Work: `หยุดผมร่วงในผู้ชายได้ถึง 94%*`,
  treatmentHLPagePinkPillDesc: `
    <p class="text-[14px] leading-[20px] mt-[16px]">เพื่อช่วยหยุดผมร่วง และปลูกผมใหม่ โดยการชะลอการผลิต DHT เหมาะสําหรับผมบาง ผมร่วงจากฮอร์โมนผู้ชาย ช่วยลดและป้องกันการหลุดร่วงของเส้นผมได้อย่างเห็นผล</p>
    <p class="text-[14px]"><span class="font-bold">รูปแบบ:</span> ยาเม็ดรับประทาน</p>
    <p class="text-[14px]"><span class="font-bold">วิธีใช้:</span> ใช้ต่อเนื่อง</p>
    <p class="text-[14px]"><span class="font-bold">ผลลัพธ์ที่ได้:</span> ประสิทธิภาพที่สูงขึ้น</p>
  `,
  treatmentHLPagePinkPillName: `ยารักษาชนิดรับประทาน`,
  treatmentHLPageProductSectionLeftFooter: `<strong>ข้อควรรู้ก่อนใช้ยารักษาผมบางผมร่วงจากฮอร์โมนผู้ชายและพันธุกรรม</strong><br/>การใช้ยาชนิดรับประทาน และยา minoxidil ชนิดพ่น สำหรับรักษาผมร่วงแบบผู้ชายอาจต้องใช้เวลาอย่างน้อย 4 เดือนจึงจะเริ่มเห็นผล ยาจะไม่สามารถรักษาอาการผมร่วงหายขาดอย่างถาวรได้ ผลของการรักษาจะยังคงอยู่ เมื่อใช้ยาอย่างต่อเนื่องเท่านั้น มีโอกาสที่ผมที่พึ่งขึ้นใหม่จะกลับมาร่วง หลังจากหยุดยา`,
  treatmentHLPageProductSectionLeftTitle: `ศีรษะล้าน ผมร่วง ผมบาง<br /><span class="font-[CentraNo2]">Hair Loss</span>`,
  treatmentHLPageStartFrom250: `ราคาเริ่มต้นที่ 250 บาท`,
  treatmentHLPageStartOffer: `ซื้อยาแก้ผมร่วงจากฮอร์โมนผู้ชายและพันธุกรรม`,
  treatmentHLPageStartWith250: `เริ่มต้นเพียง ฿250`,
  treatmentHLPageSubtitle: `Hair Loss`,
  treatmentHLPageTitle: `<span>รักษา ผมร่วง ผมบาง ศีรษะล้าน</span><br/><span class="hl-th-subtitle">จากพันธุกรรมและฮอร์โมน</span>`,
  treatmentHLPageTopic1: `นำทุกวินาทีของความหนุ่มกลับมาด้วย HIBRO`,
  treatmentHLPageTreatmentResult: `ผลลัพธ์ในการรักษา`,
  treatmentHLPageTreatmentResultDesc: `
  ยาชนิดรับประทาน มีประสิทธิภาพในการ หยุดผมร่วงในผู้ชายได้ 83% เมื่อเทียบกับ 28% ของกลุ่มที่ใช้ยาหลอก (placebo)<br /><br />
  และ 70.6% ของผู้ชายที่ใช้ไมน็อกซิดิล (minoxidil) ชนิดพ่น 5% เป็นเวลา 16 สัปดาห์ อาการผมร่วงดีขึ้นกว่าเดิม<br /><br />
  จากการศึกษายังพบว่าการใช้ minoxidil ชนิดพ่น และรักษายาชนิดรับประทาน  ร่วมกันมีประสิทธิภาพดีกว่า (94%) เมื่อเทียบกับการใช้ยาแยกกัน ยาทั้งสองชนิดสามารถใช้ร่วมกันได้อย่างปลอดภัย`,
  treatmentHLPageTreatmentResultSrc: `<span class="font-bold">ที่มา</span> : 
  <ul class="ml-10 list-disc mt-[20px]">
  <li>
    <a target="_blank" class="text-[12px] link" href="https://pubmed.ncbi.nlm.nih.gov/9951956/">McClellan K. A review of oral tablet use in male pattern hair loss (1999)</a>
  </li>
  <li>
    <a target="_blank" class="text-[12px] link" href="https://onlinelibrary.wiley.com/doi/10.1111/dth.12246">Olsen E. 5% minoxidil topical foam versus placebo in the treatment of androgenetic alopecia in men (2007)</a>
  </li>
  <li>
    <a target="_blank" class="text-[12px] link" href="https://pubmed.ncbi.nlm.nih.gov/9951956/">Hu R. Combined treatment with oral tablet and topical minoxidil in male androgenetic alopecia (2015)</a>
  </li>
</ul>`,
  treatmentHLPageWayTreatHL: `วิธีรักษาผมร่วงผมบางผู้ชาย`,
  treatmentHLPageWayTreatHLDesc: `ในกรณีที่เป็นผมร่วงจากฮอร์โมนผู้ชายและพันธุกรรม สามารถรักษาได้ด้วยการใช้ยาชนิดพ่นไมนอกซิดิล (Minoxidil) เป็นยาใช้เฉพาะที่ หรือยาแบบรับประทาน จัดเป็นวิธีรักษาผมร่วงผมบางผู้ชายที่ได้รับการรับรองจากแพทย์ผิวหนัง มีงานวิจัยที่มีมาตรฐานสนับสนุนถึงประสิทธิภาพ และข้อมูลสำคัญด้านความปลอดภัย เพราะฉะนั้นจึงสามารถมั่นใจในการใช้ยาได้เลย`,
  treatmentHLPageWhatIsHL: `ศีรษะล้าน ผมร่วง ผมบาง คืออะไร?`,
  treatmentHLPageWhatIsHLDesc: `<p><a class='link' href="https://www.sayhibro.com/hair-loss-101">ศีรษะล้าน ผมร่วง ผมบาง</a> เป็นลักษณะอาการของ <strong> ภาวะผมบางตามพันธุกรรมและฮอร์โมน (Androgenetic Alopecia)</strong> ที่ทำให้ต่อมรากผมบริเวณหนังศีรษะมีขนาดเล็กลง ส่งผลให้เส้นผมมีลักษณะบาง และสั้นลง ทำให้เกิดอาการผมบาง และอาจเกิดอาการผมหลุดร่วงไวกว่าปกติตามมาจนกระทั่งนำไปสู่ภาวะศีรษะล้านได้ในที่สุด</p>`,
  treatmentPEPage10PercentSave: `ประหยัดถึง <span class="text-[#D60000]">10%</span>`,
  treatmentPEPage3TimesEffective: `ข่วยชะลอการหลั่งได้ถึง 3 เท่า`,
  treatmentPEPageAssured: `ได้รับรองจาก อย. ถูกต้อง`,
  treatmentPEPageAverageSex: `ระยะเวลาการมีเพศสัมพันธ์โดยเฉลี่ย`,
  treatmentPEPageBluePillDesc: `
    <p class="text-[14px] mt-[16px]"><span class="font-bold">เม็ดขาว ฟินนาน ชะลอการหลั่งได้ 2-3 เท่า!</span></p>
    <p class="text-[14px] leading-[20px]">ยาแก้หลั่งเร็ว  เหมาะสำหรับรักษาอาการหลั่งน้ำอสุจิเร็วในผู้ชายที่มีอายุระหว่าง 18-64 ปี</p>
    <p class="text-[14px]"><span class="font-bold">รูปแบบ:</span> ยาเม็ดรับประทาน</p>
    <p class="text-[14px]"><span class="font-bold">ปริมาณยา:</span> 30 mg. 60 mg.</p>
    <p class="text-[14px]"><span class="font-bold">ปริมาณแนะนำ:</span> 30 mg ใช้ชั่วคราวตามคําแนะนําของแพทย์</p>
    <p class="text-[14px]"><span class="font-bold">รับประทาน:</span> 1-3 ชั่วโมงก่อนมีเพศสัมพันธ์</p>
    <p class="text-[14px]"><span class="font-bold">ระยะเวลาออกฤทธิ์:</span> 19 ชม.</p>
    <div class="flex text-[14px]">
      <p class="font-bold">ข้อควรระวัง:</p>
      <p class="flex-1">&nbsp;ไม่ควรทานร่วมกับยากล่อมประสาท ยาละลายลิ่มเลือด ยาโรคไตหรือตับ และผู้ที่มีภาวะขาดน้ำมาก</p>
    </div>
  `,
  treatmentPEPageBluePillName: `เม็ดขาว ฟินนาน`,
  treatmentPEPageCanPECure: `ผู้ชายที่หลั่งเร็วเกิดจากอะไร?`,
  treatmentPEPageCanPECureDetail: `มีหลายสาเหตุที่ทำให้ผู้ชายหลั่งน้ำอสุจิเร็วกว่าปกติ ได้แก่`,
  treatmentPEPageCanPECureDetailPoint1: `มีปัญหาทางด้านจิตใจ เช่น ความเครียด วิตกกังวลหรือซึมเศร้า`,
  treatmentPEPageCanPECureDetailPoint2: `มีปัญหาด้านความสัมพันธ์ หรือมีประสบการณ์การมีเพศสัมพันธ์โดยไม่ต้องการ`,
  treatmentPEPageCanPECureDetailPoint3: `มีประสบการณ์ทางเพศเร็วหรือก่อนวัย`,
  treatmentPEPageCanPECureDetailPoint4: `มีปัญหาทางร่างกาย เช่น ฮอร์โมนไทรอยด์ในร่างกายมีปริมาณผิดปกติ การอักเสบของทางเดินปัสสาวะ หรือบริเวณต่อมลูกหมาก หรือความผิดปกติทางพันธุกรรม`,
  treatmentPEPageCanPECureDetailPoint5: `การใช้ยา หรือสารเสพติด`,
  treatmentPEPageCanPECureDetailPoint6: `การเกิดภาวะหย่อนสมรรถภาพทางเพศจากอายุที่เพิ่มมากขึ้น`,
  treatmentPEPageFastPE: `หลั่งเร็วผิดปกติไหม?`,
  treatmentPEPageFastPEDetail: `<p>อาการหลั่งเร็ว ไม่ถือว่าเป็นภาวะผิดปกติ และไม่ส่งผลอันตรายต่อสุขภาพร่างกาย แต่อาจส่งผลต่อความมั่นใจ หรือความสัมพันธ์ของชีวิตคู่ได้<br /><br />
  หากคุณมีอาการถึงจุดสุดยอดเร็วกว่าปกติ ไม่สามารถควบคุมการหลั่งได้ แล้วรู้สึกกังวล หงุดหงิด รำคาญใจ แนะนำให้ปรึกษาคุณหมอเพื่อรับการรักษาจะดีที่สุด</p>`,
  treatmentPEPageTreatmentPE: `รักษาอาการหลั่งเร็วได้ไหม?`,
  treatmentPEPageTreatmentPEDetail: `<p>ผู้ชายที่มีอาการหลั่งน้ำอสุจิเร็ว สามารถรักษาให้อาการดีขึ้นได้ ซึ่งแต่ละคนจะมีวิธีรักษาอาการหลั่งเร็วที่เหมาะสมแตกต่างกันไป ขึ้นอยู่กับการประเมินของแพทย์<br /><br />
  ผู้ชายบางคนสามารถรักษาได้ด้วยการใช้ยาแก้หลั่งเร็ว หรือปรับเปลี่ยนพฤติกรรม เช่น พักผ่อนให้เพียงพอ ออกกำลังกายเน้นส่วนล่าง  หรือช่วยตัวเองก่อนมีเพศสัมพันธ์ ในขณะที่บางคนอาจจำเป็นต้องใช้หลายวิธีร่วมกัน<br /><br />
  หากอยากรู้ว่า ตนเองเหมาะกับวิธีรักษาแบบไหนนั้น แนะนำให้ปรึกษาแพทย์ผู้เชี่ยวชาญจะได้รับคำตอบที่ถูกต้องและแม่นยำมากที่สุด<br /><br /></p>`,
  treatmentPEPageCausePE: `กี่นาทีถึงเรียกว่าหลั่งเร็ว?`,
  treatmentPEPageCausePEDetail: `<p>โดยทั่วไป ผู้ชายจะถึงจุดสุดยอดใช้เวลาเฉลี่ย 5.7 นาที แต่หากหลั่งน้ำอสุจิภายใน หรือไม่เกิน 1 นาที ไม่ว่าจะก่อน ระหว่าง หรือหลังสอดใส่ จะถือว่าผู้ชายคนนั้นมีภาวะโรคหลั่งเร็ว</p>`,
  treatmentPEPageClinicalProve: `ยาแก้หลั่งเร็ว รักษาอาการหลั่งอสุจิเร็วได้จริง?`,
  treatmentPEPageClinicalProveDesc: `ยาแก้หลั่งเร็ว เม็ดขาว ฟินนาน เป็นยาที่ช่วยชะลอการหลั่งอสุจิชนิดเดียวที่ได้รับการรับรองจาก อย. (1C 139/56 (N), 1C 28/61 (N)) สามารถออกฤทธิ์ควบคุมกลไกการหลั่งอสุจิ เพื่อชะลอการหลั่งได้มากถึง 2-3 เท่า โดยเฉพาะในผู้ชายที่มีช่วงอายุ 18-64 ปี`,
  treatmentPEPageDesc: `หากคุณมีอาการหลั่งก่อน หลั่งไว คุมหลั่งไม่ได้ ถึงจุดสุดยอดเร็วเกินไป 
  hibro พร้อมช่วยคุณ ด้วยทีมแพทย์ผู้เชี่ยวชาญ ให้คำแนะนำ รักษา ช่วยเพิ่มความสุข 
  เติมความมั่นใจ  และชะลอการหลั่งเพื่อความฟินที่ยาวนานแน่นอน!`,
  treatmentPEPageFAQ1: `hibro มีวิธีรักษาอาการหลั่งเร็วอย่างไร?`,
  treatmentPEPageFAQ1Desc: `แพทย์ของ hibro จะใช้วิธีรักษาอาการหลั่งเร็วแบบผสมผสาน โดยการปรับพฤติกรรมการใช้ชีวิตและการมีเพศสัมพันธ์อย่างเหมาะสม ร่วมกับรักษาด้วยยาที่ช่วยลดอาการหลั่งอสุจิเร็ว ได้รับการรับรองจากอย. ไทย รวมถึงให้คำปรึกษาข้อกังวลอื่น ๆ เพิ่มเติม ซึ่งผู้ใช้บริการสามารถปรึกษาแพทย์ของเราเพื่อวางแผนการรักษาที่เหมาะสมกับตัวเองที่สุดได้`,
  treatmentPEPageFAQ2: `จะรู้ได้อย่างไรว่ามีอาการหลั่งเร็ว?`,
  treatmentPEPageFAQ2Desc: `เมื่อไม่สามารถควบคุม หรือชะลอการหลั่งได้เกินกว่า 1 นาที อาจเกิดขึ้นได้ท้ังก่อน ระหว่าง หรือหลังการสอดใส่`,
  treatmentPEPageFAQ3: `ยาลดอาการหลั่งอสุจิเร็วมีผลออกฤทธิ์อะไรบ้าง?`,
  treatmentPEPageFAQ3Desc: `ยาเม็ดขาว ฟินนาน เป็นยาชนิดทานรายแรกที่พัฒนาขึ้นมาเพื่อการรกั ษาอาการหลัง่ น ้าอสุจิเร็ว และช่วยยับยั้งการดูดกลับของสารซีโรโทนิน (Serotonin)<br />
  เม็ดขาว ฟินนาน เป็นยาลดอาการหลัง่ น ้าอสุจิเร็วรายเดียวที่ได้รบั การรับรองในกว่า 60 ประเทศทั่ว โลก รวมถงึประเทศไทย โดยจะสามารถเพิ่มเวลาในการหลั่งได้ประมาณ 2-3 เท่า จากเวลาหลงั่ น ้า อสุจิเดิม`,
  treatmentPEPageFAQ4: `ผลข้างเคียงของยาลดการหลั่งเร็วเป็นอย่างไร?`,
  treatmentPEPageFAQ4Desc: `ยาเม็ดขาว ฟินนาน อาจท าให้เกิดผลข้างเคียงที่ไม่เป็นอันตรายได้ เช่น คลื่นไส้ มึนงง ปวดศีรษะ ท้องเดิน หรือนอนไม่หลับ เป็นต้น<br />
  อย่างไรก็ตาม หากเกดิ ผลข้างเคยี งที่เป็นอนั ตราย (พบได้น้อย) ไดแ้ก่ความดันโลหิตตา ่ เวลาที่ลกุ ขึ้นยืน ม่านตาขยาย และเจ็บตา ควรหยุดรับประทานยาและแจ้งให้แพทย์ทราบทันท<br />
  <a class='link' href="" target="_blank">โปรดดูข้อมูลผลข้างเคียงของยาเม็ดขาว ฟินนาน</a>`,
  treatmentPEPageProductSectionLeftTitle: `หลั่งเร็ว<br /><span class="font-[CentraNo2]">Premature Ejaculation (PE)</span>`,
  treatmentPEPageStart890Consult: `ปรึกษาแพทย์พร้อมรับยาแก้หลั่งเร็ว`,
  treatmentPEPageStart890: `ราคาเริ่มต้น 890 บาท`,
  treatmentPEPageStartWith890: `เริ่มต้นเพียง ฿890`,
  treatmentPEPageSubtitle: `Premature Ejaculation (PE)`,
  treatmentPEPageTitle: `รักษาอาการหลั่งเร็ว`,
  treatmentPEPageTopic1: `อย่าด่วนปิดจ๊อบ<br />ทั้งที่ความฟินยังมาไม่ถึง`,
  treatmentPEPageTopic2: `รู้จักอาการหลั่งเร็ว`,
  treatmentPEPageTreatmentResult: `ผลลัพธ์ในการรักษา`,
  treatmentPEPageTreatmentResultDesc: `
    ผลสรุปงานวิจัยทั้งเอเชียแปซิฟิกและอเมริกา พบว่า ผู้ชายมากกว่า 6,000 คน หลังใช้ยาเม็ดขาว ฟินนาน จะทำให้ระยะเวลาการหลั่งโดยเฉลี่ยเพิ่มขึ้น 3 เท่า ในผู้ที่มีปัญหาหลั่งอสุจิเร็ว<br /><br />
    จากข้อมูลเวลาแฝงของการหลั่งในช่องคลอด (IELT) หลังจากใช้ยาเม็ดขาว ฟินนาน ขนาด 30 mg. มีค่าเฉลี่ยเพิ่มขึ้นจาก 0.92 เป็น 2.78 นาที และเมื่อใช้ ขนาด 60 mg. มีค่าเฉลี่ยเวลาเพิ่มขึ้นจาก 0.91 เป็น 3.32 นาที ในขณะที่กลุ่มผู้ใช้ยาหลอก มีค่าเฉลี่ยเวลาเพิ่มขึ้นจาก 0.90 เป็น 1.75 นาที เท่านั้น<br /><br />
    จากการทดลองดังกล่าวจะเห็นได้ว่า กลุ่มที่ใช้ยาเม็ดขาว ฟินนาน จะมีระยะเวลาการหลั่งอสุจินานกว่ากลุ่มที่ใช้ยาหลอกสูงถึง 3 เท่า<br /><br />
    ยาเม็ดขาว ฟินนานนั้น เป็นยาที่ช่วยรักษาอาการหลั่งน้ำอสุจิที่ได้รับการรับรองจากอย. ไทยและได้รับการรับรองให้ใช้งานใน 60 ประเทศทั่วโลก ด้วยเหตุนี้จึงทำให้ยาเม็ดขาว ฟินนาน  เป็นหนึ่งในตัวเลือกที่ดีและปลอดภัย เหมาะที่จะนำมาใช้รักษาอาการถึงจุดสุดยอดเร็วกว่าปกตินั่นเอง`,
  treatmentPEPageTreatmentResultSrcTag: `ที่มาของข้อมูล:`,
  treatmentPEPageTreatmentResultSrc: `Sangkum P. Last Longer Pill and the treatment of premature ejaculation (2013)`,
  treatmentPEPageFrequentlyAskedQuestions: `คำถามพบบ่อยเกี่ยวกับการรักษาอาการหลั่งเร็ว`,
  treatmentPEPageWhatPE: `อาการหลั่งเร็ว (PE) คืออะไร?`,
  treatmentPEPageWhatPEDetail: `<p><a class='link' href="/premature-ejaculation-101">หลั่งเร็ว คือ</a> อาการที่ผู้ชายหลัง่ น ้าอสุจิเร็วมากกว่าที่เขา หรอืคขู่ องเขาตอ้ งการในระหว่างการมี เพศสัมพันธ์ มีลักษณะเด่นคือ ไม่สามารถชะลอ หรือควบคุมการหลั่งอสุจิได้ ก่อน ระหว่างหลังการ สอดใส่ หรือ บางครั้งไม่สามารถท าให้เกิดความพึงพอใจทั้งสองฝ่าย เรียกอีกชื่อว่า นกกระจอกไม่ ทันกินน ้า หรือล่มปากอ่าว</p>`,
  treatmentPermatureEjacPageIndex1: `โรคหลั่งเร็ว (Premature Ejaculation : PE)`,
  treatmentPermatureEjacPageIndex2: `สาเหตุของโรคหลั่งเร็ว`,
  treatmentPermatureEjacPageIndex3: `เวลาเฉลี่ยการหลั่งน้ำอสุจิที่อาจบ่งบอกถึงภาวะหลั่งเร็ว`,
  treatmentPermatureEjacPageIndex4: `การรักษาโรคหลั่งเร็ว`,
  treatmentPermatureEjacPageIndex5: `How we can help, get ahead!`,
  treatmentPermatureEjacPageSubtitle: `Premature<br />Ejaculation 101`,
  treatmentPermatureEjacPageTitle: `โรคหลั่งเร็ว หรือ`,
  welcome: `Welcome`,
  /**What we treat */
  whatwetreatDesc: `พวกเราดูแลทุกปัญหาสุขภาพชาย รับการวินิจฉัยที่ถูกต้อง พร้อมแนวทางการรักษาที่ตรงตามอาการจากผู้เชี่ยวชาญของ HIBRO`,
  whatwetreatHL: `<span>ศีรษะล้าน ผมร่วง<br />ผมบาง</span>`,
  whatwetreatStartConsult: "เริ่มปรึกษา",
  /**How it work*/
  howitworkDesc: `การตอบคำถาม ออนไลน์กับเรา`,
  howitwork1Title: `ลงทะเบียนและตอบคำถามสุขภาพ`,
  howitwork1Desc: `เลือกตอบคำถามสุขภาพเพียง10-12ข้อ หรือปรึกษาแพทย์ได้ง่ายๆ ทางโทรศัพท์`,
  howitwork2Title: `เลือกผลิตภัณฑ์ (ยา) ที่ต้องการ`,
  howitwork2Desc: `เลือกวิธีการรักษาและบริการที่ต้องการ พร้อมชำระเงินก่อนใช้บริการ`,
  howitwork3Title: `วินิจฉัยโดยแพทย์ผู้เชี่ยวชาญ`,
  howitwork3Desc: `หลังจากที่กดชำระเงิน ข้อมูลทั้งหมดจะถูกส่งไปให้แพทย์วินิจฉัยและให้คำแนะนำ หากการรักษาเหมาะสมกับคุณ ผลิตภัณฑ์ (ยา) จะถูกจัดส่งภายใน 2 วัน`,
  continueBtn: `ยอมรับ`,
  /**Choose Service*/
  chooseServiceTitle: `เลือกบริการของ HIBRO`,
  chooseServiceDesc: `เรามีบริการให้ตรงกับความต้องการของคุณ`,
  chooseProductByUser: `เลือกผลิตภัณฑ์ (ยา) ด้วยตัวเอง`,
  chooseServiceRecommendToUser: `เหมาะกับผู้เคยซื้อ หรือ ใช้บริการ และทราบเรื่องยาที่ต้องการ แค่ตอบคำถามสั้นๆ ให้แพทย์วินิจฉัยและรับยา`,
  chooseServiceConsultWithQn: `ปรึกษาด้วยการตอบคำถามสุขภาพ`,
  chooseServiceBuyWithoutDoctor: `ซื้อยาได้ทันที ไม่ต้องพบแพทย์`,
  chooseServiceAnswerQN: `ตอบคำถามเพียง 10-12 ข้อ (ประมาณ 8 นาที)`,
  chooseServiceDoctorApprove: `แพทย์อนุมัติการรักษาและใบสั่งยา ใน 24ชม. จัดส่งฟรี!`,
  chooseServiceChatWithDoctor: `คุยกับแพทย์และเภสัชกรได้ไม่จำกัด พร้อมติดตามผลการรักษาได้ง่ายๆบนแฟลตฟอร์ม`,
  chooseServiceCostConsult: `฿99`,
  chooseServiceCostPerCon: `/การปรึกษา 1 ครั้ง*`,
  chooseServiceInitConsult: `รับคำปรึกษา`,
  chooseServicePhoneConsult: `คุยส่วนตัวกับแพทย์ผู้เชี่ยวชาญ`,
  chooseServiceDoctorDiagnosis: `มีแพทย์ผู้เชี่ยวชาญของ HIBRO ให้การวินิจฉัยอาการและแนะนำยาโดยตรง แบบเป็นส่วนตัว`,
  chooseServiceTimeConsult: `ปรึกษาผ่านการโทรคุย 15 นาที`,
  chooseServiceSkipQn: `ไม่ต้องตอบแบบคำถามสุขภาพ`,
  chooseServiceDoctorEva: `แจ้งรายละเอียดและผลวินิจฉัยอาการ`,
  chooseServiceConsultViaPlatform: `ราคารวมค่าปรึกษาและออกใบสั่งแพทย์`,
  chooseServiceIncludeDocFee: `ราคารวมค่าปรึกษาและออกใบสั่งแพทย์ ไม่รวมค่าสินค้า`,
  chooseServicePhoneFee: `฿200`,
  // Registration page
  registrationCreateAccount: `ลงทะเบียน`,
  registrationFillData: `สร้างบัญชีของคุณ กรุณากรอกข้อมูลพื้นฐานและประวัติทางการแพทย์ของคุณ เพื่อให้แพทย์ใช้เป็นข้อมูลในการประเมินการรักษา และจ่ายยาที่เหมาะสม`,
  registrationHasAcc: `เป็นสมาชิกอยู่แล้ว?`,
  registrationLogin: `เข้าสู่ระบบ`,
  registrationEmail: `อีเมล`,
  registrationPassword: `รหัสผ่าน`,
  registrationConfirmPassword: `ยืนยันรหัสผ่าน`,
  registrationAgreement: `<span>โดยการเปิดบัญชี HIBRO ท่านได้อ่านและยอมรับ 
    <a href="/terms" target="_blank" class="font-semibold text-[#3E92F2] underline cursor-pointer">
    ข้อกำหนดและเงื่อนไข</a> 
    <a href="/privacy-policy" target="_blank" class="font-semibold text-[#3E92F2] underline cursor-pointer">นโยบายความเป็นส่วนตัว
  </a> และ
  <a href="/consent" target="_blank" class="font-semibold text-[#3E92F2] underline cursor-pointer">
    ความยินยอมของผู้ป่วย
  </a>
  </span>`,
  registrationRegisterText: `ลงทะเบียน`,
  registrationVia: `หรือลงทะเบียนด้วย`,
  registrationViaGoogle: `เข้าสู่ระบบด้วย Google`,
  registrationViaFacebook: `เข้าสู่ระบบด้วย Facebook`,
  registrationViaLine: `เข้าสู่ระบบด้วย Line`,
  registrationPasswordCondition: `รหัสผ่านควรมีความยาว 8 ตัวอักษรขึ้นไป`,
  registrationRequireData: `กรุณากรอกข้อมูลให้ครบถ้วน`,
  registrationEmailInvalid: `กรุณากรอกอีเมล์ให้ถูกต้อง`,
  registrationPasswordNotMatch: `กรุณากรอกรหัสผ่านให้ถูกต้อง`,
  registrationPDPA: `กรุณากดยอมรับข้อตกลง`,
  // Login
  loginWelcome: `ยินดีต้อนรับ`,
  loginOldUser: `สำหรับลูกค้าที่มีบัญชีกับHIBROอยู่แล้ว`,
  loginNewUser: `ยังไม่ได้สมัครสมาชิก?`,
  loginRegister: `ลงทะเบียน`,
  loginEmail: `อีเมล`,
  loginPassword: `รหัสผ่าน`,
  loginKeepSignIn: `Keep me signed in`,
  loginForgetPass: `ลืมรหัสผ่าน`,
  loginText: `เข้าสู่ระบบ`,
  loginViaText: `หรือเข้าสู่ระบบด้วย`,
  loginWithGoogle: `เข้าสู่ระบบด้วย Google`,
  loginWithFacebook: `เข้าสู่ระบบด้วย Facebook`,
  loginWithLine: `เข้าสู่ระบบด้วย Line`,

  // Forget Password
  forgetPasswordText: `ลืมรหัสผ่าน`,
  forgetPasswordResetEmail: `กรุณากรอกอีเมล์เพื่อกำหนดรหัสผ่านใหม่`,
  forgetPasswordEmailText: `อีเมล`,
  forgetPasswordSubmit: `ส่งข้อมูล`,
  forgetPasswordCheckEmail: `Please check your email`,
  forgetPasswordSentEmail: `รหัสผ่านใหม่จะถูกส่งไปที่อีเมล์ คุณสามารถดำเนินการตั้งรหัสผ่านใหม่ได้ผ่านลิงก์ยืนยันในอีเมล์`,
  forgetPasswordBackText: `กลับสู่หน้าหลัก`,
  //basic info
  basicInfoDesc: `กรุณากรอกข้อมูลพื้นฐานเกี่ยวกับตัวคุณเพื่อให้แพทย์ได้ทราบมากขึ้น`,
  basicInfoFirstname: `ชื่อ*`,
  basicInfoLastname: `นามสกุล*`,
  basicInfoBirth: `วันเดือนปีเกิด*`,
  basicInfoSex: `เพศทางกายภาพ*`,
  basicInfoWeight: `น้ำหนัก (กก.)*`,
  basicInfoHeight: `ส่วนสูง (ซม.)*`,
  basicInfoContinue: `ขั้นตอนถัดไป`,
  basicInfoMale: `ชาย`,
  basicInfoFemale: `หญิง`,
  basicInfoAgeError: `เฉพาะเพศชายอายุระหว่าง 20-65 ปีเท่านั้น`,
  basicInfoSexError: `เฉพาะเพศชายเท่านั้น`,
  // eCommerce Status
  ecommerceStatusConsult: `แบบทดสอบ`,
  ecommerceStatusTreatment: `แผนการรักษา`,
  ecommerceStatusQuantity: `เลือกจำนวน`,
  ecommerceStatusPayment: `ชำระเงิน`,
  ecommerceBackText: `ขั้นตอนก่อนหน้า`,
  //Medical Summary
  medicalSummaryTitle: `พักกินน้ำก่อน เดี่ยวค่อยมาต่อยกสอง`,
  medicalSummaryDesc: `ขณะนี้คุณได้ตอบคำถามสุขภาพครบถ้วนแล้ว สามารถเลือกผลิตภัณฑ์(ยา) และชำระเงิน แพทย์จะวินิจฉัยอาการจากข้อมูลและยาที่เลือก เพื่อออกใบสั่งยาที่ถูกต้อง หากมีข้อสงสัย หรือสอบถามเพิ่มเติม แพทย์จะติดต่อคุณโดยตรงเป็นส่วนตัว`,
  medicalSummarySelectTreatment: `เลือกผลิตภัณฑ์ (ยา)`,
  medicalSummaryReview: `ดูคำตอบของคุณ`,
  medicalSummaryInfo: `สรุปข้อมูลทางการแพทย์`,
  medicalSummaryEditAnswer: `สามารถแก้ไขคำตอบของคุณ ก่อนไปขั้นตอนถัดไป`,
  medicalSummaryQuestionText: `คำถาม`,
  medicalSummaryAnswerText: `คำตอบของคุณ`,
  medicalSummaryNext: `ขั้นตอนถัดไป`,
  //Catalog
  catalogTitle: `แบบการรักษา(ยา)ที่เหมาะสมสำหรับอาการของคุณ คือ`,
  catalogTreatmentNumber: `ยาตัวที่`,
  catalogDesc: `สามารถเลือกยี่ห้อยา และ ปริมาณยาที่ต้องการได้`,
  catalogAllOption: `ทั้งหมด`,
  ยี่ห้ออื่น: `ยี่ห้ออื่น`,
  ทานต่อเนื่องเป็นประจำทุกวัน: `ทานต่อเนื่องเป็นประจำทุกวัน`,
  catalogNext: `ต่อไป`,
  catalogSelectTreatment: `เลือกยาตัวนี้`,
  catalogConditionText: `การรักษาที่เป็นมาตรฐานสำหรับเงื่อนไขของคุณ`,
  catalogNotWishTreatment: `ไม่ต้องการซื้อยาตัวนี้`,
  catalogRemoveOption: `กดเพื่อยกเลิกรายการยาจากตะกร้าสินค้า`,
  //Select Quantity
  selectQuantityTitle: `คุณต้องการใช้ยาจำนวนกี่กล่อง`,
  selectQuantityCondition: `หากแพทย์เห็นว่าการรักษาไม่เหมาะสม หรือปฏิเสธการรักษา ทางเราจะคืนเงินให้ทั้งหมด`,
  selectQuantityOnlineFee: `ค่าปรึกษาออนไลน์`,
  selectQuantityShipping: `ค่าขนส่ง`,
  selectQuantityFree: `ฟรี`,
  selectQuantityDiscount: `ส่วนลดทั้งหมด`,
  selectQuantityTotal: `ยอดชำระทั้งหมด`,
  selectQuantityNext: `ต่อไป`,
  selectSubscriptionNext: `สั่งซื้อ`,
  selectQuantityMaximumText1: `จำกัดไม่เกิน`,
  selectQuantityMaximumText2: `กล่องต่อการสั่งซื้อ`,
  //Select Subscription
  selectSubscriptionTitle: `ประหยัดมากขึ้นเมื่อสั่งยาล่วงหน้าแบบรายเดือน`,
  selectSubscriptionDesc: `เพื่อควบคุมปริมาณการใช้ยาที่ถูกต้อง HIBRO จะทยอยส่งสินค้า (ยา) ในปริมาณสำหรับใช้ 1 เดือน ต่อการส่ง 1 ครั้ง`,
  selectSubscriptionDescHL: `ใช้ finasteride และ minoxidil อาจต้องใช้เวลาอย่างน้อย 4 เดือนจึงจะเห็นผล การงอกของเส้นผมจะยังคงอยู่เมื่อใช้ยาเท่านั้น ผมที่ขึ้นใหม่จะร่วงภายใน 6 เดือน หลังจากหยุดยา`,
  selectSubscriptionChoosePlan: `เลือกแผนสั่งซื้อ`,
  selectSubscriptionOneTime: `ซื้อครั้งเดียว`,
  selectSubscriptionOriginalPrice: `ด้วยราคาปกติ`,
  selectSubscriptionPurchaseFor: `ซื้อล่วงหน้า`,
  selectSubscriptionMonth: `เดือน`,
  selectSubscriptionShipping: `ทยอยส่งของเดือนนี้ และอีกครั้งเดือนถัดไป`,
  selectSubscriptionShippingFinal: `ทยอยส่งเดือนละครั้งจนครบ`,
  selectSubscriptionCondition: `หากแพทย์เห็นว่าการรักษาไม่เหมาะสม หรือปฏิเสธการรักษา ทางเราจะคืนเงินให้ทั้งหมด`,
  // Upload ID
  verifyIDTitle: `การยืนยันตัวตน`,
  verifyIDDesc: `ตามเงื่อนไขของการให้บริการทางการแพทย์ออนไลน์ จำเป็นต้องตรวจสอบข้อมูลจาก บัตรประจำตัวประชาชนหรือหนังสือเดินทาง พร้อมรูปถ่ายผู้รับบริการ ในการยืนยันความถูกต้อง เพื่อความปลอดภัยและประโยชน์ในการรักษาที่เหมาะสมของตัวคุณเอง`,
  verifyIDNext: `กด เพื่อยืนยันตัวตน`,
  verifyIDUploadIDTitle: `การยืนยันตัวตน`,
  verifyIDUploadIDDesc: `กรุณาถ่าย/อัปโหลดภาพถ่ายคุณ พร้อมถือบัตรประชาชน`,
  verifyIDUploadButton: `ถ่ายภาพ / อัปโหลด`,
  verifyIDUploadDesc: `<ul className="list-disc list-inside"><li>ถือบัตรใกล้กับหน้า ต้องไม่บังส่วนใดส่วนหนึ่งของหน้า</li><li>อย่าใส่หมวกหรือแว่นตาดำ</li><li>รูปต้องชัดเจน เห็นตัวอักษรบนบัตร</li></ul>`,
  verifyIDNumberInput: `เลขบัตรประจำตัวประชาชน*`,
  verifyIDNumberPlaceholder: `กรอกโดยไม่ต้องเว้นวรรค`,
  verifyIDconfirm: `ยืนยัน`,
  // Delivery
  deliveryTitle: `รายละเอียดการจัดส่ง`,
  deliveryOrderSummary: `คำสั่งซื้อ`,
  freeConsult: `ค่าปรึกษา`,
  deliveryOnlineFee: `ค่าปรึกษาออนไลน์`,
  deliveryShipping: `ค่าขนส่ง`,
  deliveryDiscount: `ส่วนลด`,
  deliveryTotal: `ยอดชำระทั้งหมด`,
  deliveryAddressDesc: `รายละเอียดการจัดส่ง`,
  deliveryShippingNote: `ไม่ต้องกังวล เราจัดส่งด้วยกล่องพัสดุที่ปกปิดมิดชิด`,
  deliveryName: `ชื่อ - นามสกุล*`,
  deliveryAddressText: `ที่อยู่สำหรับจัดส่ง*`,
  deliveryAddressNumber: `บ้านเลขที่/ห้องชุด`,
  deliveryProvince: `จังหวัด*`,
  deliveryDistrict: `เขต*`,
  deliverySubdistrict: `แขวง*`,
  deliveryPostalCode: `รหัสไปษณีย์*`,
  deliveryNote: `ข้อความถึงคนส่ง`,
  deliveryNotePlaceholder: `เช่น วางพัสดุไว้ชั้นล่าง...`,
  deliveryContactInfo: `ข้อมูลติดต่อ`,
  deliveryContactInfoDesc: `เพื่อให้เจ้าหน้าที่เภสัชกรและพนักงานขนส่งของเรา ติดต่อจัดส่งพัสดุและอธิบายการใช้งานให้คุณ`,
  deliveryPhoneInput: `เบอร์โทรศัพท์*`,
  deliveryPreferTime: `เวลาที่สะดวก`,
  deliveryNext: `ต่อไป`,
  deliveryInvalidCoupon: `รหัสส่วนลดไม่ถูกต้อง`,
  deliveryReenterCoupon: `กรุณากรอกรหัสส่วนลดใหม่ที่ถูกต้อง`,
  deliveryBack: `ตกลง`,
  deliveryCouponLimit: `สิทธิ์เต็ม รหัสส่วนลดมีจำนวนจำกัด`,
  deliveryCouponError: `กรุณาตรวจสอบเงื่อนไข`,
  // Checkout
  checkoutTitle: `เลือกวิธีชำระเงิน`,
  checkoutOrderSummary: `คำสั่งซื้อ`,
  checkoutOnlineFee: `ค่าปรึกษาออนไลน์`,
  checkoutShipping: `ค่าขนส่ง`,
  checkoutDiscount: `ส่วนลด`,
  checkoutTotal: `ยอดชำระทั้งหมด`,
  checkoutPaymentMethod: `วิธีการชำระเงิน`,
  checkoutCreditCard: `บัตรเครดิต / เดบิต`,
  checkoutPromptpay: `พร้อมเพย์ / QR Payment`,
  checkoutTransfer: `โอนผ่านบัญชีธนาคาร`,
  checkoutCreditCardNote: `เราร่วมมือกับ GB Prime Pay เพื่อให้มั่นใจว่ารายละเอียดบัตรเครดิต/เดรบิตของคุณจะปลอดภัย HIBRO จะไม่สามารถเข้าถึงข้อมูลบนบัตรเครดิต/เดรบิตของคุณได้`,
  checkoutCardNumber: `หมายเลขบัตรเครดิต*`,
  checkoutCardNumberPlaceholder: `หมายเลขบัตร 16 หลัก`,
  checkoutExpireDate: `วันหมดอายุ*`,
  checkoutExpireDatePlaceholder: `ดด/ปป`,
  checkoutCVV: `CVV*`,
  checkoutCVVPlaceholder: `CVV 3 หลัก`,
  checkoutCardName: `ชื่อบนบัตร`,
  checkoutCardNamePlaceholder: `ชื่อผู้ถือบัตร`,
  checkoutRememberCard: `บันทึกรายละเอียดบัตรของฉันสำหรับการซื้อครั้งต่อไป`,
  checkoutPayAgreement: `การกด "ชำระเงิน" แสดงว่าคุณยอมรับหลักเกณฑ์ด้านความปลอดภัยของเรา สำหรับข้อมูลส่วนบุคคลของคุณ จะถูกใช้ในการประมวลผลคำสั่งซื้อ ช่วยเหลือในการใช้เว็บไซด์ และเพื่อวัตถุประสงค์อื่นๆ ตามนโยบายความเป็นส่วนตัวของเรา`,
  checkoutPlaceOrder: `ชำระเงิน`,
  checkoutTransferNote: `เพื่อความรวดเร็วในการยืนยันการชำระเงินของท่าน HIBRO ขอแนะนำให้ท่านอัพโหลด/แนบหลักฐานการโอนเงินที่ได้รับจาก Mobile Banking หรือ Internet Banking และหากท่านชำระเงินผ่านตู้ ATM หรือเค้าเตอร์ธนาคาร ให้ถ่ายรูปใบโอน/ชำระเงินหรือสลิปอัพโหลด/แนบบนระบบแทน`,
  checkoutName: `ชื่อ-นามสกุล:`,
  checkoutOrderNumber: `หมายเลขคำสั่งซื้อ:`,
  checkoutBankSelect: `เลือกธนาคาร*`,
  checkoutSCB: `ธนาคารไทยพาณิชย์`,
  checkoutEndose: `บริษัท เอนโดส จำกัด`,
  checkoutUploadSlip: `หลักฐานการชำระเงิน*`,
  checkoutAddNewCard: `เพิ่มบัตรใหม่`,
  // Order Success
  orderSuccessTitle: `ขอบคุณสำหรับการซื้อของคุณ`,
  orderSuccessDesc: `เราจะจัดส่งยาภายใน 1-2 วันทำการ พร้อมแจ้งสถานะการส่งให้ทราบผ่านทาง Email`,
  orderSuccessOrderSummary: `คำสั่งซื้อ`,
  orderSuccessOnlineFee: `ค่าปรึกษาออนไลน์`,
  orderSuccessShipping: `ค่าขนส่ง`,
  orderSuccessDiscount: `ส่วนลด`,
  orderSuccessTotal: `ยอดชำระทั้งหมด`,
  orderSuccessOrderID: `หมายเลขสั่งซื้อ:`,
  orderSuccessPurchaseAt: `วันที่สั่งซื้อ:`,
  orderSuccessPaymentMethod: `วิธีการชำระเงิน:`,
  orderSuccessShippingAddress: `ที่อยู่ในการจัดส่ง:`,
  orderSuccessPhoneNumber: `เบอร์โทร:`,
  orderSuccessPreferTime: `เวลา:`,
  orderSuccessBackHome: `กลับสู่หน้าหลัก`,
  orderSuccessQuestion: `มีคำถามหรือข้อสงสัย? <a href="/contact" class="underline">ติดต่อเรา</a>`,
  orderSuccessThankyouDesc: `ความคิดเห็นของคุณสำคัญสำหรับเรา ขอบคุณที่เลือกใช้บริการของ HIBRO 
  ทุกความคิดเห็นของคุณ เราจะนำไปปรับปรุงและพัฒนาการให้บริการ`,
  orderSuccessRateTitle: `โปรดให้คะแนนความพึงพอใจบริการของ HIBRO*`,
  orderSuccessSuggestion: `มีข้อเสนอแนะหรือติชม`,
  orderSuccessFeedback: `Your feedback here...`,
  orderSuccessHowyouknow: `คุณรู้จัก HIBRO ได้ยังไง*`,
  orderSuccess: `ชื่อเล่น*`,
  orderSuccessFeedbackSubmit: `ส่งข้อมูล`,
  // Phone Consult
  ed: `หย่อนสมรรถภาพทางเพศ`,
  pe: `การหลั่งเร็ว`,
  hl: `ศีรษะล้าน ผมร่วง ผมบาง`,
  phoneConsultAppointment: `นัดหมาย`,
  phoneConsultPayment: `ชำระเงิน`,
  phoneConsultConsult: `ปรึกษาแพทย์`,
  phoneConsultAppointmentTitle: `ทำการนัดหมายแพทย์`,
  phoneConsultAppointmentDesc: `กรุณาระบุอาการ และเลือกวันเวลาที่สะดวก โดยแพทย์จะโทรกลับหลังจากที่ระบบมอบหมายแพทย์แล้ว`,
  phoneConsultAppointmentPhone: `เบอร์โทรศัพท์*`,
  phoneConsultAppointmentPhonePlaceholder: `enter your number`,
  phoneConsultAppointmentPreferDate: `วันที่สะดวก*`,
  phoneConsultAppointmentEveryday: `ทุกวัน`,
  phoneConsultAppointmentMonday: `จันทร์`,
  phoneConsultAppointmentTuesday: `อังคาร`,
  phoneConsultAppointmentWednesday: `พุธ`,
  phoneConsultAppointmentThursday: `พฤหัสสบดี`,
  phoneConsultAppointmentFriday: `ศุกร์`,
  phoneConsultAppointmentSaturday: `เสาร์`,
  phoneConsultAppointmentSunday: `อาทิตย์`,
  phoneConsultAppointmentPreferTime: `เวลาที่สะดวก*`,
  phoneConsultAppointmentMorning: `ช่วงเช้า 8.00-12.00 น.`,
  phoneConsultAppointmentAfternoon: `ช่วงบ่าย 13.00-17.00 น.`,
  phoneConsultAppointmentNight: `นอกเวลาทำการ 17.00-21.00 น.`,
  phoneConsultAppointmentDetail: `รายละเอียด ความต้องการที่ต้องการให้แพทย์ทราบ`,
  phoneConsultAppointmentDetailPlaceholder: `Write down here...`,
  phoneConsultAppointmentNote: `*กรุณาเตรียมข้อมูลล่วงหน้าเพื่อประโยชน์ในการซักถามของแพทย์ เนื่องจากมีเวลา 15 นาทีในการรับการปรึกษา`,
  phoneConsultAppointmentAgree: `ฉันยินยอมให้เปิดเผยข้อมูลแก่คลีนิคนอกแพลตฟอร์มเพื่อประโยชน์ทางการรักษา*`,
  phoneConsultAppointmentSubmit: `ส่งข้อมูล`,
  medicalExpertDesc: `ด้วยทีมแพทย์ผู้เชี่ยวชาญด้านทางเดินปัสสาวะและสุขภาพทางเพศชายที่มากประสบการณ์ และความเชี่ยวชาญกว่า 10 ปี พร้อมให้คำปรึกษาและแนะนำเฉพาะเจาะจงสำหรับปัญหาของคุณ`,
  "นพ. อาคเนย์ วงษ์สวัสดิ์": `นพ. อาคเนย์ วงษ์สวัสดิ์`,
  "นพ. อาคเนย์ วงษ์สวัสดิ์_Desc": `"ศัลยแพทย์ระบบทางเดินปัสสาวะ<br/>โรงพยาบาลเมด พาร์ค กรุงเทพฯ"`,
  "นพ. พชรวรรธน์ จิระสุทัศน์": `นพ. พชรวรรธน์ จิระสุทัศน์`,
  "นพ. พชรวรรธน์ จิระสุทัศน์_Desc": `แพทย์ผู้เชี่ยวชาญด้านโรคผิวหนังประจำ<br/>สถาบันโรคผิวหนัง กรมการแพทย์<br/>กระทรวงสาธารณะสุข กรุงเทพมหานคร`,
  "นพ. ชยุตม์ ฝูงทองเจริญ": `นพ. ชยุตม์ ฝูงทองเจริญ`,
  "นพ. ชยุตม์ ฝูงทองเจริญ_Desc": `CEO และผู้อำนวยการฝ่ายการแพทย์<br/>HE clinic ทองหล่อ`,
  "นพ. ศิริวิฐพ์ หอสูติสิมา": `นพ. ศิริวิฐพ์ หอสูติสิมา`,
  "นพ. ศิริวิฐพ์ หอสูติสิมา_Desc": `แพทย์ผู้เชี่ยวชาญเวชปฏิบัติทั่วไป`,
  "นพ. วิศรุต ภาคลาภ": `นพ.วิศรุต ภาคลาภ`,
  "นพ. วิศรุต ภาคลาภ_Desc": `แพทย์ผู้เชี่ยวชาญเวชปฏิบัติทั่วไป`,
  "นพ. กฤษณ์ สันธนพิพัฒน์กุล": `นพ.กฤษณ์ สันธนพิพัฒน์กุล`,
  "นพ. กฤษณ์ สันธนพิพัฒน์กุล_Desc": `ศัลยกรรมระบบทางเดินปัสสาวะ`,
  "นพ. ศุภวัฒน์ วีรภัทรรุ่งโรจน์": `นพ.ศุภวัฒน์ วีรภัทรรุ่งโรจน์`,
  "นพ. ศุภวัฒน์ วีรภัทรรุ่งโรจน์_Desc": `แพทย์ผู้เชี่ยวชาญด้านเวชปฏิบัติทั่วไป`,
  // Phone Consult - transition
  phoneConsultTransitionTitle: `ส่งข้อมูลสำเร็จ`,
  phoneConsultTransitionDesc: `ขอบคุณสำหรับข้อมูล 
  กรุณาชำระเงินค่าปรึกษาในหน้าถัดไป เพื่อให้แพทย์ได้ติดต่อกลับหาคุณเร็วที่สุด`,
  phoneConsultTransitionPayment: `ดำเนินการชำระเงิน`,
  phoneConsultTransitionOnline: `ค่าปรึกษาออนไลน์ (15นาที)`,
  phoneConsultTransitionConsult: `ค่าใช้จ่ายสำหรับการปรึกษากับแพทย์ผู้เชี่ยวชาญ`,
  // Phone Consult - medical summary
  phoneConsultCompleteTitle: `เราได้รับการนัดหมายของคุณแล้ว`,
  phoneConsultCompleteDesc: `เราได้รับการนัดหมายของคุณแล้ว แพทย์จะโทรกลับตามวันและเวลาที่คุณสะดวก หลังจากที่ระบบมอบหมายแพทย์แล้ว คุณสามารถติดตามขั้นตอนการนัดหมายเพิ่มเติม
  ได้ในเมนูบัญชีผู้ใช้ของคุณ`,
  phoneConsultCompleteQuestion: `<p>หากมีคำถามหรือสงสัย สามารถติดต่อเราได้ที่</p>
  <p>Line: @HIBRO (มี@ข้างหน้า)</p>
  <p>Email: care@sayHIBRO.com</p>`,
  phoneConsultCompleteDetail: `รายละเอียดและข้อมูลที่สามารถติดต่อกลับ`,
  phoneConsultCompleteName: `ชื่อ`,
  phoneConsultCompleteCondition: `การรักษา`,
  phoneConsultCompletePhone: `เบอร์โทรศัพท์`,
  phoneConsultCompleteDate: `วันที่สะดวก`,
  phoneConsultCompleteTime: `เวลาที่สะดวก`,
  phoneConsultCompleteSymptom: `ระบุอาการ รายละเอียดความต้องการที่ต้องการให้คุณหมอทราบ`,
  phoneConsultCompleteBackHome: `กลับสู่หน้าหลัก`,
  phoneConsultCompleteViewAppointment: `ดูข้อมูลการนัดหมาย`,
  //Phone Consult - medical summary next steps
  phoneConsultCompleteTitle: `ผลการวินิจฉัยของคุณ`,
  phoneConsultCompleteIntro: `ขอขอบคุณที่กรุณาสละเวลาคุยโทรศัพท์กับผมในวันที่`,
  phoneConsultCompleteDiscussion: `จากการสนทนาของพวกเรา`,
  phoneConsultProductIntro: `จากการประเมินและวินิจฉัยสุขภาพของคุณในปัจจุบัน พิจารณาเห็นว่าการใช้`,
  phoneConsultProductEnd: `เป็นวิธีการรักษาที่เหมาะสมสำหรับคุณ`,
  phoneConsultSildenafilDetail1: `ซิลเดนาฟิล หรือเป็นที่รู้จักกันทั่วไปว่า “ไวอากร้า” ใช้ในการรักษาภาวะหย่อนหรือเสื่อมสมรรถภาพทางเพศในผู้ชาย ทำงานโดยเพิ่มการไหลเวียนของเลือดไปยังองคชาตในระหว่างการกระตุ้นทางเพศ ยาจะยับยั้งการทำงานของเอนไซม์ที่เรียกว่า PDE5 ที่กระตุ้นการสลายตัวของ cGMP ซึ่งมีส่วนในการควบคุมการไหลเวียนโลหิตบริเวณองคชาต ส่งผลให้การแข็งตัวขององคชาตดีขึ้น`,
  phoneConsultSildenafilDetail2: `แม้ว่ายาจะมีประสิทธิภาพช่วยในการแข็งตัวของอวัยวะเพศ แต่การใช้ Sildenafil ในการรักษาหย่อนสมรรถภาพทางเพศ ผลลัพท์ที่ได้ในแต่ละคนย่อมมีความแตกต่างกัน ส่วนหนึ่งขึ้นอยู่กับสุขภาพของแต่ละคนเช่นกัน หากคุณต้องการปรับใช้การรักษาแบบอื่นๆ สามารถสอบถามเพิ่มเติมได้โดยการส่งข้อความถึงผมโดยตรง ที่ส่วน “โปรไฟล์ของฉัน” หรือไปที่ขั้นตอนถัดไป เพื่อเลือกปุ่ม “กล่องข้อความ” และเลือก หมายเลขเคส เพิ่มเริ่มการสอบถาม`,
  phoneConsultTadalafilDetail1: `ทาดาลาฟิล หรือที่รู้จักในชื่อว่า เซียลิส (Cialis) ใช้ในการรักษาภาวะหย่อนหรือเสื่อมสมรรถภาพทางเพศในผู้ชาย ทำงานโดยเพิ่มการไหลเวียนของเลือดไปยังองคชาตในระหว่างการกระตุ้นทางเพศ ยาจะยับยั้งการทำงานของเอนไซม์ที่เรียกว่า PDE5 ที่กระตุ้นการสลายตัวของ cGMP ซึ่งมีส่วนในการควบคุมการไหลเวียนโลหิตบริเวณองคชาต ส่งผลให้การแข็งตัวขององคชาตดีขึ้น สามารถใช้ขนาด 20 มก. ได้ตามความจำเป็น และใช้ในปริมาณ5 มก.ที่ต่ำกว่า ได้ในทุกวัน`,
  phoneConsultTadalafilDetail2: `แม้ว่ายาจะมีประสิทธิภาพช่วยในการแข็งตัวของอวัยวะเพศ แต่การใช้ ทาดาลาฟิล : Tadanafilในการรักษาหย่อนสมรรถภาพทางเพศ ผลลัพท์ที่ได้ในแต่ละคนย่อมมีความแตกต่างกัน ส่วนหนึ่งขึ้นอยู่กับสุขภาพของแต่ละคนเช่นกัน หากคุณต้องการปรับใช้การรักษาแบบอื่นๆ สามารถสอบถามเพิ่มเติมได้โดยการส่งข้อความถึงผมโดยตรง ที่ส่วน “โปรไฟล์ของฉัน” หรือไปที่ขั้นตอนถัดไป เพื่อเลือกปุ่ม “กล่องข้อความ” และเลือก หมายเลขเคส เพิ่มเริ่มการสอบถาม`,
  phoneConsultPriligyDetail1: `เม็ดขาว ฟินนาน หรือที่รู้จักในชื่อว่า พริลิจี้ เป็นยาที่ใช้ในการรักษาอาการหลั่งเร็วในผู้ชาย เป็นยาในกลุ่มเอสเอสอาร์ไอ Selective serotonin reuptake inhibitor (SSRI) ช่วยเพิ่มหรือปรับปรุงการควบคุมการหลั่ง และการยับยั้งการดูดกลับของสารซีโรโทนิน (Serotonin) ลดความกังวลใจ และช่วยเพิ่มความพึงพอใจของคุณกับการมีเพศสัมพันธ์ได้ สามารถใช้ตามความจำเป็นก่อนมีกิจกรรมทางเพศ`,
  phoneConsultRejectDetail1: `จากการประเมินภาวะสุขภาพและเงื่อนไขสุขภาพของคุณในปัจจุบัน  พบบางเงื่อนไขหรือบางอาการที่ไม่สามารถรับบริการการปรึกษาหรือพบแพทย์ทางออนไลน์ได้ หากคุณกำลังมองหาแผนการรักษาที่เหมาะสมสำหรับอาการของคุณ ขอแนะนำให้ไปยังคลินิก โรงพยาบาล หรือสถานพยาบาลที่ใกล้เคียงเพื่อปรึกษาแพทย์เฉพาะทางและตรวจสุขภาพของคุณอย่างละเอียด`,
  phoneConsultRejectDetail2: `HIBRO ทำงานร่วมกับผู้ให้บริการด้านสุขภาพมากมาย เพื่อให้แน่ใจว่าผู้ใช้ของเราสามารถเข้าถึงผู้ให้บริการได้ กรุณากด “ขั้นตอนถัดไป” เพื่อทำการนัดหมาย โดยเจ้าหน้าที่ของคลินิกพันธมิตรของเรา จะติดต่อกลับหรือโทรหาคุณโดยเร็วที่สุด`,
  phoneConsultRejectDetail3: `หากคุณมีคำถามใดๆ สามารถส่งข้อความ ที่ “โปรไฟล์ของฉัน”`,
  phoneConsultSafetyIntro: `โปรดอ่าน`,
  phoneConsultSafetyText: `ข้อมูลความปลอดภัยของยา`,
  phoneConsultSafetyEnd: `อย่างละเอียดก่อนใช้ยา หากคุณมีคำถามใดๆ เกี่ยวกับการใช้ยาและผลข้างเคียงที่อาจเกิดขึ้น สามารถส่งข้อความหาผม ที่ “โปรไฟล์ของฉัน” หรือทีม HIBRO สำหรับข้อมูลเพิ่มเติม`,
  phoneConsultCloseText1: `หลังจากจัดส่ง ทีมเภสัชกรของเราจะโทรหาคุณเพื่อติดตาม เพื่อให้แน่ใจว่าคุณได้รับยาและอธิบายวิธีการใช้ยา`,
  phoneConsultCloseText2: `ขอขอบคุณอีกครั้งที่ใช้บริการของ HIBRO และสละเวลาตอบคำถามเกี่ยวกับสุขภาพของคุณ`,
  phoneConsultRegards: `ขอแสดงความนับถือ`,
  phoneConsultLicense: `เลขที่ใบประกอบวิชาชีพเวชกรรม`,
  phoneConsultCompleteSuggestion: `การรักษา(ยา)ที่เหมาะสมสำหรับอาการของคุณ คือ`,
  //Rejection flow
  rejectTitle: `ขออภัยเป็นอย่างยิ่ง`,
  rejectDesc: `คุณไม่สามารถรักษาผ่านระบบออนไลน์ได้`,
  rejectCause: `เนื่องด้วย:`,
  rejectPhoneConsult: `ปรึกษาผ่านโทรศัพท์`,
  rejectPhoneConsultPrice: `(300 บาท)`,
  rejectAppointment: `นัดหมายเพื่อพบแพทย์`,
  rejectAppointmentLine2: `ด้วยตนเอง`,
  rejectNote: `ในบางกรณี คุณอาจจะต้องพูดคุยกับแพทย์ก่อน เพื่ออนุมัติการรักษา`,
  // Rejection flow - referral form
  referalTitle: `แบบฟอร์มส่งต่อผู้ป่วย`,
  referalDesc: `กรุณากรอกรายละเอียดและข้อมูลที่สามารถติดต่อกลับ`,
  referalArea: `เขตที่อาศัยอยู่ (กรุณาระบุ เพื่อความสะดวกในการพบแพทย์ที่ใกล้ที่สุด)`,
  referalSymptom: `รายละเอียด ความต้องการที่ต้องการให้แพทย์ทราบ`,
  referalSymptomPlaceholder: `Write down here...`,
  // Rejection flow - submission
  rejectSubmitTitle: `ส่งข้อมูลสำเร็จ`,
  rejectSubmitDesc: `ขอบคุณสำหรับข้อมูล เจ้าหน้าที่ของคลีนิกใกล้เคียง จะติดต่อคุณกลับโดยเร็วที่สุด`,
  rejectSubmitBack: `กลับสู่หน้าหลัก`,
  //Questionnaire
  backQuestion: `ย้อนกลับ`,
  nextQuestion: `ข้อถัดไป`,
  //Treatment Profile
  validateSlip: "กำลังตรวจสอบการชำระเงิน",
  continueOrder: "ดำเนินการสั่งซื้อต่อ",
  paidOrder: "ชำระเงินสำเร็จ กำลังดำเนินการ",
  inreviewOrder: "แพทย์กำลังวินิจฉัย",
  onShipping: "กำลังจัดส่ง",
  nextStep: "ขั้นตอนต่อไป",
  //Profile
  cancelSaveProfile: "ยกเลิก",
  saveProfile: "บันทึก",
  saveProfileSuccess: "บันทึกสำเร็จ",
  //Profile Password
  changePasswordEmail: "อีเมล",
  changePasswordOld: "รหัสผ่านเดิม",
  changePasswordNew: "รหัสผ่านใหม่",
  changePasswordFail: "เปลี่ยรหัสผ่านไม่สำเร็จ",
  //Switch Phone Consult
  switchPopupTitle: "เปลี่ยนเป็นปรึกษาผ่านโทรศัพท์",
  switchPopupDesc:
    "เหนื่อยตอบคำถามสุขภาพใช่มั๊ย? ปรึกษากับคุณหมอทางโทรศัพท์โดยตรง",
  switchTitle: "คุณแน่ใจที่เปลี่ยนเป็นปรึกษาทางโทรศัพท์แทน?",
  switchDesc:
    "การให้คำปรึกษาทางโทรศัพท์กับแพทย์โดยตรงเป็นวิธีที่เร็วกว่าการตอบคำถามสุขภาพ ซึ่งไม่ต้องกรอกรายละเอียดทั้งหมด และข้อมูลที่คุณกรอกทั้งหมดจะถูกยกเลิก เมื่อคุณยืนยันใช้การปรึกษาทางโทรศัพท์แทน",
  switchButton: "เริ่มต้น ฿300",
  switchCancelButton: "ตอบคำถามสุขภาพต่อ",
  //Returning User Journey
  homePopupDescription: "กรุณาเลือกเพื่อทำรายการต่อ",
  homePopupGoToTreatment: "ทำแบบทดสอบต่อ",
  homePopupViewDiagnosisED: "ผลการจากปรึกษา (ED)",
  homePopupViewDiagnosisPE: "ผลการจากปรึกษา (PE)",
  homePopupGoToChat: "คุยกับแพทย์",
  homePopupBuyAgain: "ซื้ออีกครั้ง",
};
