import { SET_MODAL } from "../action/type";

const initialState = {
  content: false,
};
const modalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MODAL:
      return {
        content: payload,
      };
    default:
      return state;
  }
};
export default modalReducer;
