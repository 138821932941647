import React, { useContext, useState } from "react";

import { useRouter } from "next/router";
import { I18nContext } from "./Translation";

export const LanguageSelect = (props) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const { dispatch, langCode } = useContext(I18nContext);

  const onLanguageClick = (value) => {
    setOpen(false);
    dispatch({ type: "setLanguage", payload: value });
  };
  const styles = {
    selected: { backgroundColor: "#ddd" },
    unselected: { backgroundColor: "#fff" },
  };

  return (
    <div className="flex items-center">
      <p
        onClick={() => {
          const path = `https://www.sayhibro.com${router.pathname}`;
          if (langCode !== "th") {
            router.replace(path);
          }
          // onLanguageClick("th");
        }}
        className={`cursor-pointer pr-[8px] border-r border-[#DCDCDC] ${
          langCode === "th" ? "text-[#2A2A2A]" : "text-[#A1A1A1]"
        }`}
      >
        TH
      </p>
      <p
        onClick={() => {
          const path = `https://en.sayhibro.com${router.pathname}`;
          if (langCode !== "en") {
            router.replace(path);
          }
          // onLanguageClick("en");
        }}
        className={`cursor-pointer pl-[8px] ${
          langCode === "en" ? "text-[#2A2A2A]" : "text-[#A1A1A1]"
        }`}
      >
        EN
      </p>
    </div>
  );
};
