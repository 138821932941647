/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuote = /* GraphQL */ `
  query GetQuote(
    $items: [QuoteItemInput!]!
    $categoryID: ID!
    $promotionID: String
    $userID: String
    $orderID: String
  ) {
    getQuote(
      items: $items
      categoryID: $categoryID
      promotionID: $promotionID
      userID: $userID
      orderID: $orderID
    ) {
      items {
        productID
        productDiscountID
        quantity
        subtotal
        totalPrice
        totalDiscount
      }
      payment {
        method
        totalPrice
        promotionDiscount
        totalDiscount
        consultPrice
        consultPriceDiscount
        shippingFee
        vat
        ref
        qrcode
        creditCard {
          token
          number
          expirationMonth
          expirationYear
          name
        }
        note
      }
      promotionError
      promotionErrorMessage
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($name: String!) {
    getSetting(name: $name) {
      name
      value
      attaches {
        bucket
        key
        region
        identityId
        level
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $name: String
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettings(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        value
        attaches {
          bucket
          key
          region
          identityId
          level
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listReviews = /* GraphQL */ `
  query ListReviews($filter: ModelReviewFilterInput, $limit: Int) {
    listReviews(filter: $filter, limit: $limit) {
      items {
        id
        date
        description
        name
        score
        createdAt
        updatedAt
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($email: AWSEmail!) {
    getUser(email: $email) {
      email
      notificationEmail
      phone
      idCardNumber
      firstName
      lastName
      gender
      birth
      weight
      height
      isVerified
      addresses {
        address
        subDistrict
        district
        province
        postcode
        isDefault
      }
      attaches {
        bucket
        key
        region
        identityId
        level
      }
      creditCards {
        token
        number
        expirationMonth
        expirationYear
        name
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: AWSEmail
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        notificationEmail
        phone
        idCardNumber
        firstName
        lastName
        gender
        birth
        weight
        height
        isVerified
        addresses {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        attaches {
          bucket
          key
          region
          identityId
          level
        }
        creditCards {
          token
          number
          expirationMonth
          expirationYear
          name
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getStaff = /* GraphQL */ `
  query GetStaff($email: AWSEmail!) {
    getStaff(email: $email) {
      email
      image {
        bucket
        key
        region
        identityId
        level
      }
      jobPosition
      info
      notificationEmail
      phone
      firstName
      lastName
      roleID
      role {
        id
        name
        description
        createdAt
        updatedAt
        roleModel {
          article {
            create
            delete
            read
            update
          }
          category {
            create
            delete
            read
            update
          }
          conversation {
            create
            delete
            read
            update
          }
          order {
            create
            delete
            read
            update
          }
          product {
            create
            delete
            read
            update
          }
          promotion {
            create
            delete
            read
            update
          }
          questionnaire {
            create
            delete
            read
            update
          }
          setting {
            create
            delete
            read
            update
          }
          staff {
            create
            delete
            read
            update
          }
          user {
            create
            delete
            read
            update
          }
        }
      }
      type
      distributorID
      distributor {
        id
        name
        image {
          bucket
          key
          region
          identityId
          level
        }
        description
        email
        phone
        address {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        staffs {
          nextToken
        }
        createdAt
        updatedAt
      }
      categoryIDs
      licenseNumber
      clinicName
      clinicAddress
      clinicAddress2
      signature {
        bucket
        key
        region
        identityId
        level
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStaff = /* GraphQL */ `
  query ListStaff(
    $email: AWSEmail
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStaff(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        image {
          bucket
          key
          region
          identityId
          level
        }
        jobPosition
        info
        notificationEmail
        phone
        firstName
        lastName
        roleID
        role {
          id
          name
          description
          createdAt
          updatedAt
        }
        type
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        categoryIDs
        licenseNumber
        clinicName
      clinicAddress
      clinicAddress2
        signature {
          bucket
          key
          region
          identityId
          level
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const staffsByRoleID = /* GraphQL */ `
  query StaffsByRoleID(
    $roleID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByRoleID(
      roleID: $roleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        image {
          bucket
          key
          region
          identityId
          level
        }
        jobPosition
        info
        notificationEmail
        phone
        firstName
        lastName
        roleID
        role {
          id
          name
          description
          createdAt
          updatedAt
        }
        type
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        categoryIDs
        licenseNumber
        clinicName
      clinicAddress
      clinicAddress2
        signature {
          bucket
          key
          region
          identityId
          level
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const staffsByDistributorID = /* GraphQL */ `
  query StaffsByDistributorID(
    $distributorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByDistributorID(
      distributorID: $distributorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        image {
          bucket
          key
          region
          identityId
          level
        }
        jobPosition
        info
        notificationEmail
        phone
        firstName
        lastName
        roleID
        role {
          id
          name
          description
          createdAt
          updatedAt
        }
        type
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        categoryIDs
        licenseNumber
        clinicName
      clinicAddress
      clinicAddress2
        signature {
          bucket
          key
          region
          identityId
          level
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDistributor = /* GraphQL */ `
  query GetDistributor($id: ID!) {
    getDistributor(id: $id) {
      id
      name
      image {
        bucket
        key
        region
        identityId
        level
      }
      description
      email
      phone
      address {
        address
        subDistrict
        district
        province
        postcode
        isDefault
      }
      staffs {
        items {
          email
          jobPosition
          info
          notificationEmail
          phone
          firstName
          lastName
          roleID
          type
          distributorID
          categoryIDs
          licenseNumber
          clinicName
      clinicAddress
      clinicAddress2
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDistributors = /* GraphQL */ `
  query ListDistributors(
    $filter: ModelDistributorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image {
          bucket
          key
          region
          identityId
          level
        }
        description
        email
        phone
        address {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        staffs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      nameEN
      descriptionEN
      safetyGuideEN
      unitEN
      subUnitEN
      description
      categoryID
      category {
        id
        name
        description
        nameEN
        descriptionEN
        questionnaireID
        questionnaire {
          id
          type
          name
          description
          createdAt
          updatedAt
        }
        followupQuestionnaireID
        followupQuestionnaire {
          id
          type
          name
          description
          createdAt
          updatedAt
        }
        products {
          nextToken
        }
        consultPrice
        consultDiscountPrice
        filter
        createdAt
        updatedAt
      }
      discountOptions {
        items {
          id
          productID
          month
          discountType
          discount
          createdAt
          updatedAt
        }
        nextToken
      }
      unit
      subUnit
      unitRatio
      maxAmount
      sku
      images {
        bucket
        key
        region
        identityId
        level
      }
      manufacturer
      distributorID
      distributor {
        id
        name
        image {
          bucket
          key
          region
          identityId
          level
        }
        description
        email
        phone
        address {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        staffs {
          nextToken
        }
        createdAt
        updatedAt
      }
      safetyGuide
      enable
      isRecommend
      price
      code
      filter
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nameEN
        descriptionEN
        safetyGuideEN
        unitEN
        subUnitEN
        description
        categoryID
        category {
          waitOrderToComplete
          id
          name
          description
          nameEN
          descriptionEN
          questionnaireID
          followupQuestionnaireID
          consultPrice
          consultDiscountPrice
          filter
          createdAt
          updatedAt
        }
        discountOptions {
          items {
            id
            productID
            month
            discountType
            discount
            createdAt
            updatedAt
          }
          nextToken
        }
        unit
        subUnit
        unitRatio
        maxAmount
        sku
        images {
          bucket
          key
          region
          identityId
          level
        }
        manufacturer
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        safetyGuide
        enable
        isRecommend
        price
        code
        filter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByCategoryID = /* GraphQL */ `
  query ProductsByCategoryID(
    $categoryID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByCategoryID(
      categoryID: $categoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameEN
        descriptionEN
        safetyGuideEN
        unitEN
        subUnitEN
        description
        categoryID
        category {
          waitOrderToComplete
          id
          name
          description
          nameEN
          descriptionEN
          questionnaireID
          followupQuestionnaireID
          consultPrice
          consultDiscountPrice
          filter
          createdAt
          updatedAt
        }
        discountOptions {
          items {
            id
            month
            discount
            discountType
          }
        }
        unit
        subUnit
        unitRatio
        maxAmount
        sku
        images {
          bucket
          key
          region
          identityId
          level
        }
        manufacturer
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        safetyGuide
        enable
        isRecommend
        price
        code
        filter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByDistributorID = /* GraphQL */ `
  query ProductsByDistributorID(
    $distributorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByDistributorID(
      distributorID: $distributorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        categoryID
        category {
          waitOrderToComplete
          id
          name
          description
          questionnaireID
          followupQuestionnaireID
          consultPrice
          consultDiscountPrice
          filter
          createdAt
          updatedAt
        }
        discountOptions {
          nextToken
        }
        unit
        subUnit
        unitRatio
        maxAmount
        sku
        images {
          bucket
          key
          region
          identityId
          level
        }
        manufacturer
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        safetyGuide
        enable
        isRecommend
        price
        code
        filter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: String!) {
    getOrder(id: $id) {
      id
      userID
      user {
        email
        notificationEmail
        phone
        idCardNumber
        firstName
        lastName
        gender
        birth
        weight
        height
        isVerified
        addresses {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        attaches {
          bucket
          key
          region
          identityId
          level
        }
        creditCards {
          token
          number
          expirationMonth
          expirationYear
          name
        }
        createdAt
        updatedAt
        owner
      }
      items {
        productID
        productDiscountID
        quantity
        subtotal
        totalPrice
        totalDiscount
      }
      relateOrder {
        id
        type
        status
      }
      relateOrderID
      status
      type
      categoryID
      category {
        id
        name
        description
        nameEN
        descriptionEN
        questionnaireID
        questionnaire {
          id
          type
          name
          description
          createdAt
          updatedAt
        }
        followupQuestionnaireID
        followupQuestionnaire {
          id
          type
          name
          description
          createdAt
          updatedAt
        }
        products {
          nextToken
        }
        consultPrice
        consultDiscountPrice
        filter
        createdAt
        updatedAt
      }
      answerID
      answer {
        id
        userID
        user {
          email
          notificationEmail
          phone
          idCardNumber
          firstName
          lastName
          gender
          birth
          weight
          height
          isVerified
          createdAt
          updatedAt
          owner
        }
        questionnaireID
        questionIDs
        answer
        status
        createdAt
        emailLogs
        updatedAt
      }
      shippings {
        items {
          id
          orderID
          userID
          status
          createdAt
          note
          ref
          updatedAt
        }
        nextToken
      }
      shippingAddress {
        address
        subDistrict
        district
        province
        postcode
        isDefault
      }
      note
      payment {
        method
        totalPrice
        promotionDiscount
        totalDiscount
        consultPrice
        consultPriceDiscount
        shippingFee
        vat
        ref
        qrcode
        creditCard {
          token
          number
          expirationMonth
          expirationYear
          name
        }
        note
      }
      updatedAt
      expiredAt
      doctorID
      doctor {
        email
        image {
          bucket
          key
          region
          identityId
          level
        }
        jobPosition
        info
        notificationEmail
        phone
        firstName
        lastName
        type
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        categoryIDs
        licenseNumber
        clinicName
      clinicAddress
      clinicAddress2
        signature {
          bucket
          key
          region
          identityId
          level
        }
        createdAt
        updatedAt
      }
      distributorStaffID
      distributorStaff {
        email
        image {
          bucket
          key
          region
          identityId
          level
        }
        jobPosition
        info
        notificationEmail
        phone
        firstName
        lastName
        type
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        categoryIDs
        licenseNumber
        clinicName
      clinicAddress
      clinicAddress2
        signature {
          bucket
          key
          region
          identityId
          level
        }
        createdAt
        updatedAt
      }
      distributorID
      distributor {
        id
        name
        image {
          bucket
          key
          region
          identityId
          level
        }
        description
        email
        phone
        address {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        staffs {
          nextToken
        }
        createdAt
        updatedAt
      }
      instruction
      diagnosis
      distributorNote
      promotionID
      promotion {
        id
        name
        description
        type
        discount
        oneTimeUse
        enable
        startDate
        endDate
        limit
        used
        createdAt
        updatedAt
      }
      conversation {
        id
        userID
        userRead
        staffRead
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isCheckout
      createdAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $id: String
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userID
        user {
          email
          notificationEmail
          phone
          idCardNumber
          firstName
          lastName
          gender
          birth
          weight
          height
          isVerified
          createdAt
          updatedAt
          owner
        }
        items {
          productID
          productDiscountID
          quantity
          subtotal
          totalPrice
          totalDiscount
        }
        status
        categoryID
        category {
          waitOrderToComplete
          id
          name
          description
          nameEN
          descriptionEN
          questionnaireID
          followupQuestionnaireID
          consultPrice
          consultDiscountPrice
          filter
          createdAt
          updatedAt
        }
        answerID
        answer {
          id
          userID
          questionnaireID
          questionIDs
          answer
          status
          createdAt
          emailLogs
          updatedAt
        }
        shippings {
          nextToken
        }
        shippingAddress {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        note
        payment {
          method
          totalPrice
          promotionDiscount
          totalDiscount
          consultPrice
          consultPriceDiscount
          shippingFee
          vat
          ref
          qrcode
          note
        }
        updatedAt
        expiredAt
        doctorID
        doctor {
          email
          jobPosition
          info
          notificationEmail
          phone
          firstName
          lastName
          roleID
          type
          distributorID
          categoryIDs
          licenseNumber
          clinicName
      clinicAddress
      clinicAddress2
          createdAt
          updatedAt
        }
        distributorStaffID
        distributorStaff {
          email
          jobPosition
          info
          notificationEmail
          phone
          firstName
          lastName
          roleID
          type
          distributorID
          categoryIDs
          licenseNumber
          clinicName
      clinicAddress
      clinicAddress2
          createdAt
          updatedAt
        }
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        instruction
        diagnosis
        distributorNote
        promotionID
        promotion {
          id
          name
          description
          type
          discount
          oneTimeUse
          enable
          startDate
          endDate
          limit
          used
          createdAt
          updatedAt
        }
        conversation {
          id
          userID
          userRead
          staffRead
          createdAt
          updatedAt
        }
        isCheckout
        createdAt
      }
      nextToken
    }
  }
`;

export const searchAnswers = /* GraphQL */ `
  query SearchAnswers(
    $filter: SearchableAnswerFilterInput
    $sort: [SearchableAnswerSortInput]
    $limit: Int
    $from: Int
  ) {
    searchAnswers(filter: $filter, sort: $sort, limit: $limit, from: $from) {
      items {
        answer
        emailLogs
        createdAt
        id
        questionIDs
        questionnaireID
        status
        updatedAt
        userID
        user {
          email
          firstName
          gender
          lastName
          height
          idCardNumber
          isVerified
          notificationEmail
          owner
          phone
          updatedAt
          weight
          birth
          createdAt
        }
      }
      total
    }
  }
`;

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        addresses {
          address
          district
          isDefault
          postcode
          province
          subDistrict
        }
        attaches {
          bucket
          identityId
          key
          level
          region
        }
        birth
        createdAt
        email
        firstName
        gender
        height
        idCardNumber
        isVerified
        lastName
        phone
        updatedAt
        weight
      }
      total
    }
  }
`;

// export const searchOrders = /* Prod GraphQL */ `
//   query SearchOrders(
//     $filter: SearchableOrderFilterInput
//     $sort: SearchableOrderSortInput
//     $limit: Int
//     $nextToken: String
//     $from: Int
//   ) {
//     searchOrders(
//       filter: $filter
//       sort: $sort
//       limit: $limit
//       nextToken: $nextToken
//       from: $from
//     ) {
//       items {
//         id
//         userID
//         user {
//           email
//           notificationEmail
//           phone
//           idCardNumber
//           firstName
//           lastName
//           gender
//           birth
//           weight
//           height
//           isVerified
//           createdAt
//           updatedAt
//           owner
//         }
//         items {
//           productID
//           productDiscountID
//           quantity
//           subtotal
//           totalPrice
//           totalDiscount
//         }
//         status
//         categoryID
//         category {
//           id
//           name
//           description
//           questionnaireID
//           followupQuestionnaireID
//           consultPrice
//           consultDiscountPrice
//           filter
//           createdAt
//           updatedAt
//         }
//         answerID
//         answer {
//           id
//           userID
//           questionnaireID
//           questionIDs
//           answer
//           status
//           createdAt
//           emailLogs
//           updatedAt
//         }
//         shippings {
//           items {
//             id
//             note
//             createdAt
//             orderID
//             ref
//             status
//             updatedAt
//             userID
//           }
//           nextToken
//         }
//         shippingAddress {
//           address
//           subDistrict
//           district
//           province
//           postcode
//           isDefault
//         }
//         note
//         payment {
//           method
//           totalPrice
//           promotionDiscount
//           totalDiscount
//           consultPrice
//           consultPriceDiscount
//           shippingFee
//           vat
//           ref
//           qrcode
//           note
//         }
//         updatedAt
//         expiredAt
//         doctorID
//         doctor {
//           email
//           jobPosition
//           info
//           notificationEmail
//           phone
//           firstName
//           lastName
//           roleID
//           type
//           distributorID
//           categoryIDs
//           licenseNumber
//           createdAt
//           updatedAt
//         }
//         distributorStaffID
//         distributorStaff {
//           email
//           jobPosition
//           info
//           notificationEmail
//           phone
//           firstName
//           lastName
//           roleID
//           type
//           distributorID
//           categoryIDs
//           licenseNumber
//           createdAt
//           updatedAt
//         }
//         distributorID
//         distributor {
//           id
//           name
//           description
//           email
//           phone
//           createdAt
//           updatedAt
//         }
//         instruction
//         diagnosis
//         distributorNote
//         promotionID
//         promotion {
//           id
//           name
//           description
//           type
//           discount
//           oneTimeUse
//           enable
//           startDate
//           endDate
//           limit
//           used
//           createdAt
//           updatedAt
//         }
//         conversation {
//           id
//           userID
//           userRead
//           staffRead
//           createdAt
//           updatedAt
//           messages {
//             items {
//               userID
//               updatedAt
//               message
//               id
//               createdAt
//               conversationID
//               attaches {
//                 bucket
//                 identityId
//                 key
//                 level
//                 region
//               }
//             }
//           }
//         }
//         isCheckout
//         createdAt
//       }
//       nextToken
//       total
//     }
//   }
// `;

export const searchOrders = /* Dev GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: [SearchableOrderSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrderAggregationInput]
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userID
        user {
          email
          notificationEmail
          phone
          idCardNumber
          firstName
          lastName
          gender
          birth
          weight
          height
          isVerified
          createdAt
          updatedAt
          owner
        }
        items {
          productID
          productDiscountID
          quantity
          subtotal
          totalPrice
          totalDiscount
        }
        relateOrder {
          id
          type
          status
        }
        relateOrderID
        status
        paidAt
        categoryID
        category {
          waitOrderToComplete
          id
          name
          description
          nameEN
          descriptionEN
          questionnaireID
          followupQuestionnaireID
          consultPrice
          consultDiscountPrice
          filter
          createdAt
          updatedAt
        }
        answerID
        answer {
          id
          userID
          questionnaireID
          questionIDs
          answer
          status
          createdAt
          emailLogs
          updatedAt
        }
        type
        shippings {
          items {
            id
            note
            createdAt
            orderID
            ref
            status
            updatedAt
            userID
          }
          nextToken
        }
        shippingAddress {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        note
        payment {
          method
          totalPrice
          promotionDiscount
          totalDiscount
          consultPrice
          consultPriceDiscount
          shippingFee
          vat
          ref
          qrcode
          note
          creditCard {
            expirationMonth
            expirationYear
            name
            number
            token
          }
          slip {
            bucket
            identityId
            key
            level
            region
          }
        }
        updatedAt
        expiredAt
        doctorID
        doctor {
          email
          jobPosition
          info
          notificationEmail
          phone
          firstName
          lastName
          roleID
          type
          distributorID
          categoryIDs
          licenseNumber
          clinicName
      clinicAddress
      clinicAddress2
          createdAt
          updatedAt
          image {
            key
          }
        }
        distributorStaffID
        distributorStaff {
          email
          jobPosition
          info
          notificationEmail
          phone
          firstName
          lastName
          roleID
          type
          distributorID
          categoryIDs
          licenseNumber
          clinicName
      clinicAddress
      clinicAddress2
          createdAt
          updatedAt
        }
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        instruction
        diagnosis
        distributorNote
        promotionID
        promotion {
          id
          name
          description
          type
          discount
          oneTimeUse
          enable
          startDate
          endDate
          limit
          used
          createdAt
          updatedAt
        }
        conversation {
          id
          userID
          userRead
          staffRead
          createdAt
          updatedAt
          messages {
            items {
              userID
              updatedAt
              message
              id
              createdAt
              conversationID
              attaches {
                bucket
                identityId
                key
                level
                region
              }
            }
          }
        }
        isCheckout
        createdAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const getConversation = /* GraphQL */ `
  query GetConversation($id: String!) {
    getConversation(id: $id) {
      id
      userID
      userRead
      staffRead
      messages {
        items {
          id
          userID
          message
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      url
      name
      title
      tags
      shortDescription
      description
      subDescription
      descriptionEN
      shortDescriptionEN
      subDescriptionEN
      titleEN
      image {
        bucket
        key
        region
        identityId
        level
      }
      relatedBlogs
      categoryIDs
      blogCategoryID
      blogCategory {
        id
        name
        description
        blogs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      authorName
      reviewedBy
      seoTitle
      seoDescription
      isFeature
      status
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        name
        title
        tags
        shortDescription
        description
        subDescription
        descriptionEN
        shortDescriptionEN
        subDescriptionEN
        titleEN
        image {
          bucket
          key
          region
          identityId
          level
        }
        relatedBlogs
        categoryIDs
        blogCategoryID
        blogCategory {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        authorName
        reviewedBy
        seoTitle
        seoDescription
        isFeature
        status
      }
      nextToken
    }
  }
`;
export const blogsByUrl = /* GraphQL */ `
  query BlogsByUrl(
    $url: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogsByUrl(
      url: $url
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        name
        title
        tags
        shortDescription
        description
        subDescription
        descriptionEN
        shortDescriptionEN
        subDescriptionEN
        titleEN
        image {
          bucket
          key
          region
          identityId
          level
        }
        relatedBlogs
        categoryIDs
        blogCategoryID
        blogCategory {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        authorName
        reviewedBy
        seoTitle
        seoDescription
        isFeature
        status
      }
      nextToken
    }
  }
`;
export const blogsByCategoryID = /* GraphQL */ `
  query BlogsByCategoryID(
    $blogCategoryID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogsByCategoryID(
      blogCategoryID: $blogCategoryID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        name
        title
        tags
        shortDescription
        description
        subDescription
        descriptionEN
        shortDescriptionEN
        subDescriptionEN
        titleEN
        image {
          bucket
          key
          region
          identityId
          level
        }
        relatedBlogs
        categoryIDs
        blogCategoryID
        blogCategory {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        authorName
        reviewedBy
        seoTitle
        seoDescription
        isFeature
        status
      }
      nextToken
    }
  }
`;
export const blogsByStatus = /* GraphQL */ `
  query BlogsByStatus(
    $status: BlogStatus!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogsByStatus(
      status: $status
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        name
        title
        tags
        shortDescription
        description
        subDescription
        descriptionEN
        shortDescriptionEN
        subDescriptionEN
        titleEN
        image {
          bucket
          key
          region
          identityId
          level
        }
        relatedBlogs
        categoryIDs
        blogCategoryID
        blogCategory {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        authorName
        reviewedBy
        seoTitle
        seoDescription
        isFeature
        status
      }
      nextToken
    }
  }
`;
export const searchBlogs = /* GraphQL */ `
  query SearchBlogs(
    $filter: SearchableBlogFilterInput
    $sort: [SearchableBlogSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBlogAggregationInput]
  ) {
    searchBlogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        url
        name
        title
        tags
        shortDescription
        description
        subDescription
        descriptionEN
        shortDescriptionEN
        subDescriptionEN
        titleEN
        image {
          bucket
          key
          region
          identityId
          level
        }
        relatedBlogs
        categoryIDs
        blogCategoryID
        blogCategory {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        authorName
        reviewedBy
        seoTitle
        seoDescription
        isFeature
        status
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getBlogCategory = /* GraphQL */ `
  query GetBlogCategory($id: ID!) {
    getBlogCategory(id: $id) {
      id
      name
      description
      url
      blogs {
        items {
          id
          url
          name
          title
          tags
          shortDescription
          description
          subDescription
          relatedBlogs
          categoryIDs
          blogCategoryID
          createdAt
          updatedAt
          authorName
          reviewedBy
          seoTitle
          seoDescription
          isFeature
          status
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBlogCategories = /* GraphQL */ `
  query ListBlogCategories(
    $filter: ModelBlogCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        url
        blogs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPromotion = /* GraphQL */ `
  query GetPromotion($id: String!) {
    getPromotion(id: $id) {
      id
      name
      description
      type
      discount
      oneTimeUse
      enable
      startDate
      endDate
      limit
      used
      createdAt
      updatedAt
      productIDs
    }
  }
`;
export const listPromotions = /* GraphQL */ `
  query ListPromotions(
    $id: String
    $filter: ModelPromotionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPromotions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        type
        discount
        oneTimeUse
        enable
        startDate
        endDate
        limit
        used
        createdAt
        updatedAt
        productIDs
      }
      nextToken
    }
  }
`;

export const searchPromotions = /* GraphQL */ `
  query SearchPromotions(
    $filter: SearchablePromotionFilterInput
    $from: Int
    $limit: Int
    $sort: [SearchablePromotionSortInput]
  ) {
    searchPromotions(filter: $filter, from: $from, limit: $limit, sort: $sort) {
      items {
        id
        name
        description
        type
        discount
        oneTimeUse
        enable
        startDate
        endDate
        limit
        used
        createdAt
        updatedAt
        productIDs
      }
      total
    }
  }
`;

export const getShipping = /* GraphQL */ `
  query GetShipping($id: ID!) {
    getShipping(id: $id) {
      id
      orderID
      order {
        id
        userID
        user {
          email
          notificationEmail
          phone
          idCardNumber
          firstName
          lastName
          gender
          birth
          weight
          height
          isVerified
          createdAt
          updatedAt
          owner
        }
        items {
          productID
          productDiscountID
          quantity
          subtotal
          totalPrice
          totalDiscount
        }
        status
        categoryID
        category {
          waitOrderToComplete
          id
          name
          description
          nameEN
          descriptionEN
          questionnaireID
          followupQuestionnaireID
          consultPrice
          consultDiscountPrice
          filter
          createdAt
          updatedAt
        }
        answerID
        answer {
          id
          userID
          questionnaireID
          questionIDs
          answer
          status
          createdAt
          emailLogs
          updatedAt
        }
        shippings {
          nextToken
        }
        shippingAddress {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        note
        payment {
          method
          totalPrice
          promotionDiscount
          totalDiscount
          consultPrice
          consultPriceDiscount
          shippingFee
          vat
          ref
          qrcode
          note
        }
        updatedAt
        expiredAt
        doctorID
        doctor {
          email
          jobPosition
          info
          notificationEmail
          phone
          firstName
          lastName
          roleID
          type
          distributorID
          categoryIDs
          licenseNumber
          clinicName
      clinicAddress
      clinicAddress2
          createdAt
          updatedAt
        }
        distributorStaffID
        distributorStaff {
          email
          jobPosition
          info
          notificationEmail
          phone
          firstName
          lastName
          roleID
          type
          distributorID
          categoryIDs
          licenseNumber
          clinicName
      clinicAddress
      clinicAddress2
          createdAt
          updatedAt
        }
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        instruction
        diagnosis
        distributorNote
        promotionID
        promotion {
          id
          name
          description
          type
          discount
          oneTimeUse
          enable
          startDate
          endDate
          limit
          used
          createdAt
          updatedAt
        }
        conversation {
          id
          userID
          userRead
          staffRead
          createdAt
          updatedAt
        }
        isCheckout
        createdAt
      }
      userID
      status
      createdAt
      note
      address {
        address
        subDistrict
        district
        province
        postcode
        isDefault
      }
      ref
      updatedAt
    }
  }
`;
export const listShippings = /* GraphQL */ `
  query ListShippings(
    $filter: ModelShippingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        order {
          id
          userID
          status
          categoryID
          answerID
          note
          updatedAt
          expiredAt
          doctorID
          distributorStaffID
          distributorID
          instruction
          diagnosis
          distributorNote
          promotionID
          isCheckout
          createdAt
        }
        userID
        status
        createdAt
        note
        address {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        ref
        updatedAt
      }
      nextToken
    }
  }
`;
export const shippingsByStatus = /* GraphQL */ `
  query ShippingsByStatus(
    $status: ShippingStatus!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShippingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shippingsByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        order {
          id
          userID
          status
          categoryID
          answerID
          note
          updatedAt
          expiredAt
          doctorID
          distributorStaffID
          distributorID
          instruction
          diagnosis
          distributorNote
          promotionID
          isCheckout
          createdAt
        }
        userID
        status
        createdAt
        note
        address {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        ref
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductDiscount = /* GraphQL */ `
  query GetProductDiscount($id: ID!) {
    getProductDiscount(id: $id) {
      id
      productID
      product {
        id
        name
        description
        categoryID
        category {
          waitOrderToComplete
          id
          name
          description
          nameEN
          descriptionEN
          questionnaireID
          followupQuestionnaireID
          consultPrice
          consultDiscountPrice
          filter
          createdAt
          updatedAt
        }
        discountOptions {
          nextToken
        }
        unit
        subUnit
        unitRatio
        maxAmount
        sku
        images {
          bucket
          key
          region
          identityId
          level
        }
        manufacturer
        distributorID
        distributor {
          id
          name
          description
          email
          phone
          createdAt
          updatedAt
        }
        safetyGuide
        enable
        isRecommend
        price
        code
        filter
        createdAt
        updatedAt
      }
      month
      discountType
      discount
      createdAt
      updatedAt
    }
  }
`;
export const listProductDiscounts = /* GraphQL */ `
  query ListProductDiscounts(
    $filter: ModelProductDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductDiscounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productID
        product {
          id
          name
          description
          categoryID
          unit
          subUnit
          unitRatio
          maxAmount
          sku
          manufacturer
          distributorID
          safetyGuide
          enable
          isRecommend
          price
          code
          filter
          createdAt
          updatedAt
        }
        month
        discountType
        discount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const discountsByProductID = /* GraphQL */ `
  query DiscountsByProductID(
    $productID: ID!
    $month: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountsByProductID(
      productID: $productID
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productID
        product {
          id
          name
          description
          categoryID
          unit
          subUnit
          unitRatio
          maxAmount
          sku
          manufacturer
          distributorID
          safetyGuide
          enable
          isRecommend
          price
          code
          filter
          createdAt
          updatedAt
        }
        month
        discountType
        discount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      description
      questionnaireID
      questionnaire {
        id
        type
        name
        description
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      waitOrderToComplete
      followupQuestionnaireID
      followupQuestionnaire {
        id
        type
        name
        description
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      products {
        items {
          id
          name
          nameEN
          descriptionEN
          safetyGuideEN
          unitEN
          subUnitEN
          description
          categoryID
          unit
          subUnit
          unitRatio
          maxAmount
          sku
          manufacturer
          distributorID
          safetyGuide
          enable
          isRecommend
          price
          code
          filter
          createdAt
          updatedAt
        }
        nextToken
      }
      consultPrice
      consultDiscountPrice
      filter
      createdAt
      updatedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        nameEN
        descriptionEN
        questionnaireID
        questionnaire {
          id
          type
          name
          description
          createdAt
          updatedAt
        }
        waitOrderToComplete
        followupQuestionnaireID
        followupQuestionnaire {
          id
          type
          name
          description
          createdAt
          updatedAt
        }
        products {
          nextToken
        }
        consultPrice
        consultDiscountPrice
        filter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionnaire = /* GraphQL */ `
  query GetQuestionnaire($id: ID!) {
    getQuestionnaire(id: $id) {
      id
      type
      name
      description
      questions {
        items {
          id
          questionnaireID
          name
          question
          questionEN
          questionType
          nextQuestionID
          backQuestionID
          answer
          isCheckpoint
          sendDataToNextQuestion
          requiredLogin
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionnaires = /* GraphQL */ `
  query ListQuestionnaires(
    $filter: ModelQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      questionnaireID
      name
      question
      questionType
      nextQuestionID
      nextQuestion {
        id
        questionnaireID
        name
        question
        questionEN
        questionType
        nextQuestionID
        nextQuestion {
          id
          questionnaireID
          name
          question
          questionEN
          questionType
          nextQuestionID
          backQuestionID
          answer
          isCheckpoint
          sendDataToNextQuestion
          requiredLogin
          createdAt
          updatedAt
        }
        backQuestionID
        backQuestion {
          id
          questionnaireID
          name
          question
          questionEN
          questionType
          nextQuestionID
          backQuestionID
          answer
          isCheckpoint
          sendDataToNextQuestion
          requiredLogin
          createdAt
          updatedAt
        }
        answer
        isCheckpoint
        sendDataToNextQuestion
        requiredLogin
        createdAt
        updatedAt
      }
      backQuestionID
      backQuestion {
        id
        questionnaireID
        name
        question
        questionEN
        questionType
        nextQuestionID
        nextQuestion {
          id
          questionnaireID
          name
          question
          questionEN
          questionType
          nextQuestionID
          backQuestionID
          answer
          isCheckpoint
          sendDataToNextQuestion
          requiredLogin
          createdAt
          updatedAt
        }
        backQuestionID
        backQuestion {
          id
          questionnaireID
          name
          question
          questionEN
          questionType
          nextQuestionID
          backQuestionID
          answer
          isCheckpoint
          sendDataToNextQuestion
          requiredLogin
          createdAt
          updatedAt
        }
        answer
        isCheckpoint
        sendDataToNextQuestion
        requiredLogin
        createdAt
        updatedAt
      }
      answer
      isCheckpoint
      sendDataToNextQuestion
      requiredLogin
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionnaireID
        name
        question
        questionEN
        questionType
        nextQuestionID
        nextQuestion {
          id
          questionnaireID
          name
          question
          questionEN
          questionType
          nextQuestionID
          backQuestionID
          answer
          isCheckpoint
          sendDataToNextQuestion
          requiredLogin
          createdAt
          updatedAt
        }
        backQuestionID
        backQuestion {
          id
          questionnaireID
          name
          question
          questionEN
          questionType
          nextQuestionID
          backQuestionID
          answer
          isCheckpoint
          sendDataToNextQuestion
          requiredLogin
          createdAt
          updatedAt
        }
        answer
        isCheckpoint
        sendDataToNextQuestion
        requiredLogin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByQuestionnaireID = /* GraphQL */ `
  query QuestionsByQuestionnaireID(
    $questionnaireID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByQuestionnaireID(
      questionnaireID: $questionnaireID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionnaireID
        name
        question
        questionEN
        questionType
        nextQuestionID
        nextQuestion {
          id
          questionnaireID
          name
          question
          questionEN
          questionType
          nextQuestionID
          backQuestionID
          answer
          isCheckpoint
          sendDataToNextQuestion
          requiredLogin
          createdAt
          updatedAt
        }
        backQuestionID
        backQuestion {
          id
          questionnaireID
          name
          question
          questionEN
          questionType
          nextQuestionID
          backQuestionID
          answer
          isCheckpoint
          sendDataToNextQuestion
          requiredLogin
          createdAt
          updatedAt
        }
        answer
        isCheckpoint
        sendDataToNextQuestion
        requiredLogin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      userID
      user {
        email
        notificationEmail
        phone
        idCardNumber
        firstName
        lastName
        gender
        birth
        weight
        height
        isVerified
        addresses {
          address
          subDistrict
          district
          province
          postcode
          isDefault
        }
        attaches {
          bucket
          key
          region
          identityId
          level
        }
        creditCards {
          token
          number
          expirationMonth
          expirationYear
          name
        }
        createdAt
        updatedAt
        owner
      }
      questionnaireID
      questionIDs
      answer
      status
      createdAt
      emailLogs
      updatedAt
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          email
          notificationEmail
          phone
          idCardNumber
          firstName
          lastName
          gender
          birth
          weight
          height
          isVerified
          createdAt
          updatedAt
          owner
        }
        questionnaireID
        questionIDs
        answer
        status
        createdAt
        emailLogs
        updatedAt
      }
      nextToken
    }
  }
`;
// export const answersByEmail = /* GraphQL */ `
//   query MyQuery {
//     answersByEmail(userID: "miss.sangium2@gmail.com") {
//       items {
//         id
//       }
//     }
//   }
// `;
export const answersByEmail = /* GraphQL */ `
  query AnswersByEmail(
    $userID: AWSEmail!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByEmail(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          email
          notificationEmail
          phone
          idCardNumber
          firstName
          lastName
          gender
          birth
          weight
          height
          isVerified
          createdAt
          updatedAt
          owner
        }
        questionnaireID
        questionIDs
        answer
        status
        createdAt
        emailLogs
        updatedAt
      }
      nextToken
    }
  }
`;
export const answersByStatus = /* GraphQL */ `
  query AnswersByStatus(
    $status: AnswerStatus!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          email
          notificationEmail
          phone
          idCardNumber
          firstName
          lastName
          gender
          birth
          weight
          height
          isVerified
          createdAt
          updatedAt
          owner
        }
        questionnaireID
        questionIDs
        answer
        status
        createdAt
        emailLogs
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      description
      roleModel {
        order {
          read
          create
          update
          delete
        }
        user {
          read
          create
          update
          delete
        }
        staff {
          read
          create
          update
          delete
        }
        category {
          read
          create
          update
          delete
        }
        product {
          read
          create
          update
          delete
        }
        questionnaire {
          read
          create
          update
          delete
        }
        article {
          read
          create
          update
          delete
        }
        setting {
          read
          create
          update
          delete
        }
        promotion {
          read
          create
          update
          delete
        }
        conversation {
          read
          create
          update
          delete
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt

        roleModel {
          order {
            read
            create
            update
            delete
          }
          user {
            read
            create
            update
            delete
          }
          staff {
            read
            create
            update
            delete
          }
          category {
            read
            create
            update
            delete
          }
          product {
            read
            create
            update
            delete
          }
          questionnaire {
            read
            create
            update
            delete
          }
          article {
            read
            create
            update
            delete
          }
          setting {
            read
            create
            update
            delete
          }
          promotion {
            read
            create
            update
            delete
          }
          conversation {
            read
            create
            update
            delete
          }
        }
      }
      nextToken
    }
  }
`;
