import React, { useReducer, useEffect } from "react";

import get from "lodash/get";
import { I18n } from "aws-amplify";

import en from "./languages/en.json.js";
import th from "./languages/th.json.js";

const translations = { en, th };
const languageCodes = [];
Object.keys(translations).forEach((element) => {
  languageCodes.push(translations[element]["countryCode"]);
});

// This function will be used to create `translate` function for the context
I18n.putVocabularies(translations);

const initialState = {
  I18n: I18n,
  translations: translations,
  langCode: "th",
};

export const I18nContext = React.createContext(initialState);

export const I18nContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setLanguage":
        return {
          I18n: I18n,
          translations: translations,
          langCode: action.payload,
        };
      default:
        return { ...initialState };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const host = window.location.host;
    const parts = host.split(".");
    const subdomain =
      parts.length === 1
        ? "th"
        : get(host.split("."), 0, "www") === "en"
        ? "en"
        : "th";
    dispatch({
      type: "setLanguage",
      payload: subdomain,
    });
  }, []);

  return (
    <I18nContext.Provider value={{ ...state, dispatch }}>
      {children}
    </I18nContext.Provider>
  );
};
