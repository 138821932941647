import Head from "next/head";

export default function Meta() {
  return (
    <Head>
      <title>hibro | Easy healthcare, man to man</title>
      <meta
        name="description"
        content={
          "บริการดูแลสุขภาพชายโดยผู้เชี่ยวชาญที่เข้าใจคุณ พบแพทย์ออนไลน์ ตรวจและรักษาอย่างต่อเนื่อง สะดวกสบายเป็นส่วนตัว ฟรีค่าจัดส่ง"
        }
        data-react-helmet="true"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="image"
        content="https://sayhibro5d98f21dfb374d31b878033166abec6d182612-prod.s3.ap-southeast-1.amazonaws.com/seoImage.jpg"
        data-react-helmet="true"
      />
      <meta
        property="og:type"
        name="type"
        content="article"
        data-react-helmet="true"
      />
      <meta
        property="og:url"
        name="url"
        content="https://sayhibro.com"
        data-react-helmet="true"
      />
      <meta
        property="og:title"
        name="title"
        content={"hibro | Easy healthcare, man to man"}
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        name="description"
        content={
          "บริการดูแลสุขภาพชายโดยผู้เชี่ยวชาญที่เข้าใจคุณ พบแพทย์ออนไลน์ ตรวจและรักษาอย่างต่อเนื่อง สะดวกสบายเป็นส่วนตัว ฟรีค่าจัดส่ง"
        }
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        name="image"
        content="https://sayhibro5d98f21dfb374d31b878033166abec6d182612-prod.s3.ap-southeast-1.amazonaws.com/seoImage.jpg"
        data-react-helmet="true"
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
        data-react-helmet="true"
      />
      <meta
        name="twitter:title"
        content={"hibro | Easy healthcare, man to man"}
        data-react-helmet="true"
      />
      <meta
        name="twitter:description"
        content={
          "บริการดูแลสุขภาพชายโดยผู้เชี่ยวชาญที่เข้าใจคุณ พบแพทย์ออนไลน์ ตรวจและรักษาอย่างต่อเนื่อง สะดวกสบายเป็นส่วนตัว ฟรีค่าจัดส่ง"
        }
        data-react-helmet="true"
      />
      <meta
        name="twitter:image"
        content="https://sayhibro5d98f21dfb374d31b878033166abec6d182612-prod.s3.ap-southeast-1.amazonaws.com/seoImage.jpg"
        data-react-helmet="true"
      />
      <meta
        property="fb:app_id"
        content="560604011641316"
        data-react-helmet="true"
      />
      <meta
        name="google-site-verification"
        content="aoDV0F-xPmfvnmFWVuVzWGRDB5C3rueQIrRoiRegRq8"
      />
      <meta
        name="facebook-domain-verification"
        content="dut2pbpprooie10vv5oooohpu8ziyj"
      />
      <link rel="canonical" href="https://www.sayhibro.com" />
      <link rel="icon" type="image/icon" href="/favicon.ico" />
      <link rel="shortcut icon" type="image/icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;500;600;700;800&display=swap"
        rel="stylesheet"
      ></link>
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff"></meta>
    </Head>
  );
}
