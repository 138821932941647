import Amplify, { Auth } from "aws-amplify";
import get from "lodash/get";

import moment from "moment";

import { API, graphqlOperation } from "aws-amplify";
import { getUser, getStaff } from "../../graphql/queries";
import { updateUser } from "../../graphql/mutations";

import { SET_USER, RESET_USER, SET_ERROR, SET_RETRY } from "./type";

const config = {
  aws_project_region: "ap-southeast-1",
  aws_appsync_graphqlEndpoint: process.env.NEXT_PUBLIC_ENV_GRAPHQL_ENDPOINT,
  aws_appsync_region: "ap-southeast-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: process.env.NEXT_PUBLIC_ENV_API_KEY,
  aws_cloud_logic_custom: [
    {
      name: "sayhibroRest",
      endpoint: process.env.NEXT_PUBLIC_ENV_LOGIC_CUSTOM_ENDPOINT,
      region: "ap-southeast-1",
    },
  ],
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_ENV_IDENTITY_POOL,
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: process.env.NEXT_PUBLIC_ENV_POOL_ID,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_ENV_WEB_CLIENT_ID,
  aws_cognito_username_attributes: ["EMAIL"],
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  aws_user_files_s3_bucket: process.env.NEXT_PUBLIC_ENV_S3_BUCKET,
  aws_user_files_s3_bucket_region: "ap-southeast-1",
};

export const setLineLiff = (user) => async (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: {
      ...user,
    },
  });
};

export const setRetry = () => async (dispatch) => {
  dispatch({
    type: SET_RETRY,
    payload: {},
  });
};

export const fetchAccount = () => async (dispatch) => {
  try {
    const auth = await Auth.currentSession();
    const payload = get(auth, "idToken.payload", false);
    const identityId = (await Auth.currentUserCredentials()).identityId;
    if (payload) {
      Amplify.configure({
        ...config,
        oauth: {
          domain: process.env.NEXT_PUBLIC_ENV_OAUTH_DOMAIN,
          scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: window.location.origin + "/",
          redirectSignOut: window.location.origin + "/",
          responseType: "code",
        },
        API: {
          graphql_headers: async () => {
            const session = await Auth.currentSession();
            if (session.getIdToken().getJwtToken()) {
              return {
                Authorization: session.getIdToken().getJwtToken(),
              };
            }
          },
        },
      });
      const result = await API.graphql(
        graphqlOperation(getUser, { email: get(payload, "email", "") })
      );
      const staffResult = await API.graphql(
        graphqlOperation(getStaff, { email: get(payload, "email", "") })
      );
      localStorage.setItem("loggedIn", true);
      localStorage.removeItem("retry");
      dispatch({
        type: SET_USER,
        payload: {
          ...get(result, "data.getUser", false),
          staff: get(staffResult, "data.getStaff", false),
          identityId,
          group: get(payload, "cognito:groups", []),
        },
      });
      if (localStorage.getItem("redirectUrl")) {
        const url = localStorage.getItem("redirectUrl");
        localStorage.removeItem("redirectUrl");
        if (
          get(result, "data.getUser.firstName", false) &&
          get(result, "data.getUser.lastName", false)
        ) {
          window.location.href = url;
        } else {
          window.location.href =
            "/register?redirect=" + encodeURIComponent(url);
        }
      } else if (
        window.location.pathname !== "/register" &&
        !get(result, "data.getUser.firstName", false)
      ) {
        window.location.href = "/register";
      }
    } else {
      localStorage.removeItem("loggedIn");
      dispatch({
        type: RESET_USER,
        payload: false,
      });
    }
  } catch (e) {
    localStorage.removeItem("loggedIn");
    console.log("fetch user error", e);
    dispatch({
      type: RESET_USER,
      payload: false,
    });
  }
};

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_ERROR,
        payload: false,
      });
      await Auth.signIn(email.trim(), password);
      await Auth.currentAuthenticatedUser().then((user) => {
        if (user) {
          dispatch(fetchAccount());
        }
      });
    } catch (e) {
      localStorage.removeItem("loggedIn");
      console.log("login failed", e);
      dispatch({
        type: SET_ERROR,
        payload: "ไม่พบข้อมูล",
      });
    }
  };

export const registerEmail = (input, callback) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ERROR,
      payload: false,
    });
    await Auth.signUp({
      username: input.email.trim(),
      password: input.password.trim(),
      attributes: {
        email: input.email.trim(),
      },
    });
    await Auth.signIn(input.email.trim(), input.password.trim());
    callback();
    // dispatch(fetchAccount());
  } catch (e) {
    localStorage.removeItem("loggedIn");
    dispatch({
      type: SET_ERROR,
      payload: "ลงทะเบียนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
    });
  }
};

export const logout = (username, isAdmin) => async (dispatch) => {
  try {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("retry");
    await Auth.signOut();
    dispatch({
      type: RESET_USER,
      payload: false,
    });
    window.location.href = "/";
  } catch (e) {
    console.log("logout failed", e);
  }
};
