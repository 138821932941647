import "../styles/globals.css";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import store from "../src/redux/store";
import { Provider } from "react-redux";
import { fetchAccount } from "../src/redux/action/auth";
import Amplify, { Auth } from "aws-amplify";
import { I18nContextProvider } from "../components/I18N";
import Meta from "../components/includes/MainMeta";
import get from "lodash/get";
const config = {
	aws_project_region: "ap-southeast-1",
	aws_appsync_graphqlEndpoint: process.env.NEXT_PUBLIC_ENV_GRAPHQL_ENDPOINT,
	aws_appsync_region: "ap-southeast-1",
	aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
	aws_appsync_apiKey: process.env.NEXT_PUBLIC_ENV_API_KEY,
	aws_cloud_logic_custom: [
		{
			name: "sayhibroRest",
			endpoint: process.env.NEXT_PUBLIC_ENV_LOGIC_CUSTOM_ENDPOINT,
			region: "ap-southeast-1",
		},
	],
	aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_ENV_IDENTITY_POOL,
	aws_cognito_region: "ap-southeast-1",
	aws_user_pools_id: process.env.NEXT_PUBLIC_ENV_POOL_ID,
	aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_ENV_WEB_CLIENT_ID,
	aws_cognito_username_attributes: ["EMAIL"],
	federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
	aws_user_files_s3_bucket: process.env.NEXT_PUBLIC_ENV_S3_BUCKET,
	aws_user_files_s3_bucket_region: "ap-southeast-1",
};

const App = ({ Component, pageProps }) => {
	const router = useRouter();
	const [loaded, setLoaded] = useState(false);
	const initial = async () => {
		console.log("log", router.query, get(router.query, "liff.state", false));
		const pathname = get(router.query, "liff.state", false)
			? `${get(router.query, "liff.state", false)}`
			: null;
		const liff = (await import("@line/liff")).default;
		const loggedIn = localStorage.getItem("loggedIn");
		const retry = localStorage.getItem("retry")
			? parseInt(localStorage.getItem("retry"))
			: 0;
		if (!loggedIn && retry < 2 && liff.isInClient()) {
			const liffid = "1657094253-kM97GGGe";
			await liff.init({ liffId: `${liffid}` }).catch((err) => {
				throw err;
			});

			if (liff.isLoggedIn()) {
				try {
					const session = await Auth.currentSession();
					if (session) {
						await store.dispatch(fetchAccount());
						setLoaded(true);
						if (pathname) router.push(pathname);
					}
				} catch (e) {
					try {
						Auth.federatedSignIn({ provider: "Line" })
							.then(async (user) => {
								if (!user) {
									localStorage.setItem("retry", retry + 1);
								} else {
									setLoaded(true);
								}
							})
							.catch((e) => {
								setLoaded(true);
							});
					} catch (e) {
						setLoaded(true);
					}
				}
			} else {
				liff.login();
			}
		} else {
			await store.dispatch(fetchAccount());
			setLoaded(true);
			if (pathname) router.push(pathname);
		}
	};

	useEffect(() => {
		if (!router.isReady) {
			return;
		}
		if (localStorage.getItem("loggedIn")) {
			Amplify.configure({
				...config,
				oauth: {
					domain: process.env.NEXT_PUBLIC_ENV_OAUTH_DOMAIN,
					scope: [
						"phone",
						"email",
						"openid",
						"profile",
						"aws.cognito.signin.user.admin",
					],
					redirectSignIn: window.location.origin + "/",
					redirectSignOut: window.location.origin + "/",
					responseType: "code",
				},
				API: {
					graphql_headers: async () => {
						const session = await Auth.currentSession();
						if (session.getIdToken().getJwtToken()) {
							return {
								Authorization: session.getIdToken().getJwtToken(),
							};
						}
					},
				},
			});
		} else {
			Amplify.configure({
				...config,
				oauth: {
					domain: process.env.NEXT_PUBLIC_ENV_OAUTH_DOMAIN,
					scope: [
						"phone",
						"email",
						"openid",
						"profile",
						"aws.cognito.signin.user.admin",
					],
					redirectSignIn: window.location.origin + "/",
					redirectSignOut: window.location.origin + "/",
					responseType: "code",
				},
			});
		}
		initial();
	}, [router]);

	if (typeof document !== "undefined") {
		require("tw-elements");
	}
	if (typeof document !== "undefined") {
		require("tw-elements");
	}

	if (!loaded) {
		return (
			<Provider store={store}>
				<I18nContextProvider>
					<Meta />
					<div className="w-screen h-screen row-center">
						{"Connecting to HIBRO app. Please wait a moment.."}
					</div>
				</I18nContextProvider>
			</Provider>
		);
	}

	return (
		<Provider store={store}>
			<I18nContextProvider>
				<Component {...pageProps} />
			</I18nContextProvider>
		</Provider>
	);
};

export default App;
