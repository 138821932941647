import { RESET_USER, SET_USER, SET_ERROR, SET_RETRY } from "../action/type";

const initialState = {
  loaded: false,
  user: false,
  error: false,
  retry: 0,
};
const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER:
      return {
        user: payload,
        error: false,
        loaded: true,
        retry: 0,
      };
    case SET_RETRY:
      return { ...state, retry: state.retry + 1 };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        loaded: true,
        retry: 0,
      };
    case RESET_USER:
      return { ...initialState, loaded: true };
    default:
      return state;
  }
};
export default authReducer;
