/**********************************************************************************
 *
 *  CAUTION!! DO NOT EDIT THIS FILE MANUALLY.
 *
 *  edit _*.json.mjs in same directory instead and use
 *
 *  node script/mergeI18n.mjs
 *
 *  The script will be merge _th.json.mjs & _en.json.mjs together which mean it will copy "new" key from one file to another, beautify and sort automatically.
 *
 **********************************************************************************/

/* eslint-disable */
export default {
  homePageBanner: `Get your ED meds today: Save up to 35% discount off treatment.`,
  aboutDescDialog1: `
	HIBRO is an online men’s health clinic that provides anonymous counseling and treatment plans for men that needed a more accessible option. It was founded by a team of bros like you, who experienced the same struggles trying to solve these issues in our own ways. From feeling uncertain before sex, to obsessing over your hairline every day in front the mirror, it’s stressful  not being your best self each day. You are definitely not alone in these pains.<br /><br />
	We get it. Men health is not exactly an easy topic to openly talk about, with the information online often incomplete and outdated. What do the guys end up doing? For people experiencing sexual dysfunction issues in Thailand, <a class="link" href="https://pubmed.ncbi.nlm.nih.gov/18821288/" rel="noopener noreferrer" target="_blank">only 3% ended up getting help from care providers.</a> That’s a crazy number of people who decided not getting help, and a gap in the current healthcare system.`,
  aboutDescDialog2: `We hear this a lot when we’re growing up. It pushes for certain personality traits for guys. But should it impact on your personal health decisions with the toxic masculinity? Men’s health stigma can span across sex, mental, identity…it is up to us to give Men’s Health a better, healthier awareness.`,
  aboutDescDialog3: `
		Taking the first step is hard, but don’t let any external factors be in the way of you getting help. So, we set our goals to build the safest and easiest platform for men. We assemble a team of specialized doctors from top institutions, work with legitimate pharmacy chain who shares the same ethical values as ours, and use tech to ensure utmost privacy and safety for our users. Let’s do it for the better, for your peace of mind, and for a healthier relationship with the people you love. <br /><br />
		Sometimes, being a man can be hard. But let’s be hard only in the places we want.<br /><br />
		Jerry@HIBRO`,
  aboutHibro: `<span class="font-[CentraNo2]">ABOUT HIBRO</span>`,
  aboutPageBeAMan: `“Be a man, do the right thing.” – says Russel Peter, your bros, your SO, heck, even to yourself at some point.`,
  aboutPageBroLetsDo: `Bro, let’s do something about it`,
  aboutPageSubtitle: `<span class="font-[CentraNo2]">If something is bothering you, we want to help. Complete an online visit to get started.</span>`,
  aboutPageSubtitle2: `Healthcare at your doorstep, for men by men`,
  aboutPageweAreMenThatJust: `WE ARE MEN THAT JUST WANTS TO MAKE THINGS ACCESSIBLE FOR OUR FELLOW MEN.`,
  accept: `Accept`,
  agree: `Agree`,
  all: `All`,
  backToHomePage: `Back to home`,
  backToTop: `Back to Top`,
  blogPageSubtitle: `Hi Bro, Welcome to Your Health Guide`,
  blogPageTitle: `สาระความรู้ และเทคนิคในการดูแลสุขภาพ`,
  certifyBy: `Certified by The Department of Business Development`,
  completeOnlineVisit: `If something is bothering you, we want to help. Complete an online visit to get started.`,
  conRead: `Read more`,
  confirm: `Confirm`,
  contactPageCompany: `Endose Co., Ltd.`,
  contactPageCompanyAddr: `Summer Point 7 Level 2, Sukhumvit 69 Sukhumvit Road Phra Khanong Nua Wattana Bangkok 10110`,
  contactPageEmail: `care@sayhibro.com`,
  contactPageFAQ: `Go Help Center`,
  contactPageHello: `Let us know how we can help you, bro.`,
  contactPageIfhaveQuestion: `If you have any questions, please contact via:`,
  contactPageLine: `@hibro`,
  contactPageNeedMoreHelp: `Need more Help?`,
  contactPageNeedMoreHelpDesc: `Get instant answers to common question.`,
  contactPagePhone: `(02) 200 1032`,
  contactPageTitle: `Contact Us`,
  cookieConsentMessage: `We use cookies and other related technologies to ensure the best website experience for you. You can edit your preference setting anytime. `,
  cookieConsentMessageLink: `Click here for more details.`,
  dapoxetine: `Last longer pill`,
  erectileDysfunction: `Erectile Dysfunction`,
  finasteride: `Oral Treatment`,
  footerAbout: `About us`,
  footerBlog: `Blog`,
  footerContactUs: `Contact us`,
  footerHelpCenter: `Help center`,
  footerHowItWork: `How it works`,
  footerMDTeam: `Medical team`,
  footerPayConfirm: `Payment Confirmation`,
  footerPrivacy: `Privacy policy`,
  footerTermOfUse: `Terms of use`,
  goToSurvey: `Survey`,
  hair: `Hair`,
  hairlossTag: `ผมร่วง ผมบาง`,
  hairStartConsult: `สั่งซื้อ`,
  hairlossPageIndex1: `อาการผมร่วง ผมบาง ศีรษะล้าน เป็นอย่างไร`,
  hairlossPageIndex2: `ลักษณะของผมร่วง`,
  hairlossPageIndex3: `การรักษาอาการผมร่วง ผมบาง ศีรษะล้าน`,
  hairlossPageIndex4: `How we can help, get ahead!`,
  hairlossPageSubtitle: `Hair Loss 101`,
  hairlossPageTitle: `รู้จักอาการผมร่วง ผมบาง ศีรษะล้าน<br/> พร้อมวิธีรักษาอย่างเห็นผล`,
  health: `Health`,
  helpPageCateAccounts: `Accounts`,
  helpPageCateAccountsFAQ1: `Your order history`,
  helpPageCateAccountsFAQ1Desc: `You can view your treatment information and scheduled orders in your account profile, including your full order history, including invoices and tracking history in your account.`,
  helpPageCateAccountsFAQ2: `How can I update my information?`,
  helpPageCateAccountsFAQ2Desc: `You can easily update your account information in your account profile, including payment options, delivery address and so on.`,
  helpPageCateAccountsFAQ3: `How do I delete my account?`,
  helpPageCateAccountsFAQ3Desc: `
  We're sorry to hear you wish to cancel your Hibro subscription, but we understand it might not be the fit for every bro. We try to make this as easy as possible for you and offer two ways to cancel:<br />
  You can log in to your account and submit a request online, or give us a call directly.
`,
  helpPageCateAccountsFAQ4: `How can I reset my password?`,
  helpPageCateAccountsFAQ4Desc: `You can reset your password with the link <a class="underline" href="/reset-password">here</a>.`,
  helpPageCateContact: `Contact Us`,
  helpPageCateContactFAQ1: `Contact us`,
  helpPageCateContactFAQ1Desc: `
    Our Customer Care team is also available to help you by phone, email, or even Line chat. If you have an urgent clinical need, calling (02) 2001 032 is usually the fastest way to reach us.<br /><br />
  
    <b>Daytime phone support</b><br />
    Our normal business hours are Monday to Friday 8:00 AM - 6:00 PM and Saturday and Sunday 8:00 AM - 6:00 PM.<br /><br />
  
    <b>Evening phone support</b><br />
    After normal business hours, a voicemail system is available for customers. Please leave a message, and someone will get back to you as soon as possible. Our pharmacists will return urgent calls as soon as they can.<br /><br />
  
    <b>Email and chat</b><br />
    You can reach us via email, chat in our online platform, or through Line chat at @hibro."
  `,
  helpPageCateGeneral: `General`,
  helpPageCateGeneralFAQ1: `Who is HIBRO for?`,
  helpPageCateGeneralFAQ1Desc: `
    HIBRO offers telemedicine services for adults who are experiencing erectile dysfunction, hair loss and premature ejaculation.<br /><br />
    Our Thai licensed healthcare providers design the right treatment plan for you, based upon your health condition and needs. Our accredited pharmacy partner delivers it to you in a discreet packaging.<br /><br />
    You have to be at least 20 years of age, have had an in-person doctor’s appointment in the last year, and be a resident of the Kingdom of Thailand to be eligible to receive treatment through our platform.
  `,
  helpPageCateGeneralFAQ2: `Who are the providers on HIBRO?`,
  helpPageCateGeneralFAQ2Desc: `All HIBRO healthcare providers are licensed physicians accredited by the Medical Council of Thailand. Each provider undergoes an extensive background check, license verification process, and specialty requirements to treat your condition. We will be able to share the medical license, and credentials of our providers upon your request.`,
  helpPageCateGeneralFAQ3: `Where is the medication come from?`,
  helpPageCateGeneralFAQ3Desc: `As we are a technology provider only, we work with accredited pharmacy partners to deliver the treatment designed by the doctor. To ensure the quality source of treatments, our pharmacy partner is qualified Good Pharmacy Practice by the Pharmacy Council of Thailand.`,
  helpPageCateGeneralFAQ4: `Do I need to see a doctor in-person to use HIBRO?`,
  helpPageCateGeneralFAQ4Desc: `
    In order to receive treatment from HIBRO, you need to have been to a healthcare provider over the 24 months. This is because we need to take your most updated health information, including the blood pressure. If you haven’t been to a healthcare provider in the past 24 months you’re not a good fit for HIBRO’s service.<br /><br />
    Additionally, after our doctors have reviewed your health records, he/she may recommend you to visit a healthcare provider in person instead, if your conditions require a closer look.
  `,
  helpPageCateGeneralFAQ5: `How quick will a healthcare professional review my record?`,
  helpPageCateGeneralFAQ5Desc: `
    After your online visit a practitioner will complete his/her initial review of your medical history within 24 hours.<br /><br />
    If you are waiting longer than you should, you can email us at care@sayhibro.com or call us directly.
  `,
  helpPageCateGeneralFAQ6: `Is a video chat or a call required?`,
  helpPageCateGeneralFAQ6Desc: `It depends. If you choose our phone consultation service, a direct call with our doctors are required. In other situations a doctor may request a call or video chat to follow up on your online forms. This is to ensure that they full understand your health situation, and have the holistic approach to design the most suitable treatment for you.`,
  helpPageCateGeneralFAQ7: `Can I talk to a healthcare professional to address my concerns?`,
  helpPageCateGeneralFAQ7Desc: `
    For sure! You can chat with your designated physician through email or messaging anytime as soon as you complete the process of your first treatment.<br /><br />
    If you would like to chat with the doctor or nurse practitioner via a secure phone call or video chat, message our customer care team and we’ll coordinate for you.
  `,
  helpPageCateGeneralFAQ8: `What if I have an emergency?`,
  helpPageCateGeneralFAQ8Desc: `If you are experiencing a medical emergency, please contact your doctor or call 1554 immediately.`,
  helpPageCateOrdering: `Order & Shipping`,
  helpPageCateOrderingFAQ1: `Delivery`,
  helpPageCateOrderingFAQ1Desc: `
    <b>How long will it take?</b><br /><br />
    Following your consultation with the doctor, your treatment plan will arrive within two to six business days through our partner logistic network. Once your order is shipped you will receive an email or SMS with links to track your shipment.<br /><br />
    Orders are only able to be shipped on weekdays; the order lead time does not include the day of shipping.<br /><br />
    <b>How much does shipping cost?</b><br />
    Free! As an HIBRO customers, you never need to worry about paying for standard services and shipping for your ongoing treatment plans.<br /><br />
    <b>Where we ship?</b><br />
    We deliver treatment plans to physical addresses anywhere in Thailand. <br /><br />
    <b>Discreet packaging</b><br />
    We deliver your medications in a discreet box with no pharmacy markings. For added security, we also use tamper-proof seals on our delivery boxes.<br /><br />
    <b>Your Delivery Address </b><br />
    All parcels will be delivered to the provided address. <br /><br />
    We understand that everyone has different work schedule and lifestyle. To best cate to each individual schedule, it is recommended to provide a work address for delivery if no one is home during business hours.<br /><br />
    Additional charges may apply if you could not pick up the treatment and requires us to ship again.
  `,
  helpPageCateOrderingFAQ2: `Returns and Exchange `,
  helpPageCateOrderingFAQ2Desc: `
    Due to health regulations general medicines and prescriptions cannot be returned / reused, and all sales are final.This is because our products are medications, so once they are packaged for you, they cannot be used for another patient.<br />
    However, if the items are defective or damaged, we can make an exchange for you for the same item.Send us an email at care @sayhibro.com and we’ll coordinate with you how to exchange your order."
  `,
  helpPageCateOrderingFAQ3: `Refund`,
  helpPageCateOrderingFAQ3Desc: `
    <p>We will provide you with a refund on your treatment plan if:</p>
    <ul>
      <li>The doctor decides it is not appropriate to provide a treatment plan to you;</li>
      <li>The treatment plan ordered is no longer available; or</li>
      <li>An incorrect treatment plan or pricing error is identified.</li>
    </ul>
    <br />
    If your refund is approved, it can take up to 14 days to be completed. 
  `,
  helpPageCatePayment: `Payment`,
  helpPageCatePaymentFAQ1: `How much does a visit cost?`,
  helpPageCatePaymentFAQ1Desc: `
    There are two types of costs that would incur on the platform: 1) online consultation, 2) treatment plans. <br />
    Your online consultation costs no more than 300THB. This doesn’t include the cost of your treatments (if doctors find you suitable). If your physician or pharmacist determines you’re not a good candidate for telemedicine, you’ll get a full refund for your online visit.<br />
    You’re only charged for the medication after your practitioner has approved a personalized treatment plan. You can log into your account profile to get an update and review on plans details and costs.
  `,
  helpPageCatePaymentFAQ2: `Can I use insurance to pay for HIBRO?`,
  helpPageCatePaymentFAQ2Desc: `We are an entirely self-pay service and the services on Hibro are not covered by insurance. But we think you should know that our 200THB online visit is typically 50% less than the overall cost of your visit to doctors.`,
  helpPageCatePaymentFAQ3: `When will I be charged?`,
  helpPageCatePaymentFAQ3Desc: `
    You will be charged at the time of your first order of HIBRO treatment.<br />
    If you have chosen our prepaid plan, we will process the delivery each month. Our pharmacy partner will process the order, and make sure you never run out on your treatment. <br />
    You can view your next refill date in your account profile.<br />
  `,
  helpPageCatePaymentFAQ4: `How do I pause or cancel my orders?`,
  helpPageCatePaymentFAQ4Desc: `
    Since our shipments come either one-time or prepaid plans depending on what you choose during your visit, there is no need to concern about having to pay more from the auto-charge!<br />
    If you wish to delay a specific shipment for one of the deliveries, please call or email us to make the change.  Our customer service will help coordinate the delivery so you can get the treatment at the time you prefer.
  `,
  helpPageCatePaymentFAQ5: `How long should I expect my refund?`,
  helpPageCatePaymentFAQ5Desc: `
    <p>If you are qualified for a refund (please visit the Refund section), we will be able to process the refund depending if you use:</p><br />
    <ul>
      <li>Credit card: 30 days from the date of refund confirmation. </li>
      <li>Bank transfer or Promptpay: 14 days from the date of refund confirmation. </li>
    </ul>
  `,
  helpPageCatePrivacy: `Account Security and Privacy`,
  helpPageCatePrivacyFAQ1: `Account Security and Privacy `,
  helpPageCatePrivacyFAQ1Desc: `
  All customer data is encrypted from end-to-end. Each Hibro team member is trained to handle HIPAA-protected data and we regularly hire external security firms to validate our security practices. Our process is also in full compliance to the Personal Data Protection Act (PDPA) as defined by the regulations.<br /><br />
  For more information, <a class="underline" href="/privacy-policy">please review our privacy policy</a>.
`,
  helpPageSubtitle: `Got questions? Let us help you clear it up.`,
  helpPageTitle: `<span class="font-[CentraNo2]">Help Center</span>`,
  hibroMedicalTeam: `<span class="font-[CentraNo2]">HIBRO Medical Team</span>`,
  homePageActionNow: `<span class="font-[CentraNo2] heading3 mb-[16px]">TAKE ACTION NOW</span><br /><span class="font-[CentraNo2] title">You are only a few steps away from a healthier, better life. Explore more about our services to get started.</span>`,
  homePageActionPE: `<span class="font-[CentraNo2] heading3 mb-[16px]">TAKE ACTION NOW</span><br /><span class="font-[CentraNo2] title">You are only a few steps away from a healthier, better life. Explore more about our services to get started.</span>`,
  homePageActionED: `<span class="font-[CentraNo2] heading3 mb-[16px]">TAKE ACTION NOW</span><br /><span class="font-[CentraNo2] title">You are only a few steps away from a healthier, better life. Explore more about our services to get started.</span>
  `,
  homePageActionHL: `<span class="font-[CentraNo2] heading3 mb-[16px]">TAKE ACTION NOW</span><br /><span class="font-[CentraNo2] title">You are only a few steps away from a healthier, better life. Explore more about our services to get started.</span>
  `,
  homePageGotQuestion: `Got questions? Please visit our`,
  homePageHair: `Hair`,
  homePageHairDesc: `Get direct access to clinically-approved hair loss treatments, and have a hair specialist to review your regime for the best hair growth saftey and result`,
  homePageHairItem: `Hair Loss`,
  homePageHelpCenter: `Help Center`,
  homePageHibroMDTeam: `Medical Team for the Bros`,
  homePageHibroMDTeamDesc: `We work with Thailand's leading men's health experts to ensure the safety and results of treatments to our fellow bros`,
  homePageHibroMDTeamMeet: `Visit medical team`,
  homePageHowItWork: `How it works`,
  homePageHowItWork1: `1. Choose a service`,
  homePageHowItWork1Desc: `Make an appointment to speak with a doctor first, or get treatments directly by answering a short health survey`,
  homePageHowItWork2: `2. Get a response in 24 hours`,
  homePageHowItWork2Desc: `Our specialists can provide a diagnosis, treatment recommendation, or write a prescription to approve your order request`,
  homePageHowItWork3: `3. Receive your treatment`,
  homePageHowItWork3Desc: `If you are approved for treatments, our pharmacy will send directly to you in 2 days with free shipping`,
  homePageMD1: `Dr. Akanae Wongsawat, MD<br />(#27048)`,
  homePageMD1Desc: `Urology Surgeon at MedPark Hospital`,
  homePageMD2: `Dr. Chayut Fungtongjaroen, MD<br />(#38429)`,
  homePageMD2Desc: `CEO and Medical Director of HE Clinic Thonglor`,
  homePageMD3: `Dr. Phatcharawat Chirasuthat, MD<br />(#43700)`,
  homePageMD3Desc: `Dermatologist at Institute of Dermatology, Ministry of Health`,
  homePageOurTreatments: `Our treatments`,
  homePageOurTreatmentsDesc: `At HIBRO, we source all Thai FDA-approved treatments only from licensed pharmacies to ensure the highest standard of safety to our bros.`,
  homePagePerPill: `/pill`,
  homePagePerBottle: `bottle`,
  menariniName: `Menarini`,
  homePagePill1: `Generic blue pill with efficacy of 50mg or 100mg. Manufactured by the Government Pharmaceutical Organization (GPO)`,
  homePagePill2: `High-dose option manufactured by Eli Lilly. Active up to 24-36 hours. Available in as-needed use or daily pills.`,
  homePagePill3: `Topical Minoxidil 5%. Manufactured by Polipharm. Suitable for men only`,
  homePagePill4: `The original blue pill manufactured by Pfizer. Active up to 6-8 hours depending on dosage.`,
  homePagePill5: `The only FDA-approved treatment in Thailand to slow down ejaculation. Take one tablet 1-3 hours before sex. Manufactured by Menarini.`,
  homePageReview: `Real bros,<br />real reviews`,
  homePageReviewDesc: `See to what other users are saying about the services and the results that they are getting`,
  homePageSexualHealth: `Sexual Health`,
  homePageSexualHealthDesc: `We offer discreet phone consultation with Thai-licensed doctors, with different prescription treatment options available for you to choose from at your fingertips`,
  homePageSexualItem1: `Erectile Dysfunction`,
  homePageSexualItem2: `Premature Ejaculation`,
  homeSubtitle: `<p>This website has been verified for the accuracy of medical information by the HIBRO medical team.</p>`,
  homepageMencare: `Men's care that treats:`,
  homepageProblemList: `Erectile dysfunction<br />Premature ejaculation<br />Hair loss<br />ED clinic near me<br />Anonymous sex health consult`,
  homepageTalkToRealMD: `Talk to real doctor online, or get clinical treatments delivered to your door, without the hassle.`,
  findMyTreatment: `Find my treatment`,
  howHiBroWorkWithMD: `How HIBRO further works with our network of doctors:`,
  howHiBroWorkWithMDItem1: `Doctors operate in an independent manner contractually to ensure that the doctors stay true to their medical ethics`,
  howHiBroWorkWithMDItem2: `Doctors are paid with fixed fees per consultation, not based on results or number of prescriptions written. With no extra incentives, this is to prevent potential conflict, and always to act in your best interests`,
  howHiBroWorkWithMDItem3: `Our medical advisors continuously monitor doctor's conduct and medical protocols to ensure our users are always safe to the highest standards`,
  howHiBroWorkWithMDItem4: `We purposely design our medical procedures to provide the doctors with the most complete medical data about each case. This helps them to evaluate holistically, and come up with the most appropriate treatment plans`,
  ifInterested: `If you are interested in getting consultation from a medical professional, or an FDA prescription treatments from HIBRO, contact us directly through the above to find out more.`,
  introduction: `Introduction`,
  lefestyle: `Lifestyle`,
  login: `Login`,
  logout: `Logout`,
  md1Slogan: `HIBRO, a new option for the better health of the little brother. Take care yourself by a specialist doctor, or just have a chat with a brother  with a peace of mind, safe, and worry-free.`,
  md2Slogan: `A new alternative for men to restore sexual health that is convenient, economical and safe under the supervision of a specialist doctor. HIBRO will make it possible for everyone to have access to appropriate treatments and focus more on gender health issues.`,
  md3Slogan: `Telemedicine is playing an increasingly important role in today's treatment of patients. HIBRO is another option that gives patients access to standardized treatments and medicines.`,
  mdDetailPageDocumentNo: `(License ID {{number}})`,
  mdDetailPageGettingToKhow: `Get to know our doctors`,
  medicalTeamPageDialog1: `
		Our team of medical advisors consists of senior men's health doctors specializing in urology, sexual dysfunction and dermatology with over 10 years of care experience. <br /><br />
		Our experts play a key role in ensuring the integrity of our medical guidelines, written contents and personalized care experience for our user. Leveraging their experience, we are committed to give you the most comfortable experience possible, with bros you can trust at hibro.`,
  medicalTeamPageSubtitle: `<p class='font-bold title'>Our medical team with over ten years of experience in men’s health, from urology to dermatology</p>`,
  medicine: `ยา/เวชภัณฑ์`,
  minoxidil: `Minoxidil`,
  optOut: `Opt out`,
  ourMedicalExport: `Our Medical Experts`,
  ourMedicalExportDesc: `Our medical team with over ten years of experience in men’s health, from urology to dermatology`,
  pdpaPageAgreePerformance: `For Performance and Service Improvements`,
  pdpaPageAgreePerformanceDesc: `These cookies enable us to show contents and experiences that are specific to you in order to enhance your user experience.`,
  pdpaPageCookiesSettings: `Cookies Settings`,
  pdpaPageForMarketing: `For Marketing and Promotions`,
  pdpaPageForMarketingDesc: `These cookies are put in place by our technology partners to build a profile of your interests in order to display advertising and promotions that are relevant to you.`,
  pdpaPageStrictlyNecessary: `Strictly Necessary`,
  pdpaPageStrictlyNecessaryDesc: `These cookies are necessary for the website to function. Without the use of these data, you may not be able to browse the application with good experience.`,
  phoneConsultIfnotSure: `If you are unsure of choosing a treatment, you can choose phone consult with doctor first to get treatment recommendation.`,
  placebo: `Placebo`,
  prematureEjaculation: `Premature Ejaculation`,
  privacyPolicy: `Privacy Policy`,
  proListEasy: `24/7`,
  proListEasySub: `100% Online`,
  proListFast: `Fast 2-day `,
  proListFastSub: `delivery`,
  proListFree: `Free shipping`,
  proListFreeSub: `nationwide`,
  proListSafe: `Safe and discreet`,
  proListSafeSub: `packaging`,
  proListFDAApproved: `FDA`,
  proListFDAApprovedSub: `approved treatments`,
  proListThaiLicense: `Thai-licensed`,
  proListThaiLicenseSub: `specialized doctors`,
  proListPharmaLicense: `Legitimate`,
  proListPharmaLicenseSub: `licensed pharmacies`,
  profileAcademicWork: `Academics`,
  profileCertificate: `Certifications`,
  profileEducation: `Education`,
  profileSpecialist: `Specializations`,
  profileWorks: `Experience`,
  questionnaire: `Questionnaire`,
  readMore: `Read more`,
  readyToStartOnlineVisit: `Ready To Start Your Online Visit`,
  references: `References`,
  register: `Register`,
  relatedArticle: `Related Article`,
  reviewTitle: `Real bro, real reviews`,
  reviewedBy: `Reviewed by {{name}}`,
  salePageAddLine: `Add Line`,
  salePageConsultNow: `Consult Now`,
  salePageFAQ: `FAQ`,
  salePageNeedMoreHelp: `Need more Help ?`,
  salePageNeedMoreHelpDesc: `Talk to our team in HIBRO Line Official account to understand more about what we treat and how we can help`,
  salePageStartOurWebsite: `Our website`,
  salePageStartQuiz: `Start Quiz`,
  salePageTakeAQuiz: `<span class="font-[CentraNo2]">Take a quiz to assess risk of ED</span>`,
  salePageTakeAQuizDesc: `The risk to erectile dysfunction is based on multiple lifestyle factors. Check it out to get to know more about your risk factors.`,
  setting: `Settings`,
  sex: `เพศสัมพันธ์`,
  sildenafil: `Little blue pill`,
  sorryMsg404: `Sorry, the page you’re looking for couldn’t be found.`,
  startConsult: `Start Consult`,
  startYourOnlineVisit: `Start<br />Your Online Visit`,
  stillHaveQuestion: `Still have questions? `,
  stillHaveQuestionVisit: `Visit our Help Center`,
  treatmentEDPageAssured: `All treatments come from licensed pharmacies`,
  treatmentEDPageBluePillDesc: `
		<p class="text-[14px] leading-[20px] mt-[16px]">The iconic pill trademarked by Pfizer. Its ingredient is a PDE-5 inhibitor that help relaxes the muscle in the blood vessels, and increases blood flow to support erection.</p>
		<p class="text-[14px]"><span class="font-bold">Method:</span> Oral tablet</p>
		<p class="text-[14px]"><span class="font-bold">Dosage:</span> 25 mg, 50 mg, 100 mg</p>
		<p class="text-[14px]"><span class="font-bold">Type:</span> As needed</p>
		<p class="text-[14px]"><span class="font-bold">Use:</span> 30-60 minutes before sexual intercourse</p>
		<p class="text-[14px]"><span class="font-bold">Duration:</span> 6-8 hours</p>
  `,
  treatmentEDPageBluePillName: `Little Blue Pill`,
  treatmentEDPageCanEDCure: `Can ED be treated?`,
  treatmentEDPageCanEDCureDetail: `
    <p>Yes, there are several options for treating ED, including behavioral changes, counselling and medications. 

    There are a number of ED medications currently approved for sale in Thailand, which are in oral tablets form. ED treatments in Thailand are classified as controlled drug and requires a prescription from a doctor. Speak to a healthcare professional for any side effects before starting the treatment.</p>
  `,
  treatmentEDPageCanEDTreatmentOptionDesc: `The medications currently approved for use in Thailand for erectile dysfunctions are prescription medications in Thailand, and requires consult with a healthcare professional for potential underlying causes and interactions with other drugs before starting the treatment.`,
  treatmentEDPageCanEDTreatmentOptionSubtitle: `Clinically proven ED medications at HIBRO`,
  treatmentEDPageCanEDTreatmentOptionTitle: `ED Treatment Options`,
  treatmentEDPageCauseED: `Cause of ED`,
  treatmentEDPageCauseEDDetail: `ED can be caused by a number of reasons, including heart disease, vascular disease, diabetes, obesity, depression, anxiety, stress, smoking, drinking alcohol, or side effects from certain medications and more. In some cases, ED may come from a combination of factors without specific cause.`,
  treatmentEDPageDesc: `Get advice from a Thai-licensed doctor. Request medication to be prescribed and delivered to your door by registered pharmacies, private and safe`,
  treatmentEDPageErectileDysfunction: `หย่อนสมรรถภาพทางเพศ`,

  treatmentEDPageFrequentlyAskedQuestions: `Frequently Asked Questions`,
  treatmentEDPageFAQ1: `How do I know if I have ED?`,
  treatmentEDPageFAQ1Desc: `If you are unable to erect or maintain erection during sexual intercourse, you may start to develop ED risk. Speak to a doctor to understand your underlying condition and the best way treat the condition.`,
  treatmentEDPageFAQ2: `ED Treatment`,
  treatmentEDPageFAQ2Desc: `Medications for treating erectile dysfunction helps by increasing the blood flow to the penis during sexual stimulation. The drug blocks the action of an enzyme called PDE5 that causes blood to flow out of penis. This results in a better erection physically.`,
  treatmentEDPageFAQ3: `Which type is best for erectile dysfunction drugs?`,
  treatmentEDPageFAQ3Desc: `FDA approved ED treatments all belong to the same class of drugs. These medications relax muscles in the penis and improve blood flow to treat ED. Although they act similarly, some differences may cause a medical professional to suggest one over the other. Choosing between them should also come down to which fits best with your health and lifestyle.`,
  treatmentEDPageFAQ4: `What is the difference between the on-demand and daily medications for the Weekend Pill?`,
  treatmentEDPageFAQ4Desc: `Both medications are classified as phosphodiesterase-5 inhibitors (PDE5i) for treating ED. On-demand medications are taken 30-60 minutes before sexual activity, which lasts up to 8 hours. In some cases, your doctor may prescribe the Weekend Pill at a daily dose of 5mg, with its effect lasting up to 36 hours. 

  Both treatments show similar effectiveness and safety in studies compared to placebo. Most differences between the two drugs have to do with user preference about how long the drug lasts, how it is taken, and the medical history of the person taking it.`,
  treatmentEDPageFAQ5: `What is the difference between sildenafil and Viagra?`,
  treatmentEDPageFAQ5Desc: `Sildenafil is the generic name of Viagra®. In Thailand, there are some generic versions of Viagra® e.g. Sidegra®, Elonza® containing the same active ingredient (sildenafil citrate) as Viagra®. There is no chemical difference between the sildenafil used in Pfizer's branded Viagra® tablets and the generic sildenafil available from other manufacturers. As a general rule, assuming the dose is the same, you will get the same ED treatment effects from a generic form of sildenafil as you will from a Viagra® tablet.`,
  treatmentEDPageFAQ6: `How often should I use the Blue Pill?`,
  treatmentEDPageFAQ6Desc: `When you need to use the treatment, you should take it no more than once a day. The usual dose is 50mg. The dose can be increased to 100 mg or decreased to 25 mg depending on its effect.`,
  treatmentEDPageFAQ7: `Can I take the Blue Pill as a healthy person?`,
  treatmentEDPageFAQ7Desc: `Yes, there is nothing wrong taking ED treatment when you are healthy. Speak to a doctor to understand its benefits and risks.`,
  treatmentEDPagePercent35Save: `Save up to <span class="text-[#D60000]">35%</span> off`,
  treatmentEDPagePercent78Work: `Study shows 78% of men with better erection using ED treatment`,
  treatmentEDPageStartWith180: `Start from 180THB`,
  treatmentEDPageSubtitle: ``,
  treatmentEDPageSuccessSexAttmp: `Successful intercourse Attempt`,
  treatmentEDPageTitle: `Erectile Dysfunction (ED)`,
  treatmentEDPageTopic1: `<span class="font-[CentraNo2]">Get ED Treatments, </span><br /><span class="font-[CentraNo2]">Prescribed Online</span>`,
  treatmentEDPageTopic2: `About Erectile Dysfunction`,
  treatmentEDPageTreatmentResult: `Results of the Treatments`,
  treatmentEDPageTreatmentResultDesc: `The studies show that Thai men taking ED treatment has achieved 2x results in successful intercourse attempts with improved erections, compared to placebo group. The success rate was 66.16% in the Treatment groups, while the placebo groups has 33.05% success rates.`,
  treatmentEDPageTreatmentResultDesc2: `The percentage of global efficacy assessment was improved in the Treatment group by 82.5%, compared to 36.1% in the placebo group.`,
  treatmentEDPageTreatmentResultSrcTag: `Source :`,
  treatmentEDPageTreatmentResultSrc: ` Kongkanand A. The efficacy and safety of oral ED treatment in Thai men with erectile dysfunction (2003)`,
  treatmentEDPageWhatED: `What is ED?`,
  treatmentEDPageWhatEDDetail: `<p>Erectile dysfunction, or impotence, is the inability to get and keep an erection firm enough for sex. Having erection trouble from time to time isn't necessarily a cause for concern. If ED becomes an ongoing issue, however, it can cause stress, affect your self-confidence and contribute to relationship problems. Problems getting or keeping an erection can also be a sign of an underlying health condition that needs treatment and a risk factor for heart disease. Risk to ED increases over time with age.</p>`,
  treatmentEDPageYellowPillDesc: `
		<p class="text-[14px] leading-[20px] mt-[16px]">Known for its long duration, the Weekend Pill helps relaxes the muscle in the blood vessels to aid erection. Manufactured by Eli Lilly. Available for as-needed use and daily medications</p>
		<p class="text-[14px]"><span class="font-bold">Method:</span> Oral tablet</p>
		<p class="text-[14px]"><span class="font-bold">Dosage:</span> 2.5 mg, 5 mg, 10 mg, 20 mg</p>
		<p class="text-[14px]"><span class="font-bold">Type:</span> 20 mg (as needed) or 5 mg (daily dose)</p>
		<p class="text-[14px]"><span class="font-bold">Use:</span> 30 minutes before for as needed tablet</p>
		<p class="text-[14px]"><span class="font-bold">Duration:</span> 24-36 hours</p>
  `,
  treatmentEDPageYellowPillName: `Weekend Pill`,
  treatmentEfficacy: `Treatment Efficacy`,
  treatmentErectilePageIndex1: `ภาวะเสื่อมสมรรถภาพทางเพศ (Erectile Dysfunction : ED)`,
  treatmentErectilePageIndex2: `สาเหตุของการเสื่อมสมรรถภาพทางเพศ`,
  treatmentErectilePageIndex3: `เกรดการวัดความแข็งตัวของอวัยวะเพศชาย`,
  treatmentErectilePageIndex4: `การรักษาภาวะเสื่อมสมรรถภาพทางเพศ`,
  treatmentErectilePageIndex5: `How we can help, get ahead!`,
  treatmentErectilePageSubtitle: `Erectile<br />Dysfunction 101`,
  treatmentErectilePageTitle: `หย่อนสมรรถภาพทางเพศ`,
  treatmentHLPage10PercentSave: `save up to <span class="text-[#D60000]">10%</span>`,
  treatmentHLPageAboutHL: `<span class="font-[CentraNo2]">About Hair Loss</span>`,
  treatmentHLPageAssured: `Seeing results in 4-6 months`,
  treatmentHLPageBaldC: `Type C`,
  treatmentHLPageBaldCDesc: `Receding hairline like a half-circle or the letter C`,
  treatmentHLPageBaldM: `Type M`,
  treatmentHLPageBaldMDesc: `A gradual recession of the hairline around the temples`,
  treatmentHLPageBaldO: `Type O`,
  treatmentHLPageBaldODesc: `hair loss on the top of the scalp (the vertex)`,
  treatmentHLPageBaldPattern: `<span class="font-[CentraNo2]">Male Pattern Baldness</span>`,
  treatmentHLPageBaldU: `Type U`,
  treatmentHLPageBaldUDesc: `When the recession of the anterior hairline progresses over the vertex, which looks like a horseshoe`,
  treatmentHLPageCauseOfHL: `Cause of hair loss`,
  treatmentHLPageCauseOfHLDesc: `Male pattern hair loss is believed to be due to a combination of genetics and the male hormone dihydrotestosterone.`,
  treatmentHLPageClinicallyProve: `<span class="font-[CentraNo2]">Clinically proven hair loss medications at HIBRO</span>`,
  treatmentHLPageClinicallyProveDesc: `We offer both minoxidil and oral tablets as a standard treatment to stop hair loss for men. All medications are FDA-approved, and shipped directly by licensed pharmacies`,
  treatmentHLPageCombineRemart: `*when combining treatments under doctor advice`,
  treatmentHLPageDesc: `Get effective, FDA-approved hair loss medication prescribed by a real, Thai-licensed healthcare professional. Ship directly to your home`,
  treatmentHLPageDropperDesc: `
    <p class="text-[14px] leading-[20px] mt-[16px]">Minoxidil is a topical medication in liquid form that increase blood flow around hair follicles, and stimulates the follicle movement to the growth phase</p>
    <p class="text-[14px]"><span class="font-bold">Ingredient:</span> Minoxidil</p>
    <p class="text-[14px]"><span class="font-bold">Method:</span> Topical solution</p>
    <p class="text-[14px]"><span class="font-bold">Type:</span> Daily use</p>
    <p class="text-[14px]"><span class="font-bold">Benefits:</span> Easier to maintain hair volume</p> 
  `,
  treatmentHLPageDropperName: `Minoxidil`,
  treatmentHLPageFrequentlyAskedQuestions: `Frequently Asked Questions`,
  treatmentHLPageFAQ1: `How does hair loss treatment work at HIBRO?`,
  treatmentHLPageFAQ1Desc: `
		At HIBRO, our hair specialists will assess the severity of your conditions from your medical histories, review the notes and photos that you uploaded to the system, and provide necessary consultation to you.<br /><br />
		After all of your questions are answered, our specialists will verify the treatment you are taking and ensure it is safe for you before delivery. <br /><br />
		Periodically, our hair specialist will follow up on your progress to identify early problems, make suggestions to treatment methods, and give tips on best practices. 
	`,
  treatmentHLPageFAQ2: `Is there a cure for hair loss?`,
  treatmentHLPageFAQ2Desc: `
		It is possible to stop hair loss early on with medications. However, once the hair loss passes a certain stage where the hair follicle loses the attachment to your skin, the hair loss will become permanent. At that point, no medications can provide complete cure to hair loss. <br /><br />
		It is important consult and treat early before it is too late once you identify symptoms of hair loss.
	`,
  treatmentHLPageFAQ3: `How long does it take to grow my hair back?`,
  treatmentHLPageFAQ3Desc: `Using both options for hair loss can take at least 4 months to see results, especially when the medication helps kickstart fresh anagen phase for some of your hair follicles. It is important that hair regrowth will only persist when using consistently, otherwise the new hair will fall within 6 months after stopping the drug.`,
  treatmentHLPageFAQ4: `What are the side effects for hair loss treatments?`,
  treatmentHLPageFAQ4Desc: `
  Hair loss treatment with medicine is one of the most effective and safest methods of treatment. With any medicine, there can be side effects occurred to some users.<br /><br />
  The common side effect with the minoxidil topical solution are local skin irritation and itchiness. There may also be increased facial hair growth or even more hair loss during the initial phase of the treatment. This is considered normal and will go away over time as your hair rotates its growing phases.<br /><br />
  In research studies, there also reported rare sexual dysfunction or lower semen volume from using the oral tablets, with less than 2% rate.<br /><br />
  These symptoms can be relieved once stopping the treatments. Consult your doctor if you have any questions, or if such side effects occur."
	`,
  treatmentHLPageFAQ5: `What will the doctor recommend me to take, minoxidil or oral tablets?`,
  treatmentHLPageFAQ5Desc: `A doctor will recommend the appropriate treatments for you based on efficacy, safety, and cost for the most effective hair loss treatment for you.Another key factor is based on your treatment preference(oral or topical) and your lifestyle(convenience or efficacy).`,
  treatmentHLPageFAQ6: `When should I need hair loss medication versus hair transplants?`,
  treatmentHLPageFAQ6Desc: `
  Minoxidil and oral treatment as a hair loss treatment is highly effective in patients who suffer the initial stages of hair loss for a short period of time. In other words, identify the issue and starting the treatment early is an early prevention measure, and will give much better results. Using the treatment early will also promote new hair growth as well.<br /><br />
  For hair transplant surgery, the patient's hair will begin to grow about 3 months after the treatment, and will be fully grown in about 1 year to 1 year and a half.<br /><br />
  While the success rate is about 90%, some patients may not find it suitable for this therapy. Possible adverse reaction including worsening of blood clotting disease, uncontrolled diabetes or high blood pressure disease.<br /><br />
  In addition, the hair may start to fall again after the hair transplant. Therefore, doctors often recommend to use minoxidil and oral tablets continuously after the hair transplant surgery to maintain their hair volume."
	`,
  treatmentHLPageHaveOneOrMore: `Having one or more early symptoms above? Start a treatment with us today.`,
  treatmentHLPageMDNote: `Doctor’s note`,
  treatmentHLPageMDNoteDesc: `To enhance the results of the treatment, our doctors recommend to take both treatments together every day. It is suitable for those that are starting the treatment, or have had the conditions for a while.`,
  treatmentHLPagePercent94Work: `94% effective for men for combined treatment*`,
  treatmentHLPagePinkPillDesc: `
    <p class="text-[14px] leading-[20px] mt-[16px]">The oral medication that targets Dihydrotestosterone (DHT), a male sex hormone causing hair loss. The treatment blocks the action of enzymes and thus reduces the DHT production</p>
    <p class="text-[14px]"><span class="font-bold">Method:</span> Oral tablet ใช้เฉพาะจุด (Topical)</p>
    <p class="text-[14px]"><span class="font-bold">Type:</span> Daily use</p>
    <p class="text-[14px]"><span class="font-bold">Benefits:</span> Higher efficacy in treatment</p>
  `,
  treatmentHLPagePinkPillName: `Oral treatment`,
  treatmentHLPageProductSectionLeftFooter: `Using oral tablets and topical minoxidil for hair loss can take at least 4 months to see results. Medications alone will not be able to cure hair loss permanently. Hair regrowth will only persist when used, and the new hair will fall within 6 months after stopping the drug.`,
  treatmentHLPageProductSectionLeftTitle: `<span class="font-[CentraNo2]">Hair Loss</span>`,
  treatmentHLPageStartFrom250: `Start from 250THB`,
  treatmentHLPageStartWith250: `Prices start from ฿250`,
  treatmentHLPageSubtitle: ``,
  treatmentHLPageTitle: `<span class="font-[CentraNo2]">Hair Loss</span><br /><span class="small font-normal">(For Androgenetic Alopecia)</span>`,
  treatmentHLPageTopic1: `<span class="font-[CentraNo2]">Plant a seed today, grow mighty trees tomorrow</span>`,
  treatmentHLPageTreatmentResult: `Results of the Treatments`,
  treatmentHLPageTreatmentResultDesc: `
  Oral treatment is effective in stopping hair loss in 83% of men, compared to 28% in the placebo group. (McClellan, 1999)<br /><br />
  70.6% of men who took the 5% minoxidil sees improvement in their hair volumes in 16 weeks. (Olsen, 2007)<br /><br />
  Studies have also found that taking minoxidil and oral treatment in combination have shown better efficacy (94%) than taking either medication separately. Both medications do not interfere each other, and are safe to take together. (Hu, 2015)`,
  treatmentHLPageTreatmentResultSrc: `
  Source : 
  <a target="_blank" class="text-[12px]" href="https://pubmed.ncbi.nlm.nih.gov/9951956/">McClellan K. A review of oral tablet use in male pattern hair loss (1999)</a>
  <br />
  <a target="_blank" class="text-[12px]" href="https://onlinelibrary.wiley.com/doi/10.1111/dth.12246">Olsen E. 5% minoxidil topical foam versus placebo in the treatment of androgenetic alopecia in men (2007)</a>
  <br />
  <a target="_blank" class="text-[12px]" href="https://pubmed.ncbi.nlm.nih.gov/9951956/">Hu R. Combined treatment with oral tablet and topical minoxidil in male androgenetic alopecia (2015)</a>`,
  treatmentHLPageWayTreatHL: `Ways to treat hair loss`,
  treatmentHLPageWayTreatHLDesc: `A daily combination of oral medication and minoxidil (topical solutions) is considered an effective treatment for male pattern baldness.`,
  treatmentHLPageWhatIsHL: `What is hair loss?`,
  treatmentHLPageWhatIsHLDesc: `Hair loss, also known as androgenetic alopecia, happens when the large, thick pigmented hairs (terminal hairs) convert to barely visible, thin, depigmented hairs (vellus hairs) in the affected scalp area. The visual result becomes a progressive thinning and shortening of hair in affected scalp area.`,
  treatmentPEPage10PercentSave: `save up to <span class="text-[#D60000]">10%</span>`,
  treatmentPEPage3TimesEffective: `Slows down ejaculation by 2-3 times`,
  treatmentPEPageAssured: `FDA-approved medications in over 60 countries`,
  treatmentPEPageAverageSex: `Average Intercourse Duration`,
  treatmentPEPageBluePillDesc: `
    <p class="text-[14px] leading-[20px] mt-[16px]">Controls the ejaculation mechanism and extends the sexual activity up to 2-3 times for men between 18-64 years old</p>
    <p class="text-[14px]"><span class="font-bold">Method:</span> Oral tablet</p>
    <p class="text-[14px]"><span class="font-bold">Dosage:</span> 30 mg. 60 mg.</p>
    <p class="text-[14px]"><span class="font-bold">Recommend:</span> 30 mg. Use only as directed by a doctor</p>
    <p class="text-[14px]"><span class="font-bold">Use:</span> Taken 1-3 hours before sexual activity</p>
    <p class="text-[14px]"><span class="font-bold">Duration:</span> 19 hours</p>
    <div class="flex text-[14px]">
      <p class="font-bold">Caution:</p>
      <p class="flex-1">&nbsp;Users must not take more than one tablet once a day to avoid increased risk of side effects</p>
    </div>
  `,
  treatmentPEPageBluePillName: `Last Longer Pill`,
  treatmentPEPageCanPECure: `Can PE be treated?`,
  treatmentPEPageCanPECureDetail: `Yes. Although no single treatment works for everyone, many men are treated successfully through over-the-counter topicals, prescription medication, behavior modification, or a combination of these treatments.`,
  treatmentPEPageCanPECureDetailPoint1: ``,
  treatmentPEPageCanPECureDetailPoint2: ``,
  treatmentPEPageCanPECureDetailPoint3: ``,
  treatmentPEPageCanPECureDetailPoint4: ``,
  treatmentPEPageCanPECureDetailPoint5: ``,
  treatmentPEPageCanPECureDetailPoint6: ``,
  treatmentPEPageFastPE: ``,
  treatmentPEPageFastPEDetail: ``,
  treatmentPEPageTreatmentPE: ``,
  treatmentPEPageTreatmentPEDetail: ``,
  treatmentPEPageCausePE: `Cause of PE`,
  treatmentPEPageCausePEDetail: `<p>Study indicates that psychological problem such as stress, anxiety or depression around relationship and sexual problems can lead to PE. Another cause is the physical-related factors including abnormal amounts of thyroid hormones, inflammation of the urinary tract or prostate, and substance use.</p>`,
  treatmentPEPageClinicalProve: `<span class="font-[CentraNo2]">Clinically proven PE medications at HIBRO</span>`,
  treatmentPEPageClinicalProveDesc: `Premature ejaculation can be medically treated. There is only one premature ejaculation drug that has been approved by the Thai FDA. It controls the ejaculation mechanism and extends the sexual activity up to 2-3 times for men between 18-64 years old.`,
  treatmentPEPageDesc: `Get advice from a Thai-licensed doctor. Request medication to be prescribed and delivered to your door by registered pharmacies, private and safe`,
  treatmentPEPageFAQ1: `How does PE treatment work at hibro?`,
  treatmentPEPageFAQ1Desc: `At HIBRO, we offer the integrated treatment solutions for premature ejaculation include behavioral techniques, medications and counseling. Our doctors will find the treatment or combination of treatments that will work for you.`,
  treatmentPEPageFAQ2: `How do I know if I have PE?`,
  treatmentPEPageFAQ2Desc: `PE occurs when you have an orgasm less than a minute after you start. It can happen before, during or after the intercourse.`,
  treatmentPEPageFAQ3: `Are PE medications effective?`,
  treatmentPEPageFAQ3Desc: `PE treatment by Menarini is the first oral medication and the only selective serotonin reuptake inhibitor (SSRI) approved for PE in more than 60 countries including Thailand. The studies on the treatment show that it can improve the time until ejaculation by 1 to 1.5 minutes, depending on the dose.`,
  treatmentPEPageFAQ4: `What are the side effects for PE treatments?`,
  treatmentPEPageFAQ4Desc: `Common side effects may include nausea, dizziness, headache, diarrhea, facial flushing, indigestion, dry mouth etc. Less frequent side include low blood pressure when standing up, or light sensitivity and blurred vision.`,
  treatmentPEPageProductSectionLeftTitle: `<span class="font-[CentraNo2]">Premature Ejaculation (PE)</span>`,
  treatmentPEPageStart890Consult: ``,
  treatmentPEPageStart890: `Start from 890THB`,
  treatmentPEPageStartWith890: `Prices start from ฿890`,
  treatmentPEPageSubtitle: ``,
  treatmentPEPageTitle: `<span class="font-[CentraNo2]">Premature Ejaculation (PE)</span>`,
  treatmentPEPageTopic1: `A gentleman always <br />let the ladies come first`,
  treatmentPEPageTopic2: `About Premature Ejaculation`,
  treatmentPEPageTreatmentResult: `Results of the Treatments`,
  treatmentPEPageTreatmentResultDesc: `
  In a study of more than 6,000 men across Asia-Pacific and Americas, the treatment has shown results in increasing the average ejaculation time by 3x in men with premature ejaculation.<br /><br />
  Based on the data, the average intravaginal ejaculatory latency time (IELT) after a dose of 30mg PE treatment was increased from 0.92 to 2.78 minutes. With 60mg of PE treatment, the duration was increased 3.32 minutes, versus the placebo group from 0.90 to 1.75 minutes. The use of treatment can be effective in men with premature ejaculation, and may be taken with PDE-5 inhibitors in case of erectile dysfunction.<br /><br />
  This medication in treating premature ejaculation has been approved by the FDA in 60 countries. Consult with your doctor before considering the possible treatments.`,
  treatmentPEPageTreatmentResultSrcTag: `Source:`,
  treatmentPEPageTreatmentResultSrc: `Sangkum P. the treatment of premature ejaculation (2013)`,
  treatmentPEPageFrequentlyAskedQuestions: `Frequently Asked Questions`,
  treatmentPEPageWhatPE: `What is PE?`,
  treatmentPEPageWhatPEDetail: `<p>PE occurs when a man ejaculates sooner than he or his partner would like during sexual intercourse, usually under 1 minute. It is characterized by an inability to delay orgasm to the extent that it causes frustration to the patient and their partner. Premature ejaculation is a common sexual complaint. </p>`,
  treatmentPermatureEjacPageIndex1: `โรคหลั่งเร็ว (Premature Ejaculation : PE)`,
  treatmentPermatureEjacPageIndex2: `สาเหตุของโรคหลั่งเร็ว`,
  treatmentPermatureEjacPageIndex3: `เวลาเฉลี่ยการหลั่งน้ำอสุจิที่อาจบ่งบอกถึงภาวะหลั่งเร็ว`,
  treatmentPermatureEjacPageIndex4: `การรักษาโรคหลั่งเร็ว`,
  treatmentPermatureEjacPageIndex5: `How we can help?, get ahead!`,
  treatmentPermatureEjacPageSubtitle: `Premature<br />Ejaculation 101`,
  treatmentPermatureEjacPageTitle: `หลั่งเร็ว`,
  welcome: `Welcome`,
  /**What we treat */
  whatwetreatDesc: `Select a condition to get started. Start a discreet consult with doctors on the phone, or get your choice of prescription medications. Prescribed online and delivered to your door`,
  whatwetreatHL: `<span>Hair Loss</span>`,
  whatwetreatStartConsult: "Start Consult",
  /**How it work*/
  howitworkDesc: `The process takes about 8-10 minutes to complete`,
  howitwork1Title: `Register and answer health questions`,
  howitwork1Desc: `Choose the service that you want. Consult with a doctor over the phone for recommendation, or get medication by answering just 10-12 questions about your medical history.`,
  howitwork2Title: `Select treatments`,
  howitwork2Desc: `After answering the health questions, select the treatments and pay for it first to process.`,
  howitwork3Title: `Doctor Review and Deliver Treatments`,
  howitwork3Desc: `After payment, your details will be sent to your assigned doctor for review and follow up. If the treatment is appropriate for you, the doctor will approve the order and our pharmacy will deliver it within 2 days.`,
  continueBtn: `Continue`,
  /**Choose Service*/
  chooseServiceTitle: `Choose a service`,
  chooseServiceDesc: `Choose to chat with doctors or get medicine directly`,
  chooseProductByUser: `Purchase Treatment Directly`,
  chooseServiceRecommendToUser: `Suitable for people who know the medications they need. Just answer a few questions and let the doctor evaluate for approval.`,
  chooseServiceConsultWithQn: `Consult by answering health questions`,
  chooseServiceBuyWithoutDoctor: `No need to see doctors`,
  chooseServiceAnswerQN: `Answer 10-12 questions`,
  chooseServiceDoctorApprove: `Doctor to evaluate and write prescriptions in 24 hours. Free shipping!`,
  chooseServiceChatWithDoctor: `Unlimited messages with doctors for Q&A in profile`,
  chooseServiceCostConsult: `฿99`,
  chooseServiceCostPerCon: `/doctor’s fee per consult`,
  chooseServiceInitConsult: `Start Consult`,
  chooseServicePhoneConsult: `Phone Consult with Doctor`,
  chooseServiceDoctorDiagnosis: `Talk to doctors to diagnose your symptoms and suggest the right treatment for you.`,
  chooseServiceTimeConsult: `Consult via phone call (15 minutes)`,
  chooseServiceSkipQn: `Skip the health questions`,
  chooseServiceDoctorEva: `Doctor to provide evaluations and recommendations`,
  chooseServiceConsultViaPlatform: `(Optional) Select treatments after consult. No extra consult fees.`,
  chooseServiceIncludeDocFee: `Unlimited messages with doctors for Q&A in profile`,
  chooseServicePhoneFee: `฿200`,
  // Registration page
  registrationCreateAccount: `Create a profile`,
  registrationFillData: `Fill in your contact information for the medical team. Next, you’ll provide some basic information about you and your medical history.`,
  registrationHasAcc: `Already have an account?`,
  registrationLogin: `Log in`,
  registrationEmail: `Email`,
  registrationPassword: `Password`,
  registrationConfirmPassword: `Confirm Password`,
  registrationAgreement: `<span>I agree to HIBRO’s 
    <a href="/terms" target="_blank" class="font-semibold text-[#3E92F2] underline cursor-pointer">terms</a>, 
    <a href="/privacy-policy" target="_blank" class="font-semibold text-[#3E92F2] underline cursor-pointer">privacy policy</a>, and 
    <a href="/consent" target="_blank" class="font-semibold text-[#3E92F2] underline cursor-pointer">telehealth consent</a>
  </span>`,
  registrationRegisterText: `Register`,
  registrationVia: `Log in via`,
  registrationViaGoogle: `Continue with Google`,
  registrationViaFacebook: `Continue with Facebook`,
  registrationViaLine: `Continue with Line`,
  registrationPasswordCondition: `Password must be 8 characters or more`,
  registrationRequireData: `Please fill in required data`,
  registrationEmailInvalid: `Your email is invalid`,
  registrationPasswordNotMatch: `Password are not match`,
  registrationPDPA: `Please accept provacy policy`,
  // Login
  loginWelcome: `Welcome back`,
  loginOldUser: `Log in to your account to continue your online health visit.`,
  loginNewUser: `New to HIBRO?`,
  loginRegister: `Register`,
  loginEmail: `Email`,
  loginPassword: `Password`,
  loginKeepSignIn: `Keep me signed in`,
  loginForgetPass: `Forget password?`,
  loginText: `Log in`,
  loginViaText: `Log in via`,
  loginWithGoogle: `Continue with Google`,
  loginWithFacebook: `Continue with Facebook`,
  loginWithLine: `Continue with Line`,

  // Forget Password
  forgetPasswordText: `Forgot your password?`,
  forgetPasswordResetEmail: `Please enter your email address to reset your password`,
  forgetPasswordEmailText: `Email`,
  forgetPasswordSubmit: `Submit`,
  forgetPasswordCheckEmail: `Please check your email`,
  forgetPasswordSentEmail: `A new password is sent to your email. You can reset your password via the confirmation link in your email.`,
  forgetPasswordBackText: `Back to Home`,
  //basic info
  basicInfoDesc: `Fill in basic information about you so the doctor can get to know you better`,
  basicInfoFirstname: `First name*`,
  basicInfoLastname: `Last name*`,
  basicInfoBirth: `Date of birth*`,
  basicInfoSex: `Sex*`,
  basicInfoWeight: `Weight (kg)*`,
  basicInfoHeight: `Height (cm)*`,
  basicInfoContinue: `Continue`,
  basicInfoMale: `Male`,
  basicInfoFemale: `Female`,
  basicInfoAgeError: `Only males between 20-65 years old`,
  basicInfoSexError: `Male only`,
  // eCommerce Status
  ecommerceStatusConsult: `Consult`,
  ecommerceStatusTreatment: `Treatment`,
  ecommerceStatusQuantity: `Quantity`,
  ecommerceStatusPayment: `Payment`,
  ecommerceBackText: `Back`,
  //Medical Summary
  medicalSummaryTitle: `Next step, select treatments`,
  medicalSummaryDesc: `Great, you have answered all the health questions! Next, select a treatment and pay for it. The doctor will review your submission in 24 hours and, if the treatment is appropriate, approve the order for our pharmacy to deliver in 2 days.`,
  medicalSummarySelectTreatment: `Select Treatment`,
  medicalSummaryReview: `Review my submission`,
  medicalSummaryInfo: `Your Medical Summary`,
  medicalSummaryEditAnswer: `You can still edit your answers before you proceed`,
  medicalSummaryQuestionText: `Question`,
  medicalSummaryAnswerText: `Your Answers`,
  medicalSummaryNext: `Select Treatment`,
  //Catalog
  catalogTitle: `A standard form of treatment for erectile dysfunction is Sildenafil (commonly known as Viagra)`,
  catalogTreatmentNumber: `Treatment`,
  catalogDesc: `Select other treatments you prefer:`,
  catalogAllOption: `All`,
  ยี่ห้ออื่น: `Stronger Dose`,
  ทานต่อเนื่องเป็นประจำทุกวัน: `Daily options`,
  catalogNext: `Next`,
  catalogSelectTreatment: `Select`,
  catalogConditionText: `A recommended form of treatment for hair loss is Minoxidil and Finasteride taken together.`,
  catalogNotWishTreatment: `I do not wish to take this treatment`,
  catalogRemoveOption: `Select this box to opt out of this treatment`,
  //Select Quantity
  selectQuantityTitle: `Select the quantity you wish to purchase per month`,
  selectQuantityCondition: `If the doctor did not approve your treatment, we will refund the full amount back to you`,
  selectQuantityOnlineFee: `Doctor’s fee`,
  selectQuantityShipping: `Shipping`,
  selectQuantityFree: `Free`,
  selectQuantityDiscount: `Total Discounts`,
  selectQuantityTotal: `Total`,
  selectQuantityNext: `Next`,
  selectSubscriptionNext: `Select Plan`,
  selectQuantityMaximumText1: `Maximum`,
  selectQuantityMaximumText2: `packs a month`,
  //Select Subscription
  selectSubscriptionTitle: `Would you like to pre-order your medications for the next few months?`,
  selectSubscriptionDesc: `We will ship the treatment each month with the quantity you chose previously`,
  selectSubscriptionDescHL: `ใช้ finasteride และ minoxidil อาจต้องใช้เวลาอย่างน้อย 4 เดือนจึงจะเห็นผล การงอกของเส้นผมจะยังคงอยู่เมื่อใช้ยาเท่านั้น ผมที่ขึ้นใหม่จะร่วงภายใน 6 เดือน หลังจากหยุดยา`,
  selectSubscriptionChoosePlan: `Choose your plan and save more:`,
  selectSubscriptionOneTime: `One-time purchase`,
  selectSubscriptionOriginalPrice: `Original price`,
  selectSubscriptionPurchaseFor: `Purchase`,
  selectSubscriptionMonth: `months`,
  selectSubscriptionShipping: `We will ship this month and next month`,
  selectSubscriptionShippingFinal: `We will ship this month and next 2 months`,
  selectSubscriptionCondition: `If the doctor did not approve your treatment, we will refund the full amount back to you`,
  // Upload ID
  verifyIDTitle: `Verify your identity`,
  verifyIDDesc: `Under regulations, our doctors and pharmacy need a photo of you together with your ID for verifications before issuing the medications.

We take your privacy very seriously. Only the healthcare professionals handling your case will see your information.`,
  verifyIDNext: `Next`,
  verifyIDUploadIDTitle: `Upload a photo of you and your ID together`,
  verifyIDUploadIDDesc: `Our doctors and pharmacy are required to verify our users using a government-issued ID befores shipping`,
  verifyIDUploadButton: `Take photo/Upload`,
  verifyIDUploadDesc: `<ul className="list-disc list-inside"><li>Hold ID/Passport close to your face. Do not cover any numbers or photos.</li><li>Do not wear a hat or dark glasses.</li><li>Face and ID must be visible and clear</li></ul>`,
  verifyIDNumberInput: `Enter your Passport or ID card number again*`,
  verifyIDNumberPlaceholder: `Enter number`,
  verifyIDconfirm: `Confirm`,
  // Delivery
  deliveryTitle: `Delivery Details`,
  deliveryOrderSummary: `Order Summary`,
  freeConsult: `Consult`,
  deliveryOnlineFee: `Doctor’s fee`,
  deliveryShipping: `Shipping`,
  deliveryDiscount: `Total discounts`,
  deliveryTotal: `Total`,
  deliveryAddressDesc: `Delivery Address`,
  deliveryShippingNote: `No worries. We ship in a discreet package.`,
  deliveryName: `Name*`,
  deliveryAddressText: `Address*`,
  deliveryAddressNumber: `house number / suite`,
  deliveryProvince: `Province*`,
  deliveryDistrict: `District*`,
  deliverySubdistrict: `Subdistrict*`,
  deliveryPostalCode: `Postal code*`,
  deliveryNote: `Delivery Notes`,
  deliveryNotePlaceholder: `please put it downstairs...`,
  deliveryContactInfo: `Contact Information`,
  deliveryContactInfoDesc: `Enter your phone number so our pharmacists can call to ensure delivery and explain use of instructions`,
  deliveryPhoneInput: `Phone number*`,
  deliveryPreferTime: `Preferred time`,
  deliveryNext: `Next`,
  deliveryInvalidCoupon: `Invalid discount code`,
  deliveryReenterCoupon: `Please enter a valid new discount code.`,
  deliveryBack: `Back`,
  deliveryCouponLimit: `The coupon code limit is used`,
  deliveryCouponError: `Please select a new coupon code`,
  // Checkout
  checkoutTitle: `Payment`,
  checkoutOrderSummary: `Order Summary`,
  checkoutOnlineFee: `Doctor’s fee`,
  checkoutShipping: `Shipping`,
  checkoutDiscount: `Total discounts`,
  checkoutTotal: `Total`,
  checkoutPaymentMethod: `Payment Method`,
  checkoutCreditCard: `Credit card / Debit card`,
  checkoutPromptpay: `Promptpay QR payment`,
  checkoutTransfer: `Bank Transfer`,
  checkoutCreditCardNote: `We partner with GB Prime Pay to ensure your credit/debit card details are safe, hibro won't have access to your credit/debit card information.`,
  checkoutCardNumber: `Card Number*`,
  checkoutCardNumberPlaceholder: `Enter 16 digits`,
  checkoutExpireDate: `Expiry Date*`,
  checkoutExpireDatePlaceholder: `MM/YY`,
  checkoutCVV: `CVV*`,
  checkoutCVVPlaceholder: `CVV 3 digits`,
  checkoutCardName: `Name on Card`,
  checkoutCardNamePlaceholder: `Cardholder name`,
  checkoutRememberCard: `Remember this card`,
  checkoutPayAgreement: `By clicking “Place Order” you are agreeing to our Safety Guideline. For your personal data, it will be used to process your order, support your experience throughout this website, and for other purposes in accordance to our Privacy Policy.`,
  checkoutPlaceOrder: `Place Order`,
  checkoutTransferNote: `To ensure quick confirmation on your payment, please upload the payment slip from your mobile or Internet banking app. Or, take a photo of the payment slip and attach the slip on the system.`,
  checkoutName: `Name:`,
  checkoutOrderNumber: `Order Number:`,
  checkoutBankSelect: `Pay via:`,
  checkoutSCB: `Siam Commercial Bank`,
  checkoutEndose: `Endose Co., Ltd.`,
  checkoutUploadSlip: `Upload bank slip*`,
  checkoutAddNewCard: `Add new card`,
  // Order Success
  orderSuccessTitle: `Thank you for your purchase`,
  orderSuccessDesc: `The doctor will review your submission in 24 hours and, if the treatment is appropriate, approve the order for our pharmacy to deliver in 2 days.`,
  orderSuccessOrderSummary: `Order Summary`,
  orderSuccessOnlineFee: `Doctor’s fee`,
  orderSuccessShipping: `Shipping`,
  orderSuccessDiscount: `Total discounts`,
  orderSuccessTotal: `Total`,
  orderSuccessOrderID: `Order ID:`,
  orderSuccessPurchaseAt: `Purchase date:`,
  orderSuccessPaymentMethod: `Payment method:`,
  orderSuccessShippingAddress: `Shipping Address:`,
  orderSuccessPhoneNumber: `Phone number:`,
  orderSuccessPreferTime: `Preferred time:`,
  orderSuccessBackHome: `Back to Home`,
  orderSuccessQuestion: `Got some questions? <a href="/contact" class="underline">Contact Us</a>`,
  orderSuccessThankyouDesc: `"Thank you for choosing HIBRO!
We read every feedback we get and we take it seriously to improve our service."`,
  orderSuccessRateTitle: `Please rate your experience about HIBRO services*`,
  orderSuccessSuggestion: `Do you have any suggestions based on the rating?`,
  orderSuccessFeedback: `Your feedback here...`,
  orderSuccessHowyouknow: `How do you know about HIBRO?`,
  orderSuccess: `What’s your nickname*`,
  orderSuccessFeedbackSubmit: `Submit`,
  // Phone Consult
  ed: `Erectile Dysfunction`,
  pe: `Premature Ejaculation`,
  hl: `Hair Loss`,
  phoneConsultAppointment: `Appointment`,
  phoneConsultPayment: `Payment`,
  phoneConsultConsult: `Consult`,
  phoneConsultAppointmentTitle: `Make an appointment with doctor`,
  phoneConsultAppointmentDesc: `Please fill out your availability and describe your symptoms briefly. The assigned doctor will contact you at your preferred time`,
  phoneConsultAppointmentPhone: `Phone number*`,
  phoneConsultAppointmentPhonePlaceholder: `enter your number`,
  phoneConsultAppointmentPreferDate: `Preferred appointment date*`,
  phoneConsultAppointmentEveryday: `Everyday`,
  phoneConsultAppointmentMonday: `Monday`,
  phoneConsultAppointmentTuesday: `Tuesday`,
  phoneConsultAppointmentWednesday: `Wednesday`,
  phoneConsultAppointmentThursday: `Thursday`,
  phoneConsultAppointmentFriday: `Friday`,
  phoneConsultAppointmentSaturday: `Saturday`,
  phoneConsultAppointmentSunday: `Sunday`,
  phoneConsultAppointmentPreferTime: `Preferred time slot*`,
  phoneConsultAppointmentMorning: `Morning between 8.00 am -12.00 pm`,
  phoneConsultAppointmentAfternoon: `Afternoon between 13.00-17.00 pm`,
  phoneConsultAppointmentNight: `Night between 17.00-21.00 pm`,
  phoneConsultAppointmentDetail: `Please briefly describe your questions or symptoms:`,
  phoneConsultAppointmentDetailPlaceholder: `Write down here...`,
  phoneConsultAppointmentNote: `*your doctor can use this information to prepare before calling you. Note that each consultation is limited to 15 mintues only.`,
  phoneConsultAppointmentAgree: `I agree to share this information with the doctors and clinics in providing the consultation service`,
  phoneConsultAppointmentSubmit: `Submit`,
  medicalExpertDesc: `Our medical team with over ten years of experience in men’s health, from urology to dermatology`,
  "นพ. อาคเนย์ วงษ์สวัสดิ์": `Dr. Akanae Wongsawat, MD`,
  "นพ. อาคเนย์ วงษ์สวัสดิ์_Desc": `Urology Surgeon at MedPark Hospital`,
  "นพ. พชรวรรธน์ จิระสุทัศน์": `Dr. Phatcharawat Chirasuthat, MD`,
  "นพ. พชรวรรธน์ จิระสุทัศน์_Desc": `Dermatologist at Ramathibodi Hospital<br/>Mahidol University`,
  "นพ. ชยุตม์ ฝูงทองเจริญ": `Dr. Chayut Fungtongjaroen, MD`,
  "นพ. ชยุตม์ ฝูงทองเจริญ_Desc": `CEO and Medical Director of<br/>HE Clinic Thonglor`,
  "นพ. ศิริวิฐพ์ หอสูติสิมา": `Dr. Siriwit Horsutirama, MD`,
  "นพ. ศิริวิฐพ์ หอสูติสิมา_Desc": `General Practitioner`,
  "นพ. วิศรุต ภาคลาภ": `Dr. Witsarut Paklarp, MD`,
  "นพ. วิศรุต ภาคลาภ_Desc": `General Practitioner`,
  "นพ. กฤษณ์ สันธนพิพัฒน์กุล": `Dr. Krit Santanapipatkul, MD`,
  "นพ. กฤษณ์ สันธนพิพัฒน์กุล_Desc": `Urology Surgeon`,
  "นพ. ศุภวัฒน์ วีรภัทรรุ่งโรจน์": `Dr. Supawat Wirapatrungrot, MD`,
  "นพ. ศุภวัฒน์ วีรภัทรรุ่งโรจน์_Desc": `General Practitioner at Glove Clinic`,
  // Phone Consult - transition
  phoneConsultTransitionTitle: `Submission Success`,
  phoneConsultTransitionDesc: `Thank you for your information. Next, please pay the consultation fee first and the doctor will contact you as soon as possible.`,
  phoneConsultTransitionPayment: `Make Payment`,
  phoneConsultTransitionOnline: `Online Consultation (15 min)`,
  phoneConsultTransitionConsult: `Phone consultation with doctors directly`,
  // Phone Consult - medical summary
  phoneConsultCompleteTitle: `Your appointment is confirmed`,
  phoneConsultCompleteDesc: `We will assign a doctor in 24 hours to give you a call at the appointment time you specified. You can track the progress in User Profile.`,
  phoneConsultCompleteQuestion: `<p>If you have any questions, you can reach us via:</p>
<p>Line: @hibro</p>
<p>Email: care@sayhibro.com</p>`,
  phoneConsultCompleteDetail: `The doctor will use this information to prepare for discussion`,
  phoneConsultCompleteName: `Name:`,
  phoneConsultCompleteCondition: `Condition:`,
  phoneConsultCompletePhone: `Phone number:`,
  phoneConsultCompleteDate: `Preferred date:`,
  phoneConsultCompleteTime: `Preferred timeslot:`,
  phoneConsultCompleteSymptom: `Describe your questions or symptoms:`,
  phoneConsultCompleteBackHome: `Back to Home`,
  phoneConsultCompleteViewAppointment: `View Appointment`,
  //Phone Consult - medical summary next steps
  phoneConsultCompleteTitle: `Here’s what the doctor recommends:`,
  phoneConsultCompleteIntro: `Thank you for your time on the phone with me on`,
  phoneConsultCompleteDiscussion: `Based on our discussion,`,
  phoneConsultProductIntro: `I have also evaluated your current health conditions, and determined that`,
  phoneConsultProductEnd: `is the most suitable treatment for you.`,
  phoneConsultSildenafilDetail1: `Sildenafil, commonly known as Viagra, is used to treat erectile dysfunction, or impotence, for men. Sildenafil works by increasing the blood flow to the penis during sexual stimulation. The drug blocks the action of an enzyme called PDE5 that causes blood to flow out of penis. This results in a better erection physically.`,
  phoneConsultSildenafilDetail2: `While the medication is effective for erection, Sildenafil is not a cure for erectile dysfunction and does not work the same for everyone. If you would like to try other treatments, you can also check with me by directly messaging me in the Profile section, or go to the next step to select your desired treatment in the following sections.`,
  phoneConsultTadalafilDetail1: `"Tadalafil, commonly known as Cialis, is used to treat erectile dysfunction, or impotence, for men. It is known for its long duration and manufactured by Eli Lilly. The key ingredient, Tadalafil, is a PDE-5 inhibitor that helps relaxes the muscle in the blood vessels, and increases blood flow to aid in erection. It is available for as-needed use (20mg) and daily medications with lower dosage (5mg)."`,
  phoneConsultTadalafilDetail2: `"While the medication is effective for erection, Tadalafil is not a cure for erectile dysfunction and does not work the same for everyone. If you would like to try other treatments, you can also check with me by directly messaging me in the Profile section, or go to the next step to select your desired treatment in the following sections."`,
  phoneConsultPriligyDetail1: `Last Longer Pill, branded as Priligy, is used to treat premature ejaculation for men. It is a selective serotonin reuptake inhibitor (SSRI), which improves ejaculation controls and reduces distress by inhibiting the serotonin transport. This would improve your satisfaction with sexual intercourse. It is available for as-needed use before sexual activities.`,
  phoneConsultRejectDetail1: `I have also evaluated your current health conditions. If you are looking to get a treatment plan for your condition, I'd suggest you to visit a nearby clinic to consult with a doctor and do a health checkup. This is because part of your symptoms may raise some concerns for the doctors to prescribe remotely, and it’s also better to get a closer look at your health.`,
  phoneConsultRejectDetail2: `"HIBRO works with various healthcare providers to ensure our users have access to providers. Click next step to make an appointment, and our partner clinic will give you a call as soon as possible."`,
  phoneConsultRejectDetail3: `"If you have other follow-up questions, feel free to send me a message in the Profile section."`,
  phoneConsultSafetyIntro: `Please make sure you thoroughly read the`,
  phoneConsultSafetyText: `Drug Safety Information`,
  phoneConsultSafetyEnd: `before you take the medication. If you have any questions about the use of medicine and potential side effects, you can also message me in Profile or the HIBRO team for more information.`,
  phoneConsultCloseText1: `Our pharmacist will give you a follow up call to ensure your safety after you have received the treatment. If you have any questions regarding the use of treatment, feel free to ask us.`,
  phoneConsultCloseText2: `Again, thanks for visiting HIBRO and taking the time to answer questions about your health.`,
  phoneConsultRegards: `Best,`,
  phoneConsultLicense: `License Number`,
  phoneConsultCompleteSuggestion: `This is the treatment the doctor suggested for you:`,
  //Rejection flow
  rejectTitle: `We are very sorry, bro.`,
  rejectDesc: `Unfortunately you are not eligible for online treatments according to our medical guidelines.`,
  rejectCause: `Reasons:`,
  rejectPhoneConsult: `Start a phone consult`,
  rejectPhoneConsultPrice: `(For 300 THB)`,
  rejectAppointment: `See doctors in`,
  rejectAppointmentLine2: `your nearby clinic`,
  rejectNote: `For some cases, you will need to talk to doctors first before getting a prescription`,
  // Rejection flow - referral form
  referalTitle: `Make an appointment to see doctor in person`,
  referalDesc: `Please fill out your availability and describe your symptoms briefly. The assigned doctor will contact you at your preferred time.`,
  referalArea: `Enter your area of residence for nearest clinic:`,
  referalSymptom: `Please briefly describe your questions or symptoms:`,
  referalSymptomPlaceholder: `Write down here...`,
  // Rejection flow - submission
  rejectSubmitTitle: `Thank you for your sumbmission`,
  rejectSubmitDesc: `A clinic near you will contact you for more information`,
  rejectSubmitBack: `Back to Home`,
  //Questionnaire
  backQuestion: `Back`,
  nextQuestion: `Next`,
  //Treatment Profile
  validateSlip: "Verify Slip",
  continueOrder: "Continue",
  paidOrder: "Paid, Order in progress",
  inreviewOrder: "Doctor is reviewing",
  onShipping: "Shipping",
  nextStep: "Next Step",
  //Profile
  cancelSaveProfile: "Cancel",
  saveProfile: "Save",
  saveProfileSuccess: "Saved",
  //Profile Password
  changePasswordEmail: "Email",
  changePasswordOld: "Old Password",
  changePasswordNew: "New Password",
  changePasswordFail: "Change Password Failed",
  //Switch Phone Consult
  switchPopupTitle: "Switch to consult",
  switchPopupDesc:
    "Tired to enter the form? Talk to doctor over the phone directly.",
  switchTitle: "Are you sure you want to switch to consult?",
  switchDesc:
    "Consultation is a faster way to answer all your questions with doctors directly without filling all the details. Note that all your current input will be discarded once you move over.",
  switchButton: "Start for ฿300",
  switchCancelButton: "Continue on Form",
  //Returning User Journey
  homePopupDescription:
    "Please select the following option to continue your actions.",
  homePopupGoToTreatment: "Continue my treatment",
  homePopupViewDiagnosisED: "View doctor diagnosis (ED)",
  homePopupViewDiagnosisPE: "View doctor diagnosis (PE)",
  homePopupGoToChat: "Follow up with doctor",
  homePopupBuyAgain: "Buy again",
};
